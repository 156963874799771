export const Types = {
	SET_USER: 'user/SET_USER',
	SET_TOKEN: 'user/SET_TOKEN',
	UPDATE_MFA_VALIDATE: 'user/UPDATE_MFA_VALIDATE',
}

const initialState = {
	user: {},
	token: '',
	hasMFAValidated: !!localStorage.getItem('isValidated') || false,
}

export const setUser = user => {
	return dispatch => {
		dispatch({
			type: Types.SET_USER,
			payload: user,
		})
	}
}

export const updateMFAValidate = isValid => {
	return dispatch => {
		if (isValid) {
			localStorage.setItem('isValidated', true)
		} else {
			localStorage.removeItem('isValidated')
		}
		dispatch({
			type: Types.UPDATE_MFA_VALIDATE,
			payload: isValid,
		})
	}
}

export const setToken = token => {
	return dispatch => {
		dispatch({
			type: Types.SET_TOKEN,
			payload: token,
		})
	}
}

export const reducerObject = {
	'user/SET_USER': (state, action) => {
		return { ...state, user: action.payload }
	},
	'user/SET_TOKEN': (state, action) => {
		return { ...state, token: action.payload }
	},
	'user/UPDATE_MFA_VALIDATE': (state, action) => {
		return { ...state, hasMFAValidated: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
