import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components/macro'

import { classicDraggableInitialGrid, advancedDraggableInitialGrid } from '../constants/initialGrids'
import { darkTheme, lightTheme } from '../styles/theme'

export const ThemeContext = createContext()
export const useCustomTheme = () => useContext(ThemeContext)

const CustomThemeProvider = ({ children, themeProps }) => {
	const isCollapsedStorage = JSON.parse(localStorage.getItem('isADraggableTrade') || 'false')
	const minimizeOrdersStorage = JSON.parse(localStorage.getItem('minimizeOrders') || 'false')
	const classicLayoutStorage = JSON.parse(
		localStorage.getItem('klever-classic-layout') || JSON.stringify(classicDraggableInitialGrid)
	)
	const advancedLayoutStorage = JSON.parse(
		localStorage.getItem('klever-advanced-layout') || JSON.stringify(advancedDraggableInitialGrid)
	)
	const chartTypeStorage = JSON.parse(localStorage.getItem('klever-chart-type') || JSON.stringify('tradingview'))
	const layoutModeStorage = JSON.parse(localStorage.getItem('klever-layout-mode') || JSON.stringify('advanced'))
	const expandOrdersStorage = JSON.parse(localStorage.getItem('klever-expand-orders') || 'false')

	const [theme, setTheme] = useState(themeProps || darkTheme)
	const [isADraggableTrade, setIsADraggableTrade] = useState(isCollapsedStorage)
	const [minimizeOrders, setMinimizeOrders] = useState(minimizeOrdersStorage)
	const [classicLayout, setClassicLayout] = useState(classicLayoutStorage)
	const [advancedLayout, setAdvancedLayout] = useState(advancedLayoutStorage)
	const [chartType, setChartType] = useState(chartTypeStorage)
	const [layoutMode, setLayoutMode] = useState(layoutModeStorage)
	const [expandOrders, setExpandOrders] = useState(expandOrdersStorage)

	const _setIsADraggableTrade = useCallback(value => {
		localStorage.setItem('isADraggableTrade', JSON.stringify(value))
		setIsADraggableTrade(value)
	}, [])

	const _setMinimizeOrders = useCallback(value => {
		localStorage.setItem('minimizeOrders', JSON.stringify(value))
		setMinimizeOrders(value)
	}, [])

	const _setClassicLayout = newLayout => {
		localStorage.setItem('klever-classic-layout', JSON.stringify(newLayout))
		setClassicLayout(newLayout)
	}

	const _setAdvancedLayout = newLayout => {
		localStorage.setItem('klever-advanced-layout', JSON.stringify(newLayout))
		setAdvancedLayout(newLayout)
	}

	const _setLayoutMode = type => {
		localStorage.setItem('klever-layout-mode', JSON.stringify(type))
		setLayoutMode(type)
	}

	const _setChartType = type => {
		localStorage.setItem('klever-chart-type', JSON.stringify(type))
		setChartType(type)
	}

	const _setExpandOrders = value => {
		localStorage.setItem('klever-expand-orders', JSON.stringify(value))
		setExpandOrders(value)
	}

	const resetTheme = () => setTheme(darkTheme)

	const toggleTheme = useCallback(() => {
		if (theme.name === 'dark') {
			setTheme(lightTheme)
		} else if (theme.name === 'light') {
			setTheme(darkTheme)
		}
	}, [theme.name])

	useEffect(() => {
		if (isADraggableTrade) return _setMinimizeOrders(false)
	}, [_setMinimizeOrders, isADraggableTrade])

	return (
		<ThemeContext.Provider
			value={{
				toggleTheme,
				theme,
				resetTheme,
				isADraggableTrade,
				_setIsADraggableTrade,
				minimizeOrders,
				_setMinimizeOrders,
				classicLayout,
				_setClassicLayout,
				advancedLayout,
				_setAdvancedLayout,
				chartType,
				_setChartType,
				layoutMode,
				_setLayoutMode,
				expandOrders,
				_setExpandOrders,
			}}
		>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	)
}

CustomThemeProvider.propTypes = {
	children: PropTypes.node.isRequired,
	themeProps: PropTypes.object,
}

export default CustomThemeProvider
