import { setKeypairFavorite, getKeypairFavorite } from '../../services/userSettings'

export const Types = {
	SET_FAVORITES: 'favorites/SET_FAVORITES',
	SET_LOADING: 'favorites/SET_LOADING',
	FAILURE: 'favorites/FAILURE',
}

const initialState = {
	favoritesList: [],
	loading: false,
	failed: false,
}

export const setFavorites = (KeypairID, Remove = false) => {
	return async (dispatch, getStore) => {
		dispatch({
			type: Types.SET_LOADING,
			payload: true,
		})
		const response = await setKeypairFavorite(KeypairID, Remove)

		const { favorites } = getStore()

		const newFavorites = [...favorites?.favoritesList, KeypairID]
		if (!response?.error) {
			dispatch({
				type: Types.SET_FAVORITES,
				payload: newFavorites,
			})
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const removeFavorite = (KeypairID, Remove = true) => {
	return async (dispatch, getStore) => {
		const response = await setKeypairFavorite(KeypairID, Remove)
		const { favorites } = getStore()
		const removeFavorites = favorites?.favoritesList?.filter(id => id !== KeypairID)

		if (!response?.error) {
			dispatch({
				type: Types.SET_FAVORITES,
				payload: removeFavorites,
			})
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const getFavorites = KeypairFavorites => {
	return async dispatch => {
		const response = await getKeypairFavorite(KeypairFavorites)
		if (!response?.error) {
			dispatch({
				type: Types.SET_FAVORITES,
				payload: response,
			})
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'favorites/SET_FAVORITES': (state, action) => {
		return { ...state, favoritesList: action.payload, loading: false, failed: false }
	},
	'deposits/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
