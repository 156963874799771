import { getAccountLimit } from '../../services/manager'

export const Types = {
	SET_LIMIT: 'limit/SET_LIMIT',
	FAILURE: 'limit/FAILURE',
}

const initialState = {
	limit: {
		Limit: 0,
		Used: 0,
	},
	failed: true,
}

export const fetchAccountLimit = (currency, type) => {
	return async dispatch => {
		const limit = await getAccountLimit(currency, type)
		if (!limit?.error) {
			dispatch({
				type: Types.SET_LIMIT,
				payload: {
					Limit: limit?.Limit || 0,
					Used: limit?.Used || 0,
				},
			})
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'limit/SET_LIMIT': (state, action) => {
		return { ...state, limit: action.payload, failed: false }
	},
	'limit/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
