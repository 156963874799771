import { getAllPosts } from '../../services/wordpress'

export const Types = {
	FETCH_NEWS: 'news/FETCH_NEWS',
	FAILURE: 'news/FAILURE',
}

const initialState = {
	newsList: [],
	failed: false,
}

export const fetchNews = (pageSize, page = 1) => {
	return async (dispatch, getStore) => {
		const data = await getAllPosts(pageSize, page)
		const { news } = getStore()
		if (!data?.error) {
			if (data?.length) {
				dispatch({ type: Types.FETCH_NEWS, payload: [...news?.newsList, ...data] })
			} else {
				dispatch({ type: Types.FETCH_NEWS, payload: data })
			}
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'news/FETCH_NEWS': (state, action) => {
		return { ...state, newsList: action.payload, failed: false }
	},
	'news/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
