import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useState } from 'react'

export const KeycloakContext = createContext()
export const useKeycloak = () => useContext(KeycloakContext)

const KeycloakProvider = ({ children }) => {
	const [keycloakLoading, setKeycloakLoading] = useState(false)

	const setKeycloakLoadingProvider = useCallback(value => setKeycloakLoading(value), [])

	return (
		<KeycloakContext.Provider
			value={{
				setKeycloakLoadingProvider,
				keycloakLoading,
			}}
		>
			{children}
		</KeycloakContext.Provider>
	)
}

KeycloakProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default KeycloakProvider
