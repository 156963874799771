import MFAClient from '../../services/grpcweb/MFAClient'
import { updateMFAValidate } from './user'

export const Types = {
	LOADING: 'mfa/LOADING',
	FAILURE: 'mfa/FAILURE',
	SET_MFA_OPTIONS: 'mfa/SET_MFA_OPTIONS',
	SET_IS_VALID_MFA: 'mfa/SET_IS_VALID_MFA',
	SET_IS_REMOVED_MFA: 'mfa/SET_IS_REMOVED_MFA',
	SET_IS_ACTIVATED_MFA: 'mfa/SET_IS_ACTIVATED_MFA',
	SET_OPTION: 'mfa/SET_OPTION',
	SET_ERROR: 'mfa/SET_ERROR',
	SET_CLEAN: 'mfa/SET_CLEAN',
	SET_CHANGE_MODAL_TYPE: 'mfa/SET_CHANGE_MODAL_TYPE',
	SET_BACKUP_KEY: 'mfa/SET_BACKUP_KEY',
}

const initialState = {
	loading: false,
	mfaOptions: [],
	isValid: false,
	isActivated: false,
	isRemoved: false,
	isLoaded: false,
	modalType: '',
	error: '',
	backupkey: '',
	option: {
		secret: '',
		qrcode: '',
	},
}

export const fetchMFAOptions = authToken => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})
			const { mfa } = getStore()
			if (mfa?.error !== '') dispatch({ type: Types.SET_ERROR, payload: { error: '' } })

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.getMFAOptions(authToken)
			if (response?.error) {
				dispatch({ type: Types.FAILURE, payload: response.message })
				return response
			}
			dispatch({
				type: Types.SET_MFA_OPTIONS,
				payload: response,
			})
			return response
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const createMFA = (authToken, option = 2) => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const { mfa } = getStore()
			if (mfa.error !== '') dispatch({ type: Types.SET_ERROR, payload: { error: '' } })
			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.createMFA(option, authToken)
			if (response?.error) {
				throw new Error(response.message)
			}

			dispatch({
				type: Types.SET_OPTION,
				payload: response,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const activateMFA = (secret, code, authToken, option) => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})
			const { mfa } = getStore()
			if (mfa.error !== '') dispatch({ type: Types.SET_ERROR, payload: { error: '' } })

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.activateMFA(secret, code, authToken, option)

			if (response?.error) {
				dispatch({ type: Types.FAILURE, payload: response?.message })
				return response
			}

			dispatch({
				type: Types.SET_IS_ACTIVATED_MFA,
				payload: { isActivated: true, backupkey: response?.backupkey },
			})
			return response
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const validateMFA = code => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const { mfa } = getStore()
			if (mfa.isValid) dispatch({ type: Types.SET_IS_VALID_MFA, payload: { isValid: false } })
			if (mfa.error !== '') dispatch({ type: Types.SET_ERROR, payload: { error: '' } })

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.validateMFA(code)

			if (response?.error) {
				throw new Error(response.message)
			}

			dispatch({
				type: Types.SET_IS_VALID_MFA,
				payload: { isValid: true },
			})
			dispatch(updateMFAValidate(true))
			return { isValid: true }
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
			return { error }
		}
	}
}

export const removeMFA = code => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.removeMFA(code)

			if (response?.error) {
				throw new Error(response.message)
			}

			dispatch({
				type: Types.SET_IS_REMOVED_MFA,
				payload: { isRemoved: true },
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const recoverMFA = (backupkey, authToken) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.recoverMFA(backupkey, authToken)

			if (response?.error) {
				throw new Error(response.message)
			}

			dispatch({
				type: Types.SET_OPTION,
				payload: response,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const setClean = () => {
	return async dispatch => dispatch({ type: Types.SET_CLEAN })
}

export const setChangeModalType = modalType => {
	return async dispatch => dispatch({ type: Types.SET_CHANGE_MODAL_TYPE, payload: modalType })
}

export const fetchBackupKey = code => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const MFAGrpcWeb = new MFAClient()
			const response = await MFAGrpcWeb.recoverTOTPBackupKey(code)

			if (response?.error) {
				dispatch({ type: Types.FAILURE, payload: response?.error.message })
			}

			dispatch({
				type: Types.SET_BACKUP_KEY,
				payload: response,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE, payload: error.message })
		}
	}
}

export const reducerObject = {
	'mfa/LOADING': (state, action) => {
		return { ...state, ...action.payload }
	},
	'mfa/SET_MFA_OPTIONS': (state, action) => {
		return { ...state, mfaOptions: action.payload, loading: false, isLoaded: true }
	},
	'mfa/SET_OPTION': (state, action) => {
		return { ...state, option: action.payload, loading: false }
	},
	'mfa/SET_IS_VALID_MFA': (state, action) => {
		return { ...state, ...action.payload, loading: false }
	},
	'mfa/SET_IS_ACTIVATED_MFA': (state, action) => {
		return {
			...state,
			...action.payload,
			option: {
				secret: '',
				qrcode: '',
			},
			loading: false,
		}
	},
	'mfa/SET_IS_REMOVED_MFA': (state, action) => {
		return { ...state, ...action.payload, loading: false }
	},
	'mfa/SET_ERROR': (state, action) => {
		return { ...state, ...action.payload, loading: false }
	},
	'mfa/FAILURE': (state, action) => {
		return { ...state, failed: true, loading: false, error: action.payload }
	},
	'mfa/SET_CLEAN': state => {
		return { ...state, loading: false, error: '', isValid: false, isRemoved: false, isActivated: false }
	},
	'mfa/SET_CHANGE_MODAL_TYPE': (state, action) => {
		return { ...state, modalType: action.payload }
	},
	'mfa/SET_BACKUP_KEY': (state, action) => {
		return { ...state, backupKey: action.payload, loading: false }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
