import { applyMiddleware, createStore, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'

const PROD = process.env.REACT_APP_ENVIRONMENT === 'production'

const middlewareEnhancer = applyMiddleware(thunkMiddleware)
const composedEnhancers = PROD ? compose(middlewareEnhancer) : composeWithDevTools(middlewareEnhancer)

export default initialState => createStore(rootReducer, initialState, composedEnhancers)
