import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ConfigProvider } from 'antd'
import i18n from 'i18next'
import React, { Suspense, useCallback } from 'react'
import { initReactI18next } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import CustomThemeProvider from './context/CustomThemeProvider'
import KeycloakProvider from './context/KeycloakProvider'
import useTranslateHook from './hooks/useTranslateHook'
import keycloak from './keycloak'
import AppRoutes from './routes'
import createStore from './store'
import { antdLanguages } from './translate'
import { saveTokens } from './utils'

export const store = createStore({})
export const queryClient = new QueryClient()
const initOptions = {
	checkLoginIframe: false,
}

const CookieConsent = React.lazy(() => import('./components/Desktop/CookieConsent'))

// const localhost = window?.location?.hostname === 'localhost'

function App() {
	const lang = useTranslateHook()

	i18n.use(initReactI18next).init({
		resources: lang || {},
		supportedLngs: Object.keys(lang) || [],
		fallbackLng: JSON.parse(localStorage.getItem('language'))?.code || 'en',
		detection: {
			order: ['path', 'navigator'],
		},
		react: {
			useSuspense: true,
		},
	})

	const onLogout = useCallback(event => {
		const events = ['onAuthLogout', 'onAuthRefreshError']

		if (events.includes(event)) {
			localStorage.removeItem('isValidated')
			keycloak.clearToken()
		}
	}, [])

	const saveTokensOnApp = useCallback(async () => {
		const refreshExpired = keycloak?.refreshTokenParsed?.exp * 1000 < Date.now()

		if (refreshExpired) {
			await keycloak.logout()
		}

		if (keycloak?.token && keycloak?.refreshToken) {
			saveTokens({
				token: keycloak?.token,
				refreshToken: keycloak?.refreshToken,
				expRefresh: keycloak?.refreshTokenParsed?.exp * 1000,
				expToken: keycloak?.tokenParsed?.exp * 1000,
				name: keycloak?.tokenParsed?.name,
				email: keycloak?.tokenParsed?.email,
				iat: keycloak?.tokenParsed?.iat,
				userId: keycloak?.tokenParsed?.sub,
				logged: true,
			})
		}
	}, [])

	return (
		<Suspense fallback=''>
			<ReactKeycloakProvider
				authClient={keycloak}
				initOptions={initOptions}
				onEvent={onLogout}
				autoRefreshToken={false}
				onTokens={tokens => saveTokensOnApp(tokens)}
			>
				<Provider store={store}>
					<QueryClientProvider client={queryClient}>
						{/* {localhost && <ReactQueryDevtools initialIsOpen={false} />} */}
						<KeycloakProvider>
							<CustomThemeProvider>
								<ConfigProvider
									locale={antdLanguages[JSON.parse(localStorage.getItem('language'))?.aLang]}
									direction={JSON.parse(localStorage.getItem('language'))?.dir || 'ltr'}
								>
									<AppRoutes />
								</ConfigProvider>
							</CustomThemeProvider>
							<CookieConsent />
						</KeycloakProvider>
					</QueryClientProvider>
				</Provider>
			</ReactKeycloakProvider>
		</Suspense>
	)
}

export default App
