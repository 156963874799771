import { getExchangeBalances, getExchangeWallets, getWalletAddresses } from '../../services/manager'

export const Types = {
	SET_EXCHANGE_WALLETS: 'audit/SET_EXCHANGE_WALLETS',
	SET_EXCHANGE_BALANCES_SNAPSHOT: 'audit/SET_EXCHANGE_BALANCES_SNAPSHOT',
	SET_EXCHANGE_WALLETS_LOADING: 'audit/SET_EXCHANGE_WALLETS_LOADING',
	EXCHANGE_WALLETS_FAILURE: 'audit/EXCHANGE_WALLETS_FAILURE',
	SET_WALLET_ADDRESSES: 'audit/SET_WALLET_ADDRESSES',
	SET_WALLET_ADDRESSES_PARAMS: 'audit/SET_WALLET_ADDRESSES_PARAMS',
	SET_WALLET_ADDRESSES_LOADING: 'audit/SET_WALLET_ADDRESSES_LOADING',
	WALLET_ADDRESSES_FAILURE: 'audit/WALLET_ADDRESSES_FAILURE',
	SET_EXCHANGE_BALANCES: 'audit/SET_EXCHANGE_BALANCES',
	SET_EXCHANGE_BALANCES_LOADING: 'audit/SET_EXCHANGE_BALANCES_LOADING',
	EXCHANGE_BALANCES_FAILURE: 'audit/EXCHANGE_BALANCES_FAILURE',
}

const initialState = {
	exchangeWallets: [],
	exchangeWalletsLoading: false,
	exchangeWalletsFailure: false,
	walletAddresses: [],
	walletAddressesParams: {},
	walletAddressesLoading: false,
	walletAddressesFailure: false,
	exchangeBalances: [],
	exchangeBalancesSnapshot: '',
	exchangeBalancesLoading: false,
	exchangeBalancesFailure: false,
}

export const fetchExchangeWallets = () => {
	return async dispatch => {
		try {
			dispatch({ type: Types.SET_EXCHANGE_WALLETS_LOADING, payload: true })
			const data = await getExchangeWallets()
			dispatch({
				type: Types.SET_EXCHANGE_WALLETS,
				payload: data?.exchangeWallets,
			})
		} catch (error) {
			dispatch({
				type: Types.EXCHANGE_WALLETS_FAILURE,
			})
		} finally {
			dispatch({ type: Types.SET_EXCHANGE_WALLETS_LOADING, payload: false })
		}
	}
}

export const fetchWalletAddresses = ({ limit, skip, search, networkName }) => {
	return async dispatch => {
		try {
			dispatch({ type: Types.SET_WALLET_ADDRESSES_LOADING, payload: true })
			const data = await getWalletAddresses({ limit, skip, search, networkName })
			dispatch({
				type: Types.SET_WALLET_ADDRESSES,
				payload: data?.walletAddresses,
			})
			dispatch({
				type: Types.SET_WALLET_ADDRESSES_PARAMS,
				payload: data?.pagination,
			})
		} catch (error) {
			dispatch({
				type: Types.WALLET_ADDRESSES_FAILURE,
			})
		} finally {
			dispatch({ type: Types.SET_WALLET_ADDRESSES_LOADING, payload: false })
		}
	}
}

export const fetchExchangeBalances = () => {
	return async dispatch => {
		try {
			dispatch({ type: Types.SET_EXCHANGE_BALANCES_LOADING, payload: true })
			const data = await getExchangeBalances()
			dispatch({
				type: Types.SET_EXCHANGE_BALANCES,
				payload: data?.auditTokenBalances,
			})
			dispatch({
				type: Types.SET_EXCHANGE_BALANCES_SNAPSHOT,
				payload: data?.snapshot,
			})
		} catch (error) {
			dispatch({
				type: Types.EXCHANGE_BALANCES_FAILURE,
			})
		} finally {
			dispatch({ type: Types.SET_EXCHANGE_BALANCES_LOADING, payload: false })
		}
	}
}

export const reducerObject = {
	'audit/SET_EXCHANGE_WALLETS': (state, action) => {
		return { ...state, exchangeWallets: action.payload, exchangeWalletsFailure: false }
	},
	'audit/SET_EXCHANGE_WALLETS_LOADING': (state, action) => {
		return { ...state, exchangeWalletsLoading: action.payload }
	},
	'audit/EXCHANGE_WALLETS_FAILURE': state => {
		return { ...state, exchangeWalletsFailure: true }
	},
	'audit/SET_WALLET_ADDRESSES': (state, action) => {
		return { ...state, walletAddresses: action.payload, walletAddressesFailure: false }
	},
	'audit/SET_WALLET_ADDRESSES_PARAMS': (state, action) => {
		return { ...state, walletAddressesParams: action.payload, walletAddressesFailure: false }
	},
	'audit/SET_WALLET_ADDRESSES_LOADING': (state, action) => {
		return { ...state, walletAddressesLoading: action.payload }
	},
	'audit/WALLET_ADDRESSES_FAILURE': state => {
		return { ...state, walletAddressesFailure: true }
	},
	'audit/SET_EXCHANGE_BALANCES': (state, action) => {
		return { ...state, exchangeBalances: action.payload, exchangeBalancesFailure: false }
	},
	'audit/SET_EXCHANGE_BALANCES_SNAPSHOT': (state, action) => {
		return { ...state, exchangeBalancesSnapshot: action.payload }
	},
	'audit/SET_EXCHANGE_BALANCES_LOADING': (state, action) => {
		return { ...state, exchangeBalancesLoading: action.payload }
	},
	'audit/EXCHANGE_BALANCES_FAILURE': state => {
		return { ...state, exchangeBalancesFailure: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
