// source: src/mfa.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf')
var goog = jspb
var global = function () {
	if (this) {
		return this
	}
	if (typeof window !== 'undefined') {
		return window
	}
	if (typeof global !== 'undefined') {
		return global
	}
	if (typeof self !== 'undefined') {
		return self
	}
	return Function('return this')()
}.call(null)

var google_api_annotations_pb = require('./google/api/annotations_pb.js')
goog.object.extend(proto, google_api_annotations_pb)
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
goog.object.extend(proto, google_protobuf_empty_pb)
goog.exportSymbol('proto.mfa.ActivateMFARequest', null, global)
goog.exportSymbol('proto.mfa.ActivateMFAResponse', null, global)
goog.exportSymbol('proto.mfa.CreateMFARequest', null, global)
goog.exportSymbol('proto.mfa.CreateMFAResponse', null, global)
goog.exportSymbol('proto.mfa.GetMFAOptionsResponse', null, global)
goog.exportSymbol('proto.mfa.MFAOptions', null, global)
goog.exportSymbol('proto.mfa.OKTA_CLIENT', null, global)
goog.exportSymbol('proto.mfa.Options', null, global)
goog.exportSymbol('proto.mfa.RecoverMFARequest', null, global)
goog.exportSymbol('proto.mfa.RecoverMFAResponse', null, global)
goog.exportSymbol('proto.mfa.RecoverTOTPBackupKeyRequest', null, global)
goog.exportSymbol('proto.mfa.RecoverTOTPBackupKeyResponse', null, global)
goog.exportSymbol('proto.mfa.RemoveMFARequest', null, global)
goog.exportSymbol('proto.mfa.ValidateMFARequest', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.CreateMFARequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.CreateMFARequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.CreateMFARequest.displayName = 'proto.mfa.CreateMFARequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.CreateMFAResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.CreateMFAResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.CreateMFAResponse.displayName = 'proto.mfa.CreateMFAResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.ActivateMFARequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.ActivateMFARequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.ActivateMFARequest.displayName = 'proto.mfa.ActivateMFARequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.ActivateMFAResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.ActivateMFAResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.ActivateMFAResponse.displayName = 'proto.mfa.ActivateMFAResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.RemoveMFARequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.RemoveMFARequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.RemoveMFARequest.displayName = 'proto.mfa.RemoveMFARequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.ValidateMFARequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.ValidateMFARequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.ValidateMFARequest.displayName = 'proto.mfa.ValidateMFARequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.MFAOptions = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.MFAOptions, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.MFAOptions.displayName = 'proto.mfa.MFAOptions'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.GetMFAOptionsResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.mfa.GetMFAOptionsResponse.repeatedFields_, null)
}
goog.inherits(proto.mfa.GetMFAOptionsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.GetMFAOptionsResponse.displayName = 'proto.mfa.GetMFAOptionsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.RecoverMFARequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.RecoverMFARequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.RecoverMFARequest.displayName = 'proto.mfa.RecoverMFARequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.RecoverMFAResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.RecoverMFAResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.RecoverMFAResponse.displayName = 'proto.mfa.RecoverMFAResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.RecoverTOTPBackupKeyRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.RecoverTOTPBackupKeyRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.RecoverTOTPBackupKeyRequest.displayName = 'proto.mfa.RecoverTOTPBackupKeyRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mfa.RecoverTOTPBackupKeyResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.mfa.RecoverTOTPBackupKeyResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.mfa.RecoverTOTPBackupKeyResponse.displayName = 'proto.mfa.RecoverTOTPBackupKeyResponse'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.CreateMFARequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.CreateMFARequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.CreateMFARequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.CreateMFARequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				option: jspb.Message.getFieldWithDefault(msg, 1, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.CreateMFARequest}
 */
proto.mfa.CreateMFARequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.CreateMFARequest()
	return proto.mfa.CreateMFARequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.CreateMFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.CreateMFARequest}
 */
proto.mfa.CreateMFARequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.CreateMFARequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.CreateMFARequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.CreateMFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.CreateMFARequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(1, f)
	}
}

/**
 * optional Options option = 1;
 * @return {!proto.mfa.Options}
 */
proto.mfa.CreateMFARequest.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.CreateMFARequest} returns this
 */
proto.mfa.CreateMFARequest.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.CreateMFAResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.CreateMFAResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.CreateMFAResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.CreateMFAResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				secret: jspb.Message.getFieldWithDefault(msg, 1, ''),
				qrcode: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.CreateMFAResponse}
 */
proto.mfa.CreateMFAResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.CreateMFAResponse()
	return proto.mfa.CreateMFAResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.CreateMFAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.CreateMFAResponse}
 */
proto.mfa.CreateMFAResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setSecret(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setQrcode(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.CreateMFAResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.CreateMFAResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.CreateMFAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.CreateMFAResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getSecret()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getQrcode()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string secret = 1;
 * @return {string}
 */
proto.mfa.CreateMFAResponse.prototype.getSecret = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.CreateMFAResponse} returns this
 */
proto.mfa.CreateMFAResponse.prototype.setSecret = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string qrCode = 2;
 * @return {string}
 */
proto.mfa.CreateMFAResponse.prototype.getQrcode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.CreateMFAResponse} returns this
 */
proto.mfa.CreateMFAResponse.prototype.setQrcode = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.ActivateMFARequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.ActivateMFARequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.ActivateMFARequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.ActivateMFARequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				secret: jspb.Message.getFieldWithDefault(msg, 1, ''),
				code: jspb.Message.getFieldWithDefault(msg, 2, ''),
				option: jspb.Message.getFieldWithDefault(msg, 3, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.ActivateMFARequest}
 */
proto.mfa.ActivateMFARequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.ActivateMFARequest()
	return proto.mfa.ActivateMFARequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.ActivateMFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.ActivateMFARequest}
 */
proto.mfa.ActivateMFARequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setSecret(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setCode(value)
				break
			case 3:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.ActivateMFARequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.ActivateMFARequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.ActivateMFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.ActivateMFARequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getSecret()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getCode()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(3, f)
	}
}

/**
 * optional string secret = 1;
 * @return {string}
 */
proto.mfa.ActivateMFARequest.prototype.getSecret = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.ActivateMFARequest} returns this
 */
proto.mfa.ActivateMFARequest.prototype.setSecret = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string code = 2;
 * @return {string}
 */
proto.mfa.ActivateMFARequest.prototype.getCode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.ActivateMFARequest} returns this
 */
proto.mfa.ActivateMFARequest.prototype.setCode = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional Options option = 3;
 * @return {!proto.mfa.Options}
 */
proto.mfa.ActivateMFARequest.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.ActivateMFARequest} returns this
 */
proto.mfa.ActivateMFARequest.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.ActivateMFAResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.ActivateMFAResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.ActivateMFAResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.ActivateMFAResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				backupkey: jspb.Message.getFieldWithDefault(msg, 1, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.ActivateMFAResponse}
 */
proto.mfa.ActivateMFAResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.ActivateMFAResponse()
	return proto.mfa.ActivateMFAResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.ActivateMFAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.ActivateMFAResponse}
 */
proto.mfa.ActivateMFAResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setBackupkey(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.ActivateMFAResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.ActivateMFAResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.ActivateMFAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.ActivateMFAResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getBackupkey()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
}

/**
 * optional string backupKey = 1;
 * @return {string}
 */
proto.mfa.ActivateMFAResponse.prototype.getBackupkey = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.ActivateMFAResponse} returns this
 */
proto.mfa.ActivateMFAResponse.prototype.setBackupkey = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.RemoveMFARequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.RemoveMFARequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.RemoveMFARequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.RemoveMFARequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				code: jspb.Message.getFieldWithDefault(msg, 1, ''),
				option: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.RemoveMFARequest}
 */
proto.mfa.RemoveMFARequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.RemoveMFARequest()
	return proto.mfa.RemoveMFARequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.RemoveMFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.RemoveMFARequest}
 */
proto.mfa.RemoveMFARequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setCode(value)
				break
			case 2:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.RemoveMFARequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.RemoveMFARequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.RemoveMFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.RemoveMFARequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCode()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(2, f)
	}
}

/**
 * optional string code = 1;
 * @return {string}
 */
proto.mfa.RemoveMFARequest.prototype.getCode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.RemoveMFARequest} returns this
 */
proto.mfa.RemoveMFARequest.prototype.setCode = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional Options option = 2;
 * @return {!proto.mfa.Options}
 */
proto.mfa.RemoveMFARequest.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.RemoveMFARequest} returns this
 */
proto.mfa.RemoveMFARequest.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.ValidateMFARequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.ValidateMFARequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.ValidateMFARequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.ValidateMFARequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				code: jspb.Message.getFieldWithDefault(msg, 1, ''),
				option: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.ValidateMFARequest}
 */
proto.mfa.ValidateMFARequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.ValidateMFARequest()
	return proto.mfa.ValidateMFARequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.ValidateMFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.ValidateMFARequest}
 */
proto.mfa.ValidateMFARequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setCode(value)
				break
			case 2:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.ValidateMFARequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.ValidateMFARequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.ValidateMFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.ValidateMFARequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCode()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(2, f)
	}
}

/**
 * optional string code = 1;
 * @return {string}
 */
proto.mfa.ValidateMFARequest.prototype.getCode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.ValidateMFARequest} returns this
 */
proto.mfa.ValidateMFARequest.prototype.setCode = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional Options option = 2;
 * @return {!proto.mfa.Options}
 */
proto.mfa.ValidateMFARequest.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.ValidateMFARequest} returns this
 */
proto.mfa.ValidateMFARequest.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.MFAOptions.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.MFAOptions.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.MFAOptions} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.MFAOptions.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				option: jspb.Message.getFieldWithDefault(msg, 1, 0),
				isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
				isconfigured: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.MFAOptions}
 */
proto.mfa.MFAOptions.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.MFAOptions()
	return proto.mfa.MFAOptions.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.MFAOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.MFAOptions}
 */
proto.mfa.MFAOptions.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			case 2:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIsactive(value)
				break
			case 3:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIsconfigured(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.MFAOptions.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.MFAOptions.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.MFAOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.MFAOptions.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(1, f)
	}
	f = message.getIsactive()
	if (f) {
		writer.writeBool(2, f)
	}
	f = message.getIsconfigured()
	if (f) {
		writer.writeBool(3, f)
	}
}

/**
 * optional Options option = 1;
 * @return {!proto.mfa.Options}
 */
proto.mfa.MFAOptions.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.MFAOptions} returns this
 */
proto.mfa.MFAOptions.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 1, value)
}

/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.mfa.MFAOptions.prototype.getIsactive = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false))
}

/**
 * @param {boolean} value
 * @return {!proto.mfa.MFAOptions} returns this
 */
proto.mfa.MFAOptions.prototype.setIsactive = function (value) {
	return jspb.Message.setProto3BooleanField(this, 2, value)
}

/**
 * optional bool isConfigured = 3;
 * @return {boolean}
 */
proto.mfa.MFAOptions.prototype.getIsconfigured = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false))
}

/**
 * @param {boolean} value
 * @return {!proto.mfa.MFAOptions} returns this
 */
proto.mfa.MFAOptions.prototype.setIsconfigured = function (value) {
	return jspb.Message.setProto3BooleanField(this, 3, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mfa.GetMFAOptionsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.GetMFAOptionsResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.GetMFAOptionsResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.GetMFAOptionsResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.GetMFAOptionsResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				optionsList: jspb.Message.toObjectList(
					msg.getOptionsList(),
					proto.mfa.MFAOptions.toObject,
					includeInstance
				),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.GetMFAOptionsResponse}
 */
proto.mfa.GetMFAOptionsResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.GetMFAOptionsResponse()
	return proto.mfa.GetMFAOptionsResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.GetMFAOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.GetMFAOptionsResponse}
 */
proto.mfa.GetMFAOptionsResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.mfa.MFAOptions()
				reader.readMessage(value, proto.mfa.MFAOptions.deserializeBinaryFromReader)
				msg.addOptions(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.GetMFAOptionsResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.GetMFAOptionsResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.GetMFAOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.GetMFAOptionsResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOptionsList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.mfa.MFAOptions.serializeBinaryToWriter)
	}
}

/**
 * repeated MFAOptions options = 1;
 * @return {!Array<!proto.mfa.MFAOptions>}
 */
proto.mfa.GetMFAOptionsResponse.prototype.getOptionsList = function () {
	return /** @type{!Array<!proto.mfa.MFAOptions>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.mfa.MFAOptions, 1)
	)
}

/**
 * @param {!Array<!proto.mfa.MFAOptions>} value
 * @return {!proto.mfa.GetMFAOptionsResponse} returns this
 */
proto.mfa.GetMFAOptionsResponse.prototype.setOptionsList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.mfa.MFAOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mfa.MFAOptions}
 */
proto.mfa.GetMFAOptionsResponse.prototype.addOptions = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mfa.MFAOptions, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mfa.GetMFAOptionsResponse} returns this
 */
proto.mfa.GetMFAOptionsResponse.prototype.clearOptionsList = function () {
	return this.setOptionsList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.RecoverMFARequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.RecoverMFARequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.RecoverMFARequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.RecoverMFARequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				option: jspb.Message.getFieldWithDefault(msg, 1, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.RecoverMFARequest}
 */
proto.mfa.RecoverMFARequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.RecoverMFARequest()
	return proto.mfa.RecoverMFARequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.RecoverMFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.RecoverMFARequest}
 */
proto.mfa.RecoverMFARequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {!proto.mfa.Options} */ (reader.readEnum())
				msg.setOption(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.RecoverMFARequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.RecoverMFARequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.RecoverMFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.RecoverMFARequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOption()
	if (f !== 0.0) {
		writer.writeEnum(1, f)
	}
}

/**
 * optional Options option = 1;
 * @return {!proto.mfa.Options}
 */
proto.mfa.RecoverMFARequest.prototype.getOption = function () {
	return /** @type {!proto.mfa.Options} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {!proto.mfa.Options} value
 * @return {!proto.mfa.RecoverMFARequest} returns this
 */
proto.mfa.RecoverMFARequest.prototype.setOption = function (value) {
	return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.RecoverMFAResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.RecoverMFAResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.RecoverMFAResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.RecoverMFAResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				secret: jspb.Message.getFieldWithDefault(msg, 1, ''),
				qrcode: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.RecoverMFAResponse}
 */
proto.mfa.RecoverMFAResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.RecoverMFAResponse()
	return proto.mfa.RecoverMFAResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.RecoverMFAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.RecoverMFAResponse}
 */
proto.mfa.RecoverMFAResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setSecret(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setQrcode(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.RecoverMFAResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.RecoverMFAResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.RecoverMFAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.RecoverMFAResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getSecret()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getQrcode()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string secret = 1;
 * @return {string}
 */
proto.mfa.RecoverMFAResponse.prototype.getSecret = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.RecoverMFAResponse} returns this
 */
proto.mfa.RecoverMFAResponse.prototype.setSecret = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string qrCode = 2;
 * @return {string}
 */
proto.mfa.RecoverMFAResponse.prototype.getQrcode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.RecoverMFAResponse} returns this
 */
proto.mfa.RecoverMFAResponse.prototype.setQrcode = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.RecoverTOTPBackupKeyRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.RecoverTOTPBackupKeyRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.RecoverTOTPBackupKeyRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				code: jspb.Message.getFieldWithDefault(msg, 1, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.RecoverTOTPBackupKeyRequest}
 */
proto.mfa.RecoverTOTPBackupKeyRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.RecoverTOTPBackupKeyRequest()
	return proto.mfa.RecoverTOTPBackupKeyRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.RecoverTOTPBackupKeyRequest}
 */
proto.mfa.RecoverTOTPBackupKeyRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setCode(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.RecoverTOTPBackupKeyRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.RecoverTOTPBackupKeyRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.RecoverTOTPBackupKeyRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCode()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
}

/**
 * optional string code = 1;
 * @return {string}
 */
proto.mfa.RecoverTOTPBackupKeyRequest.prototype.getCode = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.RecoverTOTPBackupKeyRequest} returns this
 */
proto.mfa.RecoverTOTPBackupKeyRequest.prototype.setCode = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.mfa.RecoverTOTPBackupKeyResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.mfa.RecoverTOTPBackupKeyResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.mfa.RecoverTOTPBackupKeyResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.mfa.RecoverTOTPBackupKeyResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				backupkey: jspb.Message.getFieldWithDefault(msg, 1, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mfa.RecoverTOTPBackupKeyResponse}
 */
proto.mfa.RecoverTOTPBackupKeyResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.mfa.RecoverTOTPBackupKeyResponse()
	return proto.mfa.RecoverTOTPBackupKeyResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mfa.RecoverTOTPBackupKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mfa.RecoverTOTPBackupKeyResponse}
 */
proto.mfa.RecoverTOTPBackupKeyResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setBackupkey(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mfa.RecoverTOTPBackupKeyResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.mfa.RecoverTOTPBackupKeyResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mfa.RecoverTOTPBackupKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mfa.RecoverTOTPBackupKeyResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getBackupkey()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
}

/**
 * optional string backupKey = 1;
 * @return {string}
 */
proto.mfa.RecoverTOTPBackupKeyResponse.prototype.getBackupkey = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.mfa.RecoverTOTPBackupKeyResponse} returns this
 */
proto.mfa.RecoverTOTPBackupKeyResponse.prototype.setBackupkey = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * @enum {number}
 */
proto.mfa.Options = {
	MFA_UNKNOWN: 0,
	EMAIL: 1,
	TOTP: 2,
	SMS: 3,
}

/**
 * @enum {number}
 */
proto.mfa.OKTA_CLIENT = {
	UNKNOWN: 0,
	ANDROID: 1,
	IOS: 2,
	WEB: 3,
}

goog.object.extend(exports, proto.mfa)
