import React from 'react'

// Constants using env variables
export const ORDERS_LIMIT_QUANTITY = Number(process.env.REACT_APP_ORDERS_LIMIT_QUANTITY) || 70
export const STORAGE_TOKENS = 'klever/tokens'
export const COMMUNICATION_STORAGE = 'klever/communication'
export const LP_REWARDS_USD_FEATURED = 3000000
export const KLV_HOLDER_AMOUNT = 400000
export const KEYPAIRS_LIST_LENGTH = 10
export const BUY_FOR_STAKE_REMAINING_TIME = 30

export const TRM_WITHDRAW_ERROR_MSGS = [
	'this address is under sanction',
	'this address is considered high risk and we cannot transfer to this address (compliance reasons)',
]

export const allowedKeys = new Set([
	'ArrowUp',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
	'Backspace',
	'Delete',
	'Tab',
	'Enter',
	'End',
	'Home',
])

export const dvkHtml = () => {
	return (
		<>
			<div>
				<p>
					Devikins is a free Web3 mobile RPG game available for iOS and Android, set in the Void. Your mission
					is to uncover the secrets behind the disappearance of the Overlord by embarking on PvE quests or
					challenging other players in PvP battles.
				</p>
				<p>
					The game leverages the Web3 environment, allowing you to earn DVK (Devikins Token) while playing and
					completing missions. DVK can later be exchanged for real money. Additionally, since each character
					can be an NFT (Non-Fungible Token), you have full ownership of your devikin and can even sell or
					trade it on our marketplace.
				</p>{' '}
				<p>
					To learn more about Devikins and how to earn DVK by playing, visit our website:{' '}
					<a href='https://devikins.com/' target='_blank' rel='noreferrer'>
						https://devikins.com/
					</a>{' '}
				</p>
				<p>
					New players can use the code NEWDVK when creating an account to receive beginner
					{"'"}s assistance.
				</p>
			</div>
		</>
	)
}
