import isEmpty from 'lodash/isEmpty'

import EventsClient from '../../services/grpcweb/EventsClient'
import { getTokenBalance, getBalance, getBalances } from '../../services/manager'

export const Types = {
	FETCH_BASE_QUOTE_BALANCE: 'balance/FETCH_BASE_QUOTE_BALANCE',
	LOADING_BALANCE: 'balance/LOADING_BALANCE',
	FAILURE: 'balance/FAILURE',
	SET_BALANCE: 'balance/SET_BALANCE',
	SET_BALANCES: 'balance/SET_BALANCES',
	SET_QUOTE_BALANCE: 'balance/SET_QUOTE_BALANCE',
	SET_BASE_BALANCE: 'balance/SET_BASE_BALANCE',
	SET_BALANCE_STREAM: 'balance/SET_BALANCE_STREAM',
	SET_BASE_BALANCE_STREAM: 'balance/SET_BASE_BALANCE_STREAM',
	SET_QUOTE_BALANCE_STREAM: 'balance/SET_QUOTE_BALANCE_STREAM',
	FAILURE_BALANCE: 'balance/FAILURE_BALANCE',
	FAILURE_BALANCES: 'balance/FAILURE_BALANCES',
	FAILURE_QUOTE_BALANCE: 'balance/FAILURE_QUOTE_BALANCE',
	FAILURE_BASE_BALANCE: 'balance/FAILURE_BASE_BALANCE',
	FAILURE_BALANCE_STREAM: 'balance/FAILURE_BALANCE_STREAM',
	FAILURE_BASE_BALANCE_STREAM: 'balance/FAILURE_BASE_BALANCE_STREAM',
	FAILURE_QUOTE_BALANCE_STREAM: 'balance/FAILURE_QUOTE_BALANCE_STREAM',
}

const initialState = {
	loading: false,
	baseBalance: {},
	quoteBalance: {},
	balance: {
		ID: 0,
		AccountID: 0,
		TokenID: 0,
		Balance: 0,
		Available: 0,
		Abbr: 'ABBR',
	},
	failed: false,
	failedBalance: false,
	failedBalances: false,
	failedQuoteBalance: false,
	failedBaseBalance: false,
	failedBalanceStream: false,
	failedBaseBalanceStream: false,
	failedQuoteBalanceStream: false,
	balanceStream: {},
	balances: [],
	quoteBalanceStream: {},
	baseBalanceStream: {},
}

export const fetchBaseQuoteBalances = (BaseID, QuoteID) => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING_BALANCE,
				payload: {
					loading: true,
				},
			})
			const { balance, keypairs } = getStore()
			const baseBalance = await getTokenBalance(BaseID)
			const quoteBalance = await getTokenBalance(QuoteID)

			if (
				baseBalance?.TokenID === keypairs?.selectedKeypair?.BaseID &&
				quoteBalance?.TokenID === keypairs?.selectedKeypair?.QuoteID
			) {
				if (isEmpty(balance.baseBalanceStream))
					dispatch(setStreamBalance(BaseID, Types.SET_BASE_BALANCE_STREAM, Types.FAILURE_BASE_BALANCE_STREAM))
				if (isEmpty(balance.quoteBalanceStream))
					dispatch(
						setStreamBalance(QuoteID, Types.SET_QUOTE_BALANCE_STREAM, Types.FAILURE_QUOTE_BALANCE_STREAM)
					)
				dispatch({
					type: Types.FETCH_BASE_QUOTE_BALANCE,
					payload: {
						baseBalance,
						quoteBalance,
					},
				})
			} else {
				dispatch({
					type: Types.LOADING_BALANCE,
					payload: {
						loading: false,
					},
				})
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const fetchBalance = TokenID => {
	return async (dispatch, getStore) => {
		try {
			const response = await getBalance(TokenID)
			const { balance } = getStore()
			if (isEmpty(balance.balanceStream))
				dispatch(setStreamBalance(TokenID, Types.SET_BALANCE_STREAM, Types.FAILURE_BALANCE_STREAM))
			dispatch({
				type: Types.SET_BALANCE,
				payload: response,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const fetchBalances = () => {
	return async (dispatch, getStore) => {
		dispatch({
			type: Types.LOADING_BALANCE,
			payload: {
				loading: true,
			},
		})
		const { Balances, error } = await getBalances()
		const { balance } = getStore()
		if (isEmpty(balance.balanceStream))
			dispatch(setStreamBalance('', Types.SET_BALANCE_STREAM, Types.FAILURE_BALANCE_STREAM))
		if (error) {
			dispatch({ type: Types.FAILURE })
		}
		if (Balances) {
			dispatch({
				type: Types.SET_BALANCES,
				payload: Balances,
			})
		}
	}
}
export const setStreamBalance = (tokenID, type = Types.FAILURE, typeFailed = Types.FAILURE) => {
	return async dispatch => {
		try {
			const streamBalance = new EventsClient()
			dispatch({
				type,
				payload: streamBalance,
			})
		} catch (error) {
			dispatch({ type: typeFailed })
		}
	}
}

export const updateBalance = event => {
	return (dispatch, getStore) => {
		try {
			const { balance } = getStore()
			if (!!event.BalancesList && balance.balance !== event.BalancesList) {
				dispatch({
					type: Types.SET_BALANCE,
					payload: event.BalancesList[0],
				})
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_BALANCE })
		}
	}
}

export const updateBalances = event => {
	return (dispatch, getStore) => {
		try {
			const { balance } = getStore()
			const newbalances = []
			const eventbalances = Object.values(event.BalancesList)

			eventbalances.map(newbalance => {
				const oldBalances = balance?.balances?.find(oldBalance => oldBalance.ID === newbalance.ID)
				if (oldBalances) {
					delete newbalance.Abbr
					return newbalances.push({ Abbr: oldBalances.Abbr, ...newbalance })
				}
				return oldBalances
			})
			dispatch({
				type: Types.SET_BALANCES,
				payload: newbalances,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_BALANCE })
		}
	}
}

export const updateQuoteBalance = event => {
	return (dispatch, getStore) => {
		try {
			const { balance, keypairs } = getStore()
			if (event?.BalancesList[0]?.TokenID === keypairs?.selectedKeypair?.QuoteID) {
				if (!!event.BalancesList && balance.quoteBalance !== event.BalancesList) {
					dispatch({
						type: Types.SET_QUOTE_BALANCE,
						payload: event.BalancesList[0],
					})
				}
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_QUOTE_BALANCE })
		}
	}
}

export const updateBaseBalance = event => {
	return (dispatch, getStore) => {
		try {
			const { balance, keypairs } = getStore()
			if (event?.BalancesList[0]?.TokenID === keypairs?.selectedKeypair?.BaseID) {
				if (!!event.BalancesList && balance.baseBalance !== event.BalancesList) {
					dispatch({
						type: Types.SET_BASE_BALANCE,
						payload: event.BalancesList[0],
					})
				}
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_BASE_BALANCE })
		}
	}
}

export const clearBaseBalanceStream = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_BASE_BALANCE_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_BASE_BALANCE_STREAM })
		}
	}
}

export const clearQuoteBalanceStream = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_QUOTE_BALANCE_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_QUOTE_BALANCE_STREAM })
		}
	}
}

export const clearBalanceStream = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_BALANCE_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_BALANCE_STREAM })
		}
	}
}

export const reducerObject = {
	'balance/FETCH_BASE_QUOTE_BALANCE': (state, action) => {
		return { ...state, loading: false, ...action.payload }
	},
	'balance/LOADING_BALANCE': (state, action) => {
		return { ...state, ...action.payload }
	},
	'balance/FAILURE_BALANCE': state => {
		return { ...state, failedBalance: true }
	},
	'balance/FAILURE_QUOTE_BALANCE': state => {
		return { ...state, failedQuoteBalance: true }
	},
	'balance/FAILURE_BALANCES': state => {
		return { ...state, failedBalances: true }
	},
	'balance/FAILURE_BASE_BALANCE': state => {
		return { ...state, failedBaseBalance: true }
	},
	'balance/FAILURE_BALANCE_STREAM': state => {
		return { ...state, failedBalanceStream: true }
	},
	'balance/FAILURE_BASE_BALANCE_STREAM': state => {
		return { ...state, failedBaseBalanceStream: true }
	},
	'balance/FAILURE_QUOTE_BALANCE_STREAM': state => {
		return { ...state, failedQuoteBalanceStream: true }
	},
	'balance/SET_BALANCE': (state, action) => {
		return { ...state, balance: action.payload, loading: false }
	},
	'balance/SET_BALANCES': (state, action) => {
		return { ...state, balances: action.payload, loading: false }
	},
	'balance/SET_QUOTE_BALANCE': (state, action) => {
		return { ...state, quoteBalance: action.payload }
	},
	'balance/SET_BASE_BALANCE': (state, action) => {
		return { ...state, baseBalance: action.payload }
	},
	'balance/SET_BALANCE_STREAM': (state, action) => {
		return { ...state, balanceStream: action.payload }
	},
	'balance/SET_BASE_BALANCE_STREAM': (state, action) => {
		return { ...state, baseBalanceStream: action.payload }
	},
	'balance/SET_QUOTE_BALANCE_STREAM': (state, action) => {
		return { ...state, quoteBalanceStream: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
