import { getConvertTokens, getTokens } from '../../services/manager'
import { isProd } from '../../utils'

export const Types = {
	FETCH_TOKENS: 'tokens/FETCH_TOKENS',
	FETCH_CONVERT_TOKENS: 'tokens/FETCH_CONVERT_TOKENS',
	FETCH_TOKENS_IN_USD: 'tokens/FETCH_TOKENS_IN_USD',
	SET_SELECTED_TOKENS: 'tokens/SET_SELECTED_TOKENS',
	SET_LOADING_TOKENS: 'tokens/SET_LOADING_TOKENS',
	FAILURE: 'tokens/FAILURE',
}

const initialState = {
	tokensList: [],
	convertTokens: [],
	tokensListInUsd: [],
	selectedToken: {},
	failed: false,
	loading: false,
}

export const fetchTokens = (currency, isAuth = true, Abbr, NonFungible) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: true,
			})

			const { Tokens, error } = await getTokens(currency, isAuth, Abbr, NonFungible)

			if (error) return dispatch({ type: Types.FAILURE })

			if (Tokens) {
				const inProd = isProd()

				if (inProd) {
					const prodTokens = Tokens?.filter(token => !token?.IsBeta)
					dispatch({
						type: Types.FETCH_TOKENS,
						payload: prodTokens,
					})
					return
				}

				dispatch({
					type: Types.FETCH_TOKENS,
					payload: Tokens,
				})
			}
			return Tokens
		} finally {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: false,
			})
		}
	}
}

export const fetchTokensInUsd = (isAuth = true, Abbr, NonFungible) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: true,
			})

			const { Tokens, error } = await getTokens('USD', isAuth, Abbr, NonFungible)

			if (error) return dispatch({ type: Types.FAILURE })

			if (Tokens) {
				const inProd = isProd()

				if (inProd) {
					const prodTokens = Tokens?.filter(token => !token?.IsBeta)
					dispatch({
						type: Types.FETCH_TOKENS_IN_USD,
						payload: prodTokens,
					})
					return
				}

				dispatch({
					type: Types.FETCH_TOKENS_IN_USD,
					payload: Tokens,
				})
			}
			return Tokens
		} finally {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: false,
			})
		}
	}
}

export const fetchConvertTokens = (currency, isAuth) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: true,
			})

			const response = await getConvertTokens({ currency, isAuth })

			if (response?.error) return dispatch({ type: Types.FAILURE })

			if (response?.convertTokens) {
				dispatch({
					type: Types.FETCH_CONVERT_TOKENS,
					payload: response?.convertTokens,
				})
			}
			return response?.convertTokens
		} finally {
			dispatch({
				type: Types.SET_LOADING_TOKENS,
				payload: false,
			})
		}
	}
}

export const setSelectedToken = token => {
	return async dispatch => {
		dispatch({
			type: Types.SET_SELECTED_TOKENS,
			payload: token,
		})
	}
}

export const reducerObject = {
	'tokens/FETCH_TOKENS': (state, action) => {
		return { ...state, tokensList: action.payload, failed: false }
	},
	'tokens/FETCH_CONVERT_TOKENS': (state, action) => {
		return { ...state, convertTokens: action.payload, failed: false }
	},
	'tokens/FETCH_TOKENS_IN_USD': (state, action) => {
		return { ...state, tokensListInUsd: action.payload, failed: false }
	},
	'tokens/SET_SELECTED_TOKENS': (state, action) => {
		return { ...state, selectedToken: action.payload, failed: false, loading: false }
	},
	'tokens/SET_LOADING_TOKENS': (state, action) => {
		return { ...state, loading: action.payload }
	},
	'tokens/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
