import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = ({ children }) => {
	const location = useLocation()

	useEffect(() => {
		history.scrollRestoration = 'manual'
		window.scrollTo(0, 0)
	}, [location])

	return <div data-testid='scroll-to-top'>{children}</div>
}

ScrollToTop.propTypes = {
	children: PropTypes.node,
}

export default ScrollToTop
