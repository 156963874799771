/**
 * @fileoverview gRPC-Web generated client stub for mfa
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {}
grpc.web = require('grpc-web')

var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {}
proto.mfa = require('./mfa_pb.js')

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mfa.MFAServiceClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options.format = 'binary'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mfa.MFAServicePromiseClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options.format = 'binary'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.CreateMFARequest,
 *   !proto.mfa.CreateMFAResponse>}
 */
const methodDescriptor_MFAService_CreateMFA = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/CreateMFA',
	grpc.web.MethodType.UNARY,
	proto.mfa.CreateMFARequest,
	proto.mfa.CreateMFAResponse,
	/**
	 * @param {!proto.mfa.CreateMFARequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.mfa.CreateMFAResponse.deserializeBinary
)

/**
 * @param {!proto.mfa.CreateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mfa.CreateMFAResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mfa.CreateMFAResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.createMFA = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/CreateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_CreateMFA,
		callback
	)
}

/**
 * @param {!proto.mfa.CreateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mfa.CreateMFAResponse>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.createMFA = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/CreateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_CreateMFA
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.ActivateMFARequest,
 *   !proto.mfa.ActivateMFAResponse>}
 */
const methodDescriptor_MFAService_ActivateMFA = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/ActivateMFA',
	grpc.web.MethodType.UNARY,
	proto.mfa.ActivateMFARequest,
	proto.mfa.ActivateMFAResponse,
	/**
	 * @param {!proto.mfa.ActivateMFARequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.mfa.ActivateMFAResponse.deserializeBinary
)

/**
 * @param {!proto.mfa.ActivateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mfa.ActivateMFAResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mfa.ActivateMFAResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.activateMFA = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/ActivateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_ActivateMFA,
		callback
	)
}

/**
 * @param {!proto.mfa.ActivateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mfa.ActivateMFAResponse>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.activateMFA = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/ActivateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_ActivateMFA
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.ValidateMFARequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_MFAService_ValidateMFA = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/ValidateMFA',
	grpc.web.MethodType.UNARY,
	proto.mfa.ValidateMFARequest,
	google_protobuf_empty_pb.Empty,
	/**
	 * @param {!proto.mfa.ValidateMFARequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	google_protobuf_empty_pb.Empty.deserializeBinary
)

/**
 * @param {!proto.mfa.ValidateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.validateMFA = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/ValidateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_ValidateMFA,
		callback
	)
}

/**
 * @param {!proto.mfa.ValidateMFARequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.validateMFA = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/ValidateMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_ValidateMFA
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.RemoveMFARequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_MFAService_RemoveMFA = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/RemoveMFA',
	grpc.web.MethodType.UNARY,
	proto.mfa.RemoveMFARequest,
	google_protobuf_empty_pb.Empty,
	/**
	 * @param {!proto.mfa.RemoveMFARequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	google_protobuf_empty_pb.Empty.deserializeBinary
)

/**
 * @param {!proto.mfa.RemoveMFARequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.removeMFA = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/RemoveMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_RemoveMFA,
		callback
	)
}

/**
 * @param {!proto.mfa.RemoveMFARequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.removeMFA = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/RemoveMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_RemoveMFA
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mfa.GetMFAOptionsResponse>}
 */
const methodDescriptor_MFAService_GetMFAOptions = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/GetMFAOptions',
	grpc.web.MethodType.UNARY,
	google_protobuf_empty_pb.Empty,
	proto.mfa.GetMFAOptionsResponse,
	/**
	 * @param {!proto.google.protobuf.Empty} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.mfa.GetMFAOptionsResponse.deserializeBinary
)

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mfa.GetMFAOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mfa.GetMFAOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.getMFAOptions = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/GetMFAOptions',
		request,
		metadata || {},
		methodDescriptor_MFAService_GetMFAOptions,
		callback
	)
}

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mfa.GetMFAOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.getMFAOptions = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/GetMFAOptions',
		request,
		metadata || {},
		methodDescriptor_MFAService_GetMFAOptions
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.RecoverMFARequest,
 *   !proto.mfa.RecoverMFAResponse>}
 */
const methodDescriptor_MFAService_RecoverMFA = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/RecoverMFA',
	grpc.web.MethodType.UNARY,
	proto.mfa.RecoverMFARequest,
	proto.mfa.RecoverMFAResponse,
	/**
	 * @param {!proto.mfa.RecoverMFARequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.mfa.RecoverMFAResponse.deserializeBinary
)

/**
 * @param {!proto.mfa.RecoverMFARequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mfa.RecoverMFAResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mfa.RecoverMFAResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.recoverMFA = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/RecoverMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_RecoverMFA,
		callback
	)
}

/**
 * @param {!proto.mfa.RecoverMFARequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mfa.RecoverMFAResponse>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.recoverMFA = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/RecoverMFA',
		request,
		metadata || {},
		methodDescriptor_MFAService_RecoverMFA
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mfa.RecoverTOTPBackupKeyRequest,
 *   !proto.mfa.RecoverTOTPBackupKeyResponse>}
 */
const methodDescriptor_MFAService_RecoverTOTPBackupKey = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/RecoverTOTPBackupKey',
	grpc.web.MethodType.UNARY,
	proto.mfa.RecoverTOTPBackupKeyRequest,
	proto.mfa.RecoverTOTPBackupKeyResponse,
	/**
	 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.mfa.RecoverTOTPBackupKeyResponse.deserializeBinary
)

/**
 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mfa.RecoverTOTPBackupKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mfa.RecoverTOTPBackupKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.recoverTOTPBackupKey = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/RecoverTOTPBackupKey',
		request,
		metadata || {},
		methodDescriptor_MFAService_RecoverTOTPBackupKey,
		callback
	)
}

/**
 * @param {!proto.mfa.RecoverTOTPBackupKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mfa.RecoverTOTPBackupKeyResponse>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.recoverTOTPBackupKey = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/RecoverTOTPBackupKey',
		request,
		metadata || {},
		methodDescriptor_MFAService_RecoverTOTPBackupKey
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_MFAService_DeleteTOTPAdmin = new grpc.web.MethodDescriptor(
	'/mfa.MFAService/DeleteTOTPAdmin',
	grpc.web.MethodType.UNARY,
	google_protobuf_empty_pb.Empty,
	google_protobuf_empty_pb.Empty,
	/**
	 * @param {!proto.google.protobuf.Empty} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	google_protobuf_empty_pb.Empty.deserializeBinary
)

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mfa.MFAServiceClient.prototype.deleteTOTPAdmin = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/mfa.MFAService/DeleteTOTPAdmin',
		request,
		metadata || {},
		methodDescriptor_MFAService_DeleteTOTPAdmin,
		callback
	)
}

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mfa.MFAServicePromiseClient.prototype.deleteTOTPAdmin = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/mfa.MFAService/DeleteTOTPAdmin',
		request,
		metadata || {},
		methodDescriptor_MFAService_DeleteTOTPAdmin
	)
}

module.exports = proto.mfa
