import { getApiKeys, createApiKey, addIP, deleteApiKey, getIPList } from '../../services/manager'

export const Types = {
	LOADING: 'apiKeys/LOADING',
	SET_KEYS: 'apiKeys/SET_KEYS',
	SET_IPS: 'apiKeys/SET_IPS',
	SET_CREATED: 'apiKeys/SET_CREATED',
	FAILURE_KEYS: 'apiKeys/FAILURE_KEYS',
	FAILURE_IPS: 'apiKeys/FAILURE_IPS',
	FAILURE_CREATED: 'apiKeys/FAILURE_CREATED',
	FAILURE_DELETED: 'apiKeys/FAILURE_DELETED',
	FAILURE_CLEARED: 'apiKeys/FAILURE_CLEARED',
}

const initialState = {
	loading: false,
	keys: [],
	ips: [],
	created: null,
	failed_key: false,
	failed_ip: false,
	failed_created: false,
	failed_deleted: false,
	failed_cleared: false,
}

export const fetchKeys = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const keys = await getApiKeys()

			if (typeof keys === 'string') {
				throw new Error(keys)
			}

			dispatch({
				type: Types.SET_KEYS,
				payload: keys,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_KEYS })
		}
	}
}

export const createKey = (data, factorTOTP) => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING,
			payload: { loading: true },
		})

		const { Key, error } = await createApiKey(data, factorTOTP)
		if (error) {
			dispatch({ type: Types.FAILURE_CREATED })
		}
		if (Key) {
			dispatch({
				type: Types.SET_CREATED,
				payload: Key?.Key,
			})

			if (Key && data?.ipList?.length) {
				for (const ip of data?.ipList) {
					await addIP(data?.Label, ip, factorTOTP)
				}
			}

			dispatch(fetchKeys())
		}
	}
}

export const deleteKey = (label, factorTOTP) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			await deleteApiKey(label, factorTOTP)

			dispatch(fetchKeys())
		} catch (error) {
			dispatch({ type: Types.FAILURE_DELETED })
		}
	}
}

export const clearCreated = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.SET_CREATED,
				payload: null,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_CLEARED })
		}
	}
}

export const fetchIps = label => {
	return async dispatch => {
		const ips = await getIPList(label)

		try {
			dispatch({
				type: Types.SET_IPS,
				payload: ips,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_IPS })
		}
	}
}

export const reducerObject = {
	'apiKeys/LOADING': (state, action) => {
		return { ...state, ...action.payload }
	},
	'apiKeys/SET_KEYS': (state, action) => {
		return { ...state, keys: action.payload, loading: false }
	},
	'apiKeys/SET_IPS': (state, action) => {
		return { ...state, ips: [...state.ips, ...action.payload], loading: false }
	},
	'apiKeys/SET_CREATED': (state, action) => {
		return { ...state, created: action.payload, loading: false }
	},
	'apiKeys/FAILURE_KEYS': state => {
		return { ...state, failed_key: true, loading: false }
	},
	'apiKeys/FAILURE_IPS': state => {
		return { ...state, failed_ip: true, loading: false }
	},
	'apiKeys/FAILURE_CREATED': state => {
		return { ...state, failed_created: true, loading: false }
	},
	'apiKeys/FAILURE_DELETED': state => {
		return { ...state, failed_deleted: true, loading: false }
	},
	'apiKeys/FAILURE_CLEARED': state => {
		return { ...state, failed_cleared: true, loading: false }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
