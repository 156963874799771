export const Types = {
    SET_ROUTE: 'route/SET_ROUTE',
}

const initialState = {
    route: '/'
}

export const setRoute = path => {
    return dispatch => {
        dispatch({
            type: Types.SET_ROUTE,
            payload: path,
        })
    }
}

export const reducerObject = {
    'route/SET_ROUTE': (state, action) => {
        return { ...state, route: action.payload }
    }
}

export default function reducer(state = initialState, action) {
    return reducerObject[action.type]?.(state, action) || state
}
