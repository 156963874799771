import isEmpty from 'lodash/isEmpty'

import EventsClient from '../../services/grpcweb/EventsClient'
import { fetchKeyPairs, fetchKeyPairsCard, getConvertKeypairs } from '../../services/manager'
import { isProd, sortArray } from '../../utils'
import { CURRENCIES } from '../../utils/constants'
import { clearFills } from './fills'
import { clearStreamOrders, clearOrders } from './orders'

export const Types = {
	SET_SELECTED_KEYPAIR: 'keypairs/SET_SELECTED_KEYPAIR',
	FECTH_KEYPAIRS: 'keypairs/FECTH_KEYPAIRS',
	FECTH_CONVERT_KEYPAIRS: 'keypairs/FECTH_CONVERT_KEYPAIRS',
	SET_KEYPAIRS_CARD: 'keypairs/SET_KEYPAIRS_CARD',
	SET_KEYPAIR_LIST: 'keypairs/SET_KEYPAIR_LIST',
	SET_KEYPAIR_TABS: 'keypairs/SET_KEYPAIR_TABS',
	SET_PAIRS_TABS: 'keypairs/SET_PAIRS_TABS',
	SET_KEYPAIR_STREAM: 'keypairs/SET_KEYPAIR_STREAM',
	FAILURE: 'keypairs/FAILURE',
	FAILURE_CONVERT_KEYPAIRS: 'keypairs/FAILURE_CONVERT_KEYPAIRS',
}

const KEYPAIRS_LIMIT_DEFAULT = 10
const TICKERS_LIMIT_DEFAULT = 24
const TIME_RANGE_DEFAULT = 'HOUR_1'

const initialState = {
	keypairsList: [],
	convertKeypairs: [],
	keypairsCard: [],
	keypairTabs: [],
	pairTabs: [],
	selectedKeypair: {},
	keypairStream: null,
	failed: false,
	failureConvertKeypairs: null,
}

export const fetchKeyPairsReducer = (Currency, KeypairSelected) => {
	return async (dispatch, getStory) => {
		const { keypairs } = getStory()
		const array = []
		const reduced = []

		const { Keypairs: newKeypairs, error } = await fetchKeyPairs(Currency)

		let sortedKeypairs = newKeypairs

		if (error) {
			return dispatch({ type: Types.FAILURE })
		}

		if (newKeypairs) {
			const inProd = isProd()

			if (inProd) sortedKeypairs = newKeypairs?.filter(keypair => !keypair?.IsBeta)

			const selectedKeypair = sortedKeypairs?.find(keypair =>
				KeypairSelected ? keypair.Name === KeypairSelected : keypair.Name === 'KLV-USDT'
			)
			const pairsTabs = localStorage.getItem('pairsTabs')
			if (pairsTabs) {
				const oldPairs = JSON.parse(pairsTabs)
				const updatedPairsTabs = oldPairs?.map(pair => {
					const updatedPair = sortedKeypairs?.find(p => p?.ID === pair?.ID)
					if (updatedPair) {
						pair = updatedPair
					}
					return pair
				})
				dispatch({
					type: Types.SET_PAIRS_TABS,
					payload: updatedPairsTabs,
				})
			}
			if (
				keypairs?.selectedKeypair?.Name !== KeypairSelected ||
				((keypairs?.selectedKeypair?.LastPrice || keypairs?.selectedKeypair?.Base?.Price) !==
					selectedKeypair?.Base?.Price &&
					keypairs?.selectedKeypair?.Name === selectedKeypair?.Name)
			) {
				dispatch(updateSelectedKeypair(selectedKeypair))
			}
			dispatch({
				type: Types.SET_KEYPAIR_LIST,
				payload: sortArray(sortedKeypairs, 'Name'),
			})
			if (isEmpty(keypairs?.keypairStream)) {
				dispatch(setStreamKeypairs())
			}
			sortedKeypairs.forEach(keypair => {
				array.push(
					{ tabName: keypair?.Base?.Abbr, ID: keypair?.ID },
					{ tabName: keypair?.Quote?.Abbr, ID: keypair?.ID }
				)
			})
			array.forEach(k => {
				const duplicated =
					reduced.findIndex(item => {
						return k?.tabName === item?.tabName
					}) > -1
				if (!duplicated) {
					reduced.push(k)
				}
			})
			dispatch(setKeypairsTabs(sortArray(reduced, 'tabName')))
		}
	}
}

export const fetchKeyPairsCardReducer = (Currency, keypairsLimit = 10) => {
	return async dispatch => {
		const inProd = isProd()
		let filteredKeypairs = []

		try {
			const keypairs = await fetchKeyPairsCard(
				keypairsLimit || KEYPAIRS_LIMIT_DEFAULT,
				TICKERS_LIMIT_DEFAULT,
				TIME_RANGE_DEFAULT,
				true,
				true,
				Currency
			)

			if (keypairs?.error) {
				return dispatch({ type: Types.FAILURE })
			}

			if (inProd) {
				filteredKeypairs = keypairs?.filter(
					keypair => !keypair?.KeypairData?.IsBeta && keypair?.KeypairData?.Active
				)

				return dispatch({
					type: Types.SET_KEYPAIRS_CARD,
					payload: filteredKeypairs,
				})
			}

			dispatch({
				type: Types.SET_KEYPAIRS_CARD,
				payload: keypairs,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const fetchConvertKeypairs = ({ baseTokenId, baseChainId, currency, isAuth }) => {
	return async dispatch => {
		try {
			const { convertKeypairs, error } = await getConvertKeypairs({ baseTokenId, baseChainId, currency, isAuth })
			if (error) {
				return dispatch({ type: Types.FAILURE_CONVERT_KEYPAIRS, payload: 'error' })
			}

			dispatch({
				type: Types.FECTH_CONVERT_KEYPAIRS,
				payload: sortArray(convertKeypairs, 'name'),
			})
			dispatch({
				type: Types.FAILURE_CONVERT_KEYPAIRS,
				payload: null,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_CONVERT_KEYPAIRS, payload: error?.message })
		}
	}
}

export const setSelectedKeypair = keypair => {
	return async (dispatch, getStory) => {
		const { orders, keypairs } = getStory()
		if (keypairs?.selectedKeypair?.ID === keypair?.ID) return

		// clear orderbook, orders and trade lists
		dispatch(clearFills())
		dispatch(clearOrders())

		if (!isEmpty(orders.orderStream)) {
			orders?.orderStream?.cancelStream(() => {
				dispatch(clearStreamOrders())
			})
		}
		dispatch({
			type: Types.SET_SELECTED_KEYPAIR,
			payload: keypair,
		})
	}
}

export const updateSelectedKeypair = keypair => {
	return async dispatch => {
		dispatch({
			type: Types.SET_SELECTED_KEYPAIR,
			payload: keypair,
		})
	}
}

export const setKeypairsTabs = keypairs => {
	return async dispatch => {
		dispatch({
			type: Types.SET_KEYPAIR_TABS,
			payload: keypairs,
		})
	}
}

export const setPairsTabs = pairs => {
	return async dispatch => {
		localStorage.setItem('pairsTabs', JSON.stringify(pairs))
		dispatch({
			type: Types.SET_PAIRS_TABS,
			payload: pairs,
		})
	}
}

export const setStreamKeypairs = () => {
	return async dispatch => {
		try {
			const streamKeypairs = new EventsClient()
			dispatch({
				type: Types.SET_KEYPAIR_STREAM,
				payload: streamKeypairs,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearStreamKeypair = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_KEYPAIR_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const updateKeypairs = (event, curr) => {
	return (dispatch, getStore) => {
		try {
			const { keypairs, currency } = getStore()
			const { keypairsList, pairTabs, selectedKeypair, keypairsCard } = keypairs

			if (CURRENCIES[currency?.currency?.name] === curr) {
				// upadate keypairsList data
				const index = keypairsList.findIndex(i => i?.ID === event.ID)
				if (index >= 0) {
					const newList = [...keypairsList]
					const oldStats = keypairsList[index]?.Stats

					const newStats = {
						Open: oldStats?.Open,
						Close: event?.Price,
						High: event?.Price >= oldStats?.High ? event?.Price : oldStats?.High,
						Low: event?.Price <= oldStats?.Low ? event?.Price : oldStats?.Low,
						Volume: event?.Volume || oldStats?.Volume,
						VariationPercentage: event?.VariationPercentage || oldStats?.VariationPercentage || 0,
					}

					// update the keypair value
					newList[index] = {
						...keypairsList[index],
						Stats: newStats,
						Base: {
							...keypairsList[index]?.Base,
							Price: event?.PriceCurrency,
						},
					}

					// update keypairs prices for the same Base

					const baseName = event?.Name?.split('-')[0]

					const updatedList = newList.map(i => ({
						...i,
						Base: {
							...i.Base,
							Price: i?.Base?.Abbr === baseName ? event?.PriceCurrency : i?.Base?.Price,
						},
					}))

					dispatch({
						type: Types.SET_KEYPAIR_LIST,
						payload: updatedList,
					})

					// update pairTabs list data

					const indexTab = pairTabs.findIndex(i => i?.ID === event.ID)

					if (indexTab >= 0) {
						const newTabs = [...pairTabs]

						newTabs[indexTab] = {
							...pairTabs[indexTab],
							Stats: newStats,
							Base: {
								...pairTabs[indexTab]?.Base,
								Price: event?.PriceCurrency,
							},
						}

						const updatedTabs = newTabs.map(i => ({
							...i,
							Base: {
								...i.Base,
								Price: i?.Base?.Abbr === baseName ? event?.PriceCurrency : i?.Base?.Price,
							},
						}))

						dispatch({
							type: Types.SET_PAIRS_TABS,
							payload: updatedTabs,
						})
					}

					// update selectedKeypair data

					if (selectedKeypair?.ID === event?.ID) {
						const newSelectedKeypair = {
							...selectedKeypair,
							Base: {
								...selectedKeypair?.Base,
								Price: event?.PriceCurrency,
							},
							Stats: newStats,
						}
						dispatch({
							type: Types.SET_SELECTED_KEYPAIR,
							payload: newSelectedKeypair,
						})
					}

					// update keypairs cards data

					const indexCard = keypairsCard.findIndex(i => i?.KeypairData?.ID === event.ID)

					if (indexCard >= 0) {
						const newCards = [...keypairsCard]

						newCards[indexCard] = {
							...keypairsCard[indexCard],
							KeypairData: {
								...keypairsCard[indexCard]?.KeypairData,
								Stats: newStats,
								Base: {
									...keypairsCard[indexCard]?.KeypairData?.Base,
									Price: event?.PriceCurrency,
								},
							},
						}

						const updatedCards = newCards.map(i => ({
							...i,
							KeypairData: {
								...i?.KeypairData,
								Base: {
									...i?.KeypairData?.Base,
									Price:
										i?.KeypairData?.Base?.Abbr === baseName
											? event?.PriceCurrency
											: i?.KeypairData?.Base?.Price,
								},
							},
						}))

						dispatch({
							type: Types.SET_KEYPAIRS_CARD,
							payload: updatedCards,
						})
					}
				}
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'keypairs/SET_SELECTED_KEYPAIR': (state, action) => {
		return { ...state, selectedKeypair: action.payload, failed: false }
	},
	'keypairs/FECTH_KEYPAIRS': state => {
		return { ...state, failed: false }
	},
	'keypairs/FECTH_CONVERT_KEYPAIRS': (state, action) => {
		return { ...state, convertKeypairs: action.payload, failed: false }
	},
	'keypairs/SET_KEYPAIRS_CARD': (state, action) => {
		return { ...state, keypairsCard: action.payload, failed: false }
	},
	'keypairs/SET_KEYPAIR_LIST': (state, action) => {
		return { ...state, keypairsList: action.payload, failed: false }
	},
	'keypairs/SET_KEYPAIR_TABS': (state, action) => {
		return { ...state, keypairTabs: action.payload, failed: false }
	},
	'keypairs/SET_PAIRS_TABS': (state, action) => {
		return { ...state, pairTabs: action.payload, failed: false }
	},
	'keypairs/SET_KEYPAIR_STREAM': (state, action) => {
		return { ...state, keypairStream: action.payload }
	},
	'keypairs/FAILURE': state => {
		return { ...state, failed: true }
	},
	'keypairs/FAILURE_CONVERT_KEYPAIRS': (state, action) => {
		return { ...state, failureConvertKeypairs: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
