import { getNetworks } from '../../services/manager'

export const Types = {
	FETCH_NETWORKS: 'networks/FETCH_NETWORKS',
	FAILURE: 'networks/FAILURE',
}

const initialState = {
	networksList: [],
	failed: false,
}

export const fetchNetworks = () => {
	return async dispatch => {
		const { Networks, error } = await getNetworks()
		error
			? dispatch({ type: Types.FAILURE })
			: dispatch({
					type: Types.FETCH_NETWORKS,
					payload: Networks,
			  })
	}
}

export const reducerObject = {
	'networks/FETCH_NETWORKS': (state, action) => {
		return { ...state, networksList: action.payload, failed: false }
	},
	'networks/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
