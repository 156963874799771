// source: src/nft.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf')
var goog = jspb
var global = function () {
	if (this) {
		return this
	}
	if (typeof window !== 'undefined') {
		return window
	}
	if (typeof global !== 'undefined') {
		return global
	}
	if (typeof self !== 'undefined') {
		return self
	}
	return Function('return this')()
}.call(null)

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
goog.object.extend(proto, google_protobuf_timestamp_pb)
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')
goog.object.extend(proto, google_protobuf_struct_pb)
var google_api_annotations_pb = require('./google/api/annotations_pb.js')
goog.object.extend(proto, google_api_annotations_pb)
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
goog.object.extend(proto, google_protobuf_empty_pb)
var validate_validate_pb = require('./validate/validate_pb.js')
goog.object.extend(proto, validate_validate_pb)
goog.exportSymbol('proto.nft.BuyNftRequest', null, global)
goog.exportSymbol('proto.nft.BuyNftResponse', null, global)
goog.exportSymbol('proto.nft.Collection', null, global)
goog.exportSymbol('proto.nft.GetCollectionRequest', null, global)
goog.exportSymbol('proto.nft.GetCollectionTokensResponse', null, global)
goog.exportSymbol('proto.nft.GetId', null, global)
goog.exportSymbol('proto.nft.GetNftOrderHistoryRequest', null, global)
goog.exportSymbol('proto.nft.GetNftOrderHistoryResponse', null, global)
goog.exportSymbol('proto.nft.GetNftRankingResponse', null, global)
goog.exportSymbol('proto.nft.GetNftRequest', null, global)
goog.exportSymbol('proto.nft.GetNftsByCollectionNameRequest', null, global)
goog.exportSymbol('proto.nft.GetNftsByCollectionNameResponse', null, global)
goog.exportSymbol('proto.nft.GetUserOrderHistoryRequest', null, global)
goog.exportSymbol('proto.nft.GetUserOrderHistoryResponse', null, global)
goog.exportSymbol('proto.nft.HealthResponse', null, global)
goog.exportSymbol('proto.nft.ListCollectionsRequest', null, global)
goog.exportSymbol('proto.nft.ListCollectionsResponse', null, global)
goog.exportSymbol('proto.nft.ListNftsRequest', null, global)
goog.exportSymbol('proto.nft.ListNftsResponse', null, global)
goog.exportSymbol('proto.nft.ListOrderRequest', null, global)
goog.exportSymbol('proto.nft.ListOrderResponse', null, global)
goog.exportSymbol('proto.nft.Nft', null, global)
goog.exportSymbol('proto.nft.NftCancelRequest', null, global)
goog.exportSymbol('proto.nft.NftCancelResponse', null, global)
goog.exportSymbol('proto.nft.NftInfo', null, global)
goog.exportSymbol('proto.nft.NftMetadataFilter', null, global)
goog.exportSymbol('proto.nft.NftOrderRequest', null, global)
goog.exportSymbol('proto.nft.NftOrderResponse', null, global)
goog.exportSymbol('proto.nft.NftRanking', null, global)
goog.exportSymbol('proto.nft.OKTA_CLIENT', null, global)
goog.exportSymbol('proto.nft.ORDER_FILTER', null, global)
goog.exportSymbol('proto.nft.ORDER_HISTORY_TYPE', null, global)
goog.exportSymbol('proto.nft.ORDER_STATUS', null, global)
goog.exportSymbol('proto.nft.Order', null, global)
goog.exportSymbol('proto.nft.OrderHistory', null, global)
goog.exportSymbol('proto.nft.PaginationParams', null, global)
goog.exportSymbol('proto.nft.PaginationResponse', null, global)
goog.exportSymbol('proto.nft.SocialMedia', null, global)
goog.exportSymbol('proto.nft.StatesStatus', null, global)
goog.exportSymbol('proto.nft.UserOrderHistory', null, global)
goog.exportSymbol('proto.nft.WithdrawNftRequest', null, global)
goog.exportSymbol('proto.nft.WithdrawNftResponse', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftMetadataFilter = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftMetadataFilter, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftMetadataFilter.displayName = 'proto.nft.NftMetadataFilter'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.HealthResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.HealthResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.HealthResponse.displayName = 'proto.nft.HealthResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.GetNftRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftRequest.displayName = 'proto.nft.GetNftRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListNftsRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.ListNftsRequest.repeatedFields_, null)
}
goog.inherits(proto.nft.ListNftsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListNftsRequest.displayName = 'proto.nft.ListNftsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListNftsResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.ListNftsResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.ListNftsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListNftsResponse.displayName = 'proto.nft.ListNftsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.StatesStatus = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.StatesStatus, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.StatesStatus.displayName = 'proto.nft.StatesStatus'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.Nft = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.Nft, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.Nft.displayName = 'proto.nft.Nft'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.PaginationParams = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.PaginationParams, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.PaginationParams.displayName = 'proto.nft.PaginationParams'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.PaginationResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.PaginationResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.PaginationResponse.displayName = 'proto.nft.PaginationResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetId = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.GetId, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetId.displayName = 'proto.nft.GetId'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetCollectionRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.GetCollectionRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetCollectionRequest.displayName = 'proto.nft.GetCollectionRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListCollectionsRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.ListCollectionsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListCollectionsRequest.displayName = 'proto.nft.ListCollectionsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListCollectionsResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.ListCollectionsResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.ListCollectionsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListCollectionsResponse.displayName = 'proto.nft.ListCollectionsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.SocialMedia = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.SocialMedia, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.SocialMedia.displayName = 'proto.nft.SocialMedia'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.Collection = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.Collection.repeatedFields_, null)
}
goog.inherits(proto.nft.Collection, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.Collection.displayName = 'proto.nft.Collection'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.Order = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.Order, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.Order.displayName = 'proto.nft.Order'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftInfo = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftInfo.displayName = 'proto.nft.NftInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.OrderHistory = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.OrderHistory, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.OrderHistory.displayName = 'proto.nft.OrderHistory'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftOrderRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftOrderRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftOrderRequest.displayName = 'proto.nft.NftOrderRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftOrderResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftOrderResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftOrderResponse.displayName = 'proto.nft.NftOrderResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftCancelRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftCancelRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftCancelRequest.displayName = 'proto.nft.NftCancelRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftCancelResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftCancelResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftCancelResponse.displayName = 'proto.nft.NftCancelResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListOrderRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.ListOrderRequest.repeatedFields_, null)
}
goog.inherits(proto.nft.ListOrderRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListOrderRequest.displayName = 'proto.nft.ListOrderRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.ListOrderResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.ListOrderResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.ListOrderResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.ListOrderResponse.displayName = 'proto.nft.ListOrderResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.BuyNftRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.BuyNftRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.BuyNftRequest.displayName = 'proto.nft.BuyNftRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.BuyNftResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.BuyNftResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.BuyNftResponse.displayName = 'proto.nft.BuyNftResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftOrderHistoryRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.GetNftOrderHistoryRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftOrderHistoryRequest.displayName = 'proto.nft.GetNftOrderHistoryRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftOrderHistoryResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetNftOrderHistoryResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.GetNftOrderHistoryResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftOrderHistoryResponse.displayName = 'proto.nft.GetNftOrderHistoryResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftsByCollectionNameRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetNftsByCollectionNameRequest.repeatedFields_, null)
}
goog.inherits(proto.nft.GetNftsByCollectionNameRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftsByCollectionNameRequest.displayName = 'proto.nft.GetNftsByCollectionNameRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftsByCollectionNameResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetNftsByCollectionNameResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.GetNftsByCollectionNameResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftsByCollectionNameResponse.displayName = 'proto.nft.GetNftsByCollectionNameResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.NftRanking = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.NftRanking, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.NftRanking.displayName = 'proto.nft.NftRanking'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetNftRankingResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetNftRankingResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.GetNftRankingResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetNftRankingResponse.displayName = 'proto.nft.GetNftRankingResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.WithdrawNftRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.WithdrawNftRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.WithdrawNftRequest.displayName = 'proto.nft.WithdrawNftRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.WithdrawNftResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.WithdrawNftResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.WithdrawNftResponse.displayName = 'proto.nft.WithdrawNftResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetUserOrderHistoryRequest = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.GetUserOrderHistoryRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetUserOrderHistoryRequest.displayName = 'proto.nft.GetUserOrderHistoryRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.UserOrderHistory = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.nft.UserOrderHistory, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.UserOrderHistory.displayName = 'proto.nft.UserOrderHistory'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetUserOrderHistoryResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetUserOrderHistoryResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.GetUserOrderHistoryResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetUserOrderHistoryResponse.displayName = 'proto.nft.GetUserOrderHistoryResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nft.GetCollectionTokensResponse = function (opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.nft.GetCollectionTokensResponse.repeatedFields_, null)
}
goog.inherits(proto.nft.GetCollectionTokensResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.nft.GetCollectionTokensResponse.displayName = 'proto.nft.GetCollectionTokensResponse'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftMetadataFilter.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftMetadataFilter.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftMetadataFilter} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftMetadataFilter.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				field: jspb.Message.getFieldWithDefault(msg, 1, ''),
				value: (f = msg.getValue()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftMetadataFilter}
 */
proto.nft.NftMetadataFilter.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftMetadataFilter()
	return proto.nft.NftMetadataFilter.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftMetadataFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftMetadataFilter}
 */
proto.nft.NftMetadataFilter.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setField(value)
				break
			case 2:
				var value = new google_protobuf_struct_pb.Value()
				reader.readMessage(value, google_protobuf_struct_pb.Value.deserializeBinaryFromReader)
				msg.setValue(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftMetadataFilter.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftMetadataFilter.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftMetadataFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftMetadataFilter.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getField()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getValue()
	if (f != null) {
		writer.writeMessage(2, f, google_protobuf_struct_pb.Value.serializeBinaryToWriter)
	}
}

/**
 * optional string field = 1;
 * @return {string}
 */
proto.nft.NftMetadataFilter.prototype.getField = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftMetadataFilter} returns this
 */
proto.nft.NftMetadataFilter.prototype.setField = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional google.protobuf.Value value = 2;
 * @return {?proto.google.protobuf.Value}
 */
proto.nft.NftMetadataFilter.prototype.getValue = function () {
	return /** @type{?proto.google.protobuf.Value} */ (
		jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 2)
	)
}

/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.nft.NftMetadataFilter} returns this
 */
proto.nft.NftMetadataFilter.prototype.setValue = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.NftMetadataFilter} returns this
 */
proto.nft.NftMetadataFilter.prototype.clearValue = function () {
	return this.setValue(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.NftMetadataFilter.prototype.hasValue = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.HealthResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.HealthResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.HealthResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.HealthResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				service: jspb.Message.getFieldWithDefault(msg, 1, ''),
				status: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.HealthResponse}
 */
proto.nft.HealthResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.HealthResponse()
	return proto.nft.HealthResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.HealthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.HealthResponse}
 */
proto.nft.HealthResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setService(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setStatus(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.HealthResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.HealthResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.HealthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.HealthResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getService()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getStatus()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string service = 1;
 * @return {string}
 */
proto.nft.HealthResponse.prototype.getService = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.HealthResponse} returns this
 */
proto.nft.HealthResponse.prototype.setService = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string status = 2;
 * @return {string}
 */
proto.nft.HealthResponse.prototype.getStatus = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.HealthResponse} returns this
 */
proto.nft.HealthResponse.prototype.setStatus = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				hash: jspb.Message.getFieldWithDefault(msg, 1, ''),
				collectionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftRequest}
 */
proto.nft.GetNftRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftRequest()
	return proto.nft.GetNftRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftRequest}
 */
proto.nft.GetNftRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setHash(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getHash()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
}

/**
 * optional string hash = 1;
 * @return {string}
 */
proto.nft.GetNftRequest.prototype.getHash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.GetNftRequest} returns this
 */
proto.nft.GetNftRequest.prototype.setHash = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 collectionId = 2;
 * @return {number}
 */
proto.nft.GetNftRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.GetNftRequest} returns this
 */
proto.nft.GetNftRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.ListNftsRequest.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListNftsRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListNftsRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListNftsRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListNftsRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
				filtersList: jspb.Message.toObjectList(
					msg.getFiltersList(),
					proto.nft.NftMetadataFilter.toObject,
					includeInstance
				),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListNftsRequest}
 */
proto.nft.ListNftsRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListNftsRequest()
	return proto.nft.ListNftsRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListNftsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListNftsRequest}
 */
proto.nft.ListNftsRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 2:
				var value = new proto.nft.NftMetadataFilter()
				reader.readMessage(value, proto.nft.NftMetadataFilter.deserializeBinaryFromReader)
				msg.addFilters(value)
				break
			case 3:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListNftsRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListNftsRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListNftsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListNftsRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getFiltersList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(2, f, proto.nft.NftMetadataFilter.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(3, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
}

/**
 * optional int32 collectionId = 1;
 * @return {number}
 */
proto.nft.ListNftsRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.ListNftsRequest} returns this
 */
proto.nft.ListNftsRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * repeated NftMetadataFilter filters = 2;
 * @return {!Array<!proto.nft.NftMetadataFilter>}
 */
proto.nft.ListNftsRequest.prototype.getFiltersList = function () {
	return /** @type{!Array<!proto.nft.NftMetadataFilter>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.NftMetadataFilter, 2)
	)
}

/**
 * @param {!Array<!proto.nft.NftMetadataFilter>} value
 * @return {!proto.nft.ListNftsRequest} returns this
 */
proto.nft.ListNftsRequest.prototype.setFiltersList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.nft.NftMetadataFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.NftMetadataFilter}
 */
proto.nft.ListNftsRequest.prototype.addFilters = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.nft.NftMetadataFilter, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListNftsRequest} returns this
 */
proto.nft.ListNftsRequest.prototype.clearFiltersList = function () {
	return this.setFiltersList([])
}

/**
 * optional PaginationParams paginationInfos = 3;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.ListNftsRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 3))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.ListNftsRequest} returns this
 */
proto.nft.ListNftsRequest.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListNftsRequest} returns this
 */
proto.nft.ListNftsRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListNftsRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 3) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.ListNftsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListNftsResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListNftsResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListNftsResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListNftsResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				nftsList: jspb.Message.toObjectList(msg.getNftsList(), proto.nft.Nft.toObject, includeInstance),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListNftsResponse}
 */
proto.nft.ListNftsResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListNftsResponse()
	return proto.nft.ListNftsResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListNftsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListNftsResponse}
 */
proto.nft.ListNftsResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.Nft()
				reader.readMessage(value, proto.nft.Nft.deserializeBinaryFromReader)
				msg.addNfts(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListNftsResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListNftsResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListNftsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListNftsResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getNftsList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.Nft.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated Nft nfts = 1;
 * @return {!Array<!proto.nft.Nft>}
 */
proto.nft.ListNftsResponse.prototype.getNftsList = function () {
	return /** @type{!Array<!proto.nft.Nft>} */ (jspb.Message.getRepeatedWrapperField(this, proto.nft.Nft, 1))
}

/**
 * @param {!Array<!proto.nft.Nft>} value
 * @return {!proto.nft.ListNftsResponse} returns this
 */
proto.nft.ListNftsResponse.prototype.setNftsList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.Nft=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.Nft}
 */
proto.nft.ListNftsResponse.prototype.addNfts = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.Nft, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListNftsResponse} returns this
 */
proto.nft.ListNftsResponse.prototype.clearNftsList = function () {
	return this.setNftsList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.ListNftsResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.ListNftsResponse} returns this
 */
proto.nft.ListNftsResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListNftsResponse} returns this
 */
proto.nft.ListNftsResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListNftsResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.StatesStatus.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.StatesStatus.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.StatesStatus} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.StatesStatus.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				inorder: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
				inwithdraw: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.StatesStatus}
 */
proto.nft.StatesStatus.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.StatesStatus()
	return proto.nft.StatesStatus.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.StatesStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.StatesStatus}
 */
proto.nft.StatesStatus.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setInorder(value)
				break
			case 2:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setInwithdraw(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.StatesStatus.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.StatesStatus.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.StatesStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.StatesStatus.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getInorder()
	if (f) {
		writer.writeBool(1, f)
	}
	f = message.getInwithdraw()
	if (f) {
		writer.writeBool(2, f)
	}
}

/**
 * optional bool inOrder = 1;
 * @return {boolean}
 */
proto.nft.StatesStatus.prototype.getInorder = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.StatesStatus} returns this
 */
proto.nft.StatesStatus.prototype.setInorder = function (value) {
	return jspb.Message.setProto3BooleanField(this, 1, value)
}

/**
 * optional bool inWithdraw = 2;
 * @return {boolean}
 */
proto.nft.StatesStatus.prototype.getInwithdraw = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.StatesStatus} returns this
 */
proto.nft.StatesStatus.prototype.setInwithdraw = function (value) {
	return jspb.Message.setProto3BooleanField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.Nft.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.Nft.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.Nft} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.Nft.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				id: jspb.Message.getFieldWithDefault(msg, 1, 0),
				nftname: jspb.Message.getFieldWithDefault(msg, 2, ''),
				collectionid: jspb.Message.getFieldWithDefault(msg, 3, 0),
				belongsto: jspb.Message.getFieldWithDefault(msg, 4, ''),
				tokenhash: jspb.Message.getFieldWithDefault(msg, 5, ''),
				createdat:
					(f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				updatedat:
					(f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				nftimage: jspb.Message.getFieldWithDefault(msg, 8, ''),
				metadata: jspb.Message.getFieldWithDefault(msg, 9, ''),
				states: (f = msg.getStates()) && proto.nft.StatesStatus.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.Nft}
 */
proto.nft.Nft.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.Nft()
	return proto.nft.Nft.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.Nft} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.Nft}
 */
proto.nft.Nft.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setId(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setNftname(value)
				break
			case 3:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 4:
				var value = /** @type {string} */ (reader.readString())
				msg.setBelongsto(value)
				break
			case 5:
				var value = /** @type {string} */ (reader.readString())
				msg.setTokenhash(value)
				break
			case 6:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setCreatedat(value)
				break
			case 7:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setUpdatedat(value)
				break
			case 8:
				var value = /** @type {string} */ (reader.readString())
				msg.setNftimage(value)
				break
			case 9:
				var value = /** @type {string} */ (reader.readString())
				msg.setMetadata(value)
				break
			case 10:
				var value = new proto.nft.StatesStatus()
				reader.readMessage(value, proto.nft.StatesStatus.deserializeBinaryFromReader)
				msg.setStates(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.Nft.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.Nft.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.Nft} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.Nft.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getId()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getNftname()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(3, f)
	}
	f = message.getBelongsto()
	if (f.length > 0) {
		writer.writeString(4, f)
	}
	f = message.getTokenhash()
	if (f.length > 0) {
		writer.writeString(5, f)
	}
	f = message.getCreatedat()
	if (f != null) {
		writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getUpdatedat()
	if (f != null) {
		writer.writeMessage(7, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getNftimage()
	if (f.length > 0) {
		writer.writeString(8, f)
	}
	f = message.getMetadata()
	if (f.length > 0) {
		writer.writeString(9, f)
	}
	f = message.getStates()
	if (f != null) {
		writer.writeMessage(10, f, proto.nft.StatesStatus.serializeBinaryToWriter)
	}
}

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.nft.Nft.prototype.getId = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setId = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string nftName = 2;
 * @return {string}
 */
proto.nft.Nft.prototype.getNftname = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setNftname = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional int32 collectionId = 3;
 * @return {number}
 */
proto.nft.Nft.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string belongsTo = 4;
 * @return {string}
 */
proto.nft.Nft.prototype.getBelongsto = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setBelongsto = function (value) {
	return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string tokenHash = 5;
 * @return {string}
 */
proto.nft.Nft.prototype.getTokenhash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setTokenhash = function (value) {
	return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional google.protobuf.Timestamp createdAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Nft.prototype.getCreatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setCreatedat = function (value) {
	return jspb.Message.setWrapperField(this, 6, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.clearCreatedat = function () {
	return this.setCreatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Nft.prototype.hasCreatedat = function () {
	return jspb.Message.getField(this, 6) != null
}

/**
 * optional google.protobuf.Timestamp updatedAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Nft.prototype.getUpdatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setUpdatedat = function (value) {
	return jspb.Message.setWrapperField(this, 7, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.clearUpdatedat = function () {
	return this.setUpdatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Nft.prototype.hasUpdatedat = function () {
	return jspb.Message.getField(this, 7) != null
}

/**
 * optional string nftImage = 8;
 * @return {string}
 */
proto.nft.Nft.prototype.getNftimage = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setNftimage = function (value) {
	return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional string metadata = 9;
 * @return {string}
 */
proto.nft.Nft.prototype.getMetadata = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setMetadata = function (value) {
	return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * optional StatesStatus states = 10;
 * @return {?proto.nft.StatesStatus}
 */
proto.nft.Nft.prototype.getStates = function () {
	return /** @type{?proto.nft.StatesStatus} */ (jspb.Message.getWrapperField(this, proto.nft.StatesStatus, 10))
}

/**
 * @param {?proto.nft.StatesStatus|undefined} value
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.setStates = function (value) {
	return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Nft} returns this
 */
proto.nft.Nft.prototype.clearStates = function () {
	return this.setStates(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Nft.prototype.hasStates = function () {
	return jspb.Message.getField(this, 10) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.PaginationParams.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.PaginationParams.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.PaginationParams} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.PaginationParams.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				skip: jspb.Message.getFieldWithDefault(msg, 1, 0),
				limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.PaginationParams}
 */
proto.nft.PaginationParams.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.PaginationParams()
	return proto.nft.PaginationParams.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.PaginationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.PaginationParams}
 */
proto.nft.PaginationParams.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setSkip(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setLimit(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.PaginationParams.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.PaginationParams.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.PaginationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.PaginationParams.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getSkip()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getLimit()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
}

/**
 * optional int32 skip = 1;
 * @return {number}
 */
proto.nft.PaginationParams.prototype.getSkip = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.PaginationParams} returns this
 */
proto.nft.PaginationParams.prototype.setSkip = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.nft.PaginationParams.prototype.getLimit = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.PaginationParams} returns this
 */
proto.nft.PaginationParams.prototype.setLimit = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.PaginationResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.PaginationResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.PaginationResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.PaginationResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				totalpages: jspb.Message.getFieldWithDefault(msg, 1, 0),
				totalitems: jspb.Message.getFieldWithDefault(msg, 2, 0),
				hasnextpage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.PaginationResponse}
 */
proto.nft.PaginationResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.PaginationResponse()
	return proto.nft.PaginationResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.PaginationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.PaginationResponse}
 */
proto.nft.PaginationResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTotalpages(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTotalitems(value)
				break
			case 3:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setHasnextpage(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.PaginationResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.PaginationResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.PaginationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.PaginationResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getTotalpages()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getTotalitems()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getHasnextpage()
	if (f) {
		writer.writeBool(3, f)
	}
}

/**
 * optional int32 totalPages = 1;
 * @return {number}
 */
proto.nft.PaginationResponse.prototype.getTotalpages = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.PaginationResponse} returns this
 */
proto.nft.PaginationResponse.prototype.setTotalpages = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional int32 totalItems = 2;
 * @return {number}
 */
proto.nft.PaginationResponse.prototype.getTotalitems = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.PaginationResponse} returns this
 */
proto.nft.PaginationResponse.prototype.setTotalitems = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional bool hasNextPage = 3;
 * @return {boolean}
 */
proto.nft.PaginationResponse.prototype.getHasnextpage = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.PaginationResponse} returns this
 */
proto.nft.PaginationResponse.prototype.setHasnextpage = function (value) {
	return jspb.Message.setProto3BooleanField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetId.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetId.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetId} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetId.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				id: jspb.Message.getFieldWithDefault(msg, 1, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetId}
 */
proto.nft.GetId.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetId()
	return proto.nft.GetId.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetId}
 */
proto.nft.GetId.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setId(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetId.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetId.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetId.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getId()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
}

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.nft.GetId.prototype.getId = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.GetId} returns this
 */
proto.nft.GetId.prototype.setId = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetCollectionRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetCollectionRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetCollectionRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetCollectionRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				id: jspb.Message.getFieldWithDefault(msg, 1, 0),
				collectionname: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetCollectionRequest}
 */
proto.nft.GetCollectionRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetCollectionRequest()
	return proto.nft.GetCollectionRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetCollectionRequest}
 */
proto.nft.GetCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setId(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setCollectionname(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetCollectionRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetCollectionRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetCollectionRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getId()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getCollectionname()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.nft.GetCollectionRequest.prototype.getId = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.GetCollectionRequest} returns this
 */
proto.nft.GetCollectionRequest.prototype.setId = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string collectionName = 2;
 * @return {string}
 */
proto.nft.GetCollectionRequest.prototype.getCollectionname = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.GetCollectionRequest} returns this
 */
proto.nft.GetCollectionRequest.prototype.setCollectionname = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListCollectionsRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListCollectionsRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListCollectionsRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListCollectionsRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListCollectionsRequest}
 */
proto.nft.ListCollectionsRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListCollectionsRequest()
	return proto.nft.ListCollectionsRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListCollectionsRequest}
 */
proto.nft.ListCollectionsRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListCollectionsRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListCollectionsRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListCollectionsRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(1, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
}

/**
 * optional PaginationParams paginationInfos = 1;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.ListCollectionsRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 1))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.ListCollectionsRequest} returns this
 */
proto.nft.ListCollectionsRequest.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListCollectionsRequest} returns this
 */
proto.nft.ListCollectionsRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListCollectionsRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 1) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.ListCollectionsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListCollectionsResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListCollectionsResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListCollectionsResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListCollectionsResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionsList: jspb.Message.toObjectList(
					msg.getCollectionsList(),
					proto.nft.Collection.toObject,
					includeInstance
				),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListCollectionsResponse}
 */
proto.nft.ListCollectionsResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListCollectionsResponse()
	return proto.nft.ListCollectionsResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListCollectionsResponse}
 */
proto.nft.ListCollectionsResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.Collection()
				reader.readMessage(value, proto.nft.Collection.deserializeBinaryFromReader)
				msg.addCollections(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListCollectionsResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListCollectionsResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListCollectionsResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionsList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.Collection.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated Collection collections = 1;
 * @return {!Array<!proto.nft.Collection>}
 */
proto.nft.ListCollectionsResponse.prototype.getCollectionsList = function () {
	return /** @type{!Array<!proto.nft.Collection>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.Collection, 1)
	)
}

/**
 * @param {!Array<!proto.nft.Collection>} value
 * @return {!proto.nft.ListCollectionsResponse} returns this
 */
proto.nft.ListCollectionsResponse.prototype.setCollectionsList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.Collection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.Collection}
 */
proto.nft.ListCollectionsResponse.prototype.addCollections = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.Collection, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListCollectionsResponse} returns this
 */
proto.nft.ListCollectionsResponse.prototype.clearCollectionsList = function () {
	return this.setCollectionsList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.ListCollectionsResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.ListCollectionsResponse} returns this
 */
proto.nft.ListCollectionsResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListCollectionsResponse} returns this
 */
proto.nft.ListCollectionsResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListCollectionsResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.SocialMedia.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.SocialMedia.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.SocialMedia} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.SocialMedia.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				name: jspb.Message.getFieldWithDefault(msg, 1, ''),
				url: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.SocialMedia}
 */
proto.nft.SocialMedia.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.SocialMedia()
	return proto.nft.SocialMedia.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.SocialMedia} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.SocialMedia}
 */
proto.nft.SocialMedia.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setName(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setUrl(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.SocialMedia.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.SocialMedia.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.SocialMedia} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.SocialMedia.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getName()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getUrl()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string name = 1;
 * @return {string}
 */
proto.nft.SocialMedia.prototype.getName = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.SocialMedia} returns this
 */
proto.nft.SocialMedia.prototype.setName = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string url = 2;
 * @return {string}
 */
proto.nft.SocialMedia.prototype.getUrl = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.SocialMedia} returns this
 */
proto.nft.SocialMedia.prototype.setUrl = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.Collection.repeatedFields_ = [16]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.Collection.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.Collection.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.Collection} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.Collection.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				id: jspb.Message.getFieldWithDefault(msg, 1, 0),
				collectionname: jspb.Message.getFieldWithDefault(msg, 2, ''),
				creator: jspb.Message.getFieldWithDefault(msg, 3, ''),
				isfeatured: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
				ispublished: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
				isactive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
				assetid: jspb.Message.getFieldWithDefault(msg, 7, ''),
				createdat:
					(f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				updatedat:
					(f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				description: jspb.Message.getFieldWithDefault(msg, 10, ''),
				avatarimage: jspb.Message.getFieldWithDefault(msg, 11, ''),
				bannerimage: jspb.Message.getFieldWithDefault(msg, 12, ''),
				contracturl: jspb.Message.getFieldWithDefault(msg, 13, ''),
				blockchain: jspb.Message.getFieldWithDefault(msg, 14, ''),
				isverified: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
				socialmediasList: jspb.Message.toObjectList(
					msg.getSocialmediasList(),
					proto.nft.SocialMedia.toObject,
					includeInstance
				),
				viewerurl: jspb.Message.getFieldWithDefault(msg, 17, ''),
				fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.Collection}
 */
proto.nft.Collection.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.Collection()
	return proto.nft.Collection.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.Collection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.Collection}
 */
proto.nft.Collection.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setId(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setCollectionname(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setCreator(value)
				break
			case 4:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIsfeatured(value)
				break
			case 5:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIspublished(value)
				break
			case 6:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIsactive(value)
				break
			case 7:
				var value = /** @type {string} */ (reader.readString())
				msg.setAssetid(value)
				break
			case 8:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setCreatedat(value)
				break
			case 9:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setUpdatedat(value)
				break
			case 10:
				var value = /** @type {string} */ (reader.readString())
				msg.setDescription(value)
				break
			case 11:
				var value = /** @type {string} */ (reader.readString())
				msg.setAvatarimage(value)
				break
			case 12:
				var value = /** @type {string} */ (reader.readString())
				msg.setBannerimage(value)
				break
			case 13:
				var value = /** @type {string} */ (reader.readString())
				msg.setContracturl(value)
				break
			case 14:
				var value = /** @type {string} */ (reader.readString())
				msg.setBlockchain(value)
				break
			case 15:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setIsverified(value)
				break
			case 16:
				var value = new proto.nft.SocialMedia()
				reader.readMessage(value, proto.nft.SocialMedia.deserializeBinaryFromReader)
				msg.addSocialmedias(value)
				break
			case 17:
				var value = /** @type {string} */ (reader.readString())
				msg.setViewerurl(value)
				break
			case 18:
				var value = /** @type {number} */ (reader.readDouble())
				msg.setFee(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.Collection.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.Collection.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.Collection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.Collection.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getId()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getCollectionname()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getCreator()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getIsfeatured()
	if (f) {
		writer.writeBool(4, f)
	}
	f = message.getIspublished()
	if (f) {
		writer.writeBool(5, f)
	}
	f = message.getIsactive()
	if (f) {
		writer.writeBool(6, f)
	}
	f = message.getAssetid()
	if (f.length > 0) {
		writer.writeString(7, f)
	}
	f = message.getCreatedat()
	if (f != null) {
		writer.writeMessage(8, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getUpdatedat()
	if (f != null) {
		writer.writeMessage(9, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getDescription()
	if (f.length > 0) {
		writer.writeString(10, f)
	}
	f = message.getAvatarimage()
	if (f.length > 0) {
		writer.writeString(11, f)
	}
	f = message.getBannerimage()
	if (f.length > 0) {
		writer.writeString(12, f)
	}
	f = message.getContracturl()
	if (f.length > 0) {
		writer.writeString(13, f)
	}
	f = message.getBlockchain()
	if (f.length > 0) {
		writer.writeString(14, f)
	}
	f = message.getIsverified()
	if (f) {
		writer.writeBool(15, f)
	}
	f = message.getSocialmediasList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(16, f, proto.nft.SocialMedia.serializeBinaryToWriter)
	}
	f = message.getViewerurl()
	if (f.length > 0) {
		writer.writeString(17, f)
	}
	f = message.getFee()
	if (f !== 0.0) {
		writer.writeDouble(18, f)
	}
}

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.nft.Collection.prototype.getId = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setId = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string collectionName = 2;
 * @return {string}
 */
proto.nft.Collection.prototype.getCollectionname = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setCollectionname = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string creator = 3;
 * @return {string}
 */
proto.nft.Collection.prototype.getCreator = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setCreator = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional bool isFeatured = 4;
 * @return {boolean}
 */
proto.nft.Collection.prototype.getIsfeatured = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setIsfeatured = function (value) {
	return jspb.Message.setProto3BooleanField(this, 4, value)
}

/**
 * optional bool isPublished = 5;
 * @return {boolean}
 */
proto.nft.Collection.prototype.getIspublished = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setIspublished = function (value) {
	return jspb.Message.setProto3BooleanField(this, 5, value)
}

/**
 * optional bool isActive = 6;
 * @return {boolean}
 */
proto.nft.Collection.prototype.getIsactive = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setIsactive = function (value) {
	return jspb.Message.setProto3BooleanField(this, 6, value)
}

/**
 * optional string assetId = 7;
 * @return {string}
 */
proto.nft.Collection.prototype.getAssetid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setAssetid = function (value) {
	return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Collection.prototype.getCreatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setCreatedat = function (value) {
	return jspb.Message.setWrapperField(this, 8, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.clearCreatedat = function () {
	return this.setCreatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Collection.prototype.hasCreatedat = function () {
	return jspb.Message.getField(this, 8) != null
}

/**
 * optional google.protobuf.Timestamp updatedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Collection.prototype.getUpdatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setUpdatedat = function (value) {
	return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.clearUpdatedat = function () {
	return this.setUpdatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Collection.prototype.hasUpdatedat = function () {
	return jspb.Message.getField(this, 9) != null
}

/**
 * optional string description = 10;
 * @return {string}
 */
proto.nft.Collection.prototype.getDescription = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setDescription = function (value) {
	return jspb.Message.setProto3StringField(this, 10, value)
}

/**
 * optional string avatarImage = 11;
 * @return {string}
 */
proto.nft.Collection.prototype.getAvatarimage = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setAvatarimage = function (value) {
	return jspb.Message.setProto3StringField(this, 11, value)
}

/**
 * optional string bannerImage = 12;
 * @return {string}
 */
proto.nft.Collection.prototype.getBannerimage = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setBannerimage = function (value) {
	return jspb.Message.setProto3StringField(this, 12, value)
}

/**
 * optional string contractUrl = 13;
 * @return {string}
 */
proto.nft.Collection.prototype.getContracturl = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setContracturl = function (value) {
	return jspb.Message.setProto3StringField(this, 13, value)
}

/**
 * optional string blockchain = 14;
 * @return {string}
 */
proto.nft.Collection.prototype.getBlockchain = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setBlockchain = function (value) {
	return jspb.Message.setProto3StringField(this, 14, value)
}

/**
 * optional bool isVerified = 15;
 * @return {boolean}
 */
proto.nft.Collection.prototype.getIsverified = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setIsverified = function (value) {
	return jspb.Message.setProto3BooleanField(this, 15, value)
}

/**
 * repeated SocialMedia socialMedias = 16;
 * @return {!Array<!proto.nft.SocialMedia>}
 */
proto.nft.Collection.prototype.getSocialmediasList = function () {
	return /** @type{!Array<!proto.nft.SocialMedia>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.SocialMedia, 16)
	)
}

/**
 * @param {!Array<!proto.nft.SocialMedia>} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setSocialmediasList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 16, value)
}

/**
 * @param {!proto.nft.SocialMedia=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.SocialMedia}
 */
proto.nft.Collection.prototype.addSocialmedias = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.nft.SocialMedia, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.clearSocialmediasList = function () {
	return this.setSocialmediasList([])
}

/**
 * optional string viewerUrl = 17;
 * @return {string}
 */
proto.nft.Collection.prototype.getViewerurl = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setViewerurl = function (value) {
	return jspb.Message.setProto3StringField(this, 17, value)
}

/**
 * optional double fee = 18;
 * @return {number}
 */
proto.nft.Collection.prototype.getFee = function () {
	return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Collection} returns this
 */
proto.nft.Collection.prototype.setFee = function (value) {
	return jspb.Message.setProto3FloatField(this, 18, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.Order.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.Order.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.Order} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.Order.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				id: jspb.Message.getFieldWithDefault(msg, 1, ''),
				nftid: jspb.Message.getFieldWithDefault(msg, 2, 0),
				userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
				price: jspb.Message.getFieldWithDefault(msg, 4, ''),
				kind: jspb.Message.getFieldWithDefault(msg, 5, ''),
				status: jspb.Message.getFieldWithDefault(msg, 6, ''),
				tokenid: jspb.Message.getFieldWithDefault(msg, 7, 0),
				createdat:
					(f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				updatedat:
					(f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				nftdetail: (f = msg.getNftdetail()) && proto.nft.NftInfo.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.Order}
 */
proto.nft.Order.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.Order()
	return proto.nft.Order.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.Order}
 */
proto.nft.Order.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setId(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setNftid(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setUserid(value)
				break
			case 4:
				var value = /** @type {string} */ (reader.readString())
				msg.setPrice(value)
				break
			case 5:
				var value = /** @type {string} */ (reader.readString())
				msg.setKind(value)
				break
			case 6:
				var value = /** @type {string} */ (reader.readString())
				msg.setStatus(value)
				break
			case 7:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTokenid(value)
				break
			case 8:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setCreatedat(value)
				break
			case 9:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setUpdatedat(value)
				break
			case 10:
				var value = new proto.nft.NftInfo()
				reader.readMessage(value, proto.nft.NftInfo.deserializeBinaryFromReader)
				msg.setNftdetail(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.Order.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.Order.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.Order.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getId()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getNftid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getUserid()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getPrice()
	if (f.length > 0) {
		writer.writeString(4, f)
	}
	f = message.getKind()
	if (f.length > 0) {
		writer.writeString(5, f)
	}
	f = message.getStatus()
	if (f.length > 0) {
		writer.writeString(6, f)
	}
	f = message.getTokenid()
	if (f !== 0) {
		writer.writeInt32(7, f)
	}
	f = message.getCreatedat()
	if (f != null) {
		writer.writeMessage(8, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getUpdatedat()
	if (f != null) {
		writer.writeMessage(9, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getNftdetail()
	if (f != null) {
		writer.writeMessage(10, f, proto.nft.NftInfo.serializeBinaryToWriter)
	}
}

/**
 * optional string id = 1;
 * @return {string}
 */
proto.nft.Order.prototype.getId = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setId = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 nftId = 2;
 * @return {number}
 */
proto.nft.Order.prototype.getNftid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setNftid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string userId = 3;
 * @return {string}
 */
proto.nft.Order.prototype.getUserid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setUserid = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string price = 4;
 * @return {string}
 */
proto.nft.Order.prototype.getPrice = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setPrice = function (value) {
	return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string kind = 5;
 * @return {string}
 */
proto.nft.Order.prototype.getKind = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setKind = function (value) {
	return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string status = 6;
 * @return {string}
 */
proto.nft.Order.prototype.getStatus = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setStatus = function (value) {
	return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional int32 tokenId = 7;
 * @return {number}
 */
proto.nft.Order.prototype.getTokenid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setTokenid = function (value) {
	return jspb.Message.setProto3IntField(this, 7, value)
}

/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Order.prototype.getCreatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setCreatedat = function (value) {
	return jspb.Message.setWrapperField(this, 8, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.clearCreatedat = function () {
	return this.setCreatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Order.prototype.hasCreatedat = function () {
	return jspb.Message.getField(this, 8) != null
}

/**
 * optional google.protobuf.Timestamp updatedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.Order.prototype.getUpdatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setUpdatedat = function (value) {
	return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.clearUpdatedat = function () {
	return this.setUpdatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Order.prototype.hasUpdatedat = function () {
	return jspb.Message.getField(this, 9) != null
}

/**
 * optional NftInfo nftDetail = 10;
 * @return {?proto.nft.NftInfo}
 */
proto.nft.Order.prototype.getNftdetail = function () {
	return /** @type{?proto.nft.NftInfo} */ (jspb.Message.getWrapperField(this, proto.nft.NftInfo, 10))
}

/**
 * @param {?proto.nft.NftInfo|undefined} value
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.setNftdetail = function (value) {
	return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.Order} returns this
 */
proto.nft.Order.prototype.clearNftdetail = function () {
	return this.setNftdetail(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.Order.prototype.hasNftdetail = function () {
	return jspb.Message.getField(this, 10) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftInfo.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftInfo.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftInfo} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftInfo.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				nftname: jspb.Message.getFieldWithDefault(msg, 1, ''),
				nftimage: jspb.Message.getFieldWithDefault(msg, 2, ''),
				tokenhash: jspb.Message.getFieldWithDefault(msg, 3, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftInfo}
 */
proto.nft.NftInfo.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftInfo()
	return proto.nft.NftInfo.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftInfo}
 */
proto.nft.NftInfo.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setNftname(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setNftimage(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setTokenhash(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftInfo.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftInfo.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftInfo.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getNftname()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getNftimage()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getTokenhash()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
}

/**
 * optional string nftName = 1;
 * @return {string}
 */
proto.nft.NftInfo.prototype.getNftname = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftInfo} returns this
 */
proto.nft.NftInfo.prototype.setNftname = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string nftImage = 2;
 * @return {string}
 */
proto.nft.NftInfo.prototype.getNftimage = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftInfo} returns this
 */
proto.nft.NftInfo.prototype.setNftimage = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string tokenHash = 3;
 * @return {string}
 */
proto.nft.NftInfo.prototype.getTokenhash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftInfo} returns this
 */
proto.nft.NftInfo.prototype.setTokenhash = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.OrderHistory.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.OrderHistory.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.OrderHistory} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.OrderHistory.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
				buyeruserid: jspb.Message.getFieldWithDefault(msg, 2, ''),
				price: jspb.Message.getFieldWithDefault(msg, 3, ''),
				tokenid: jspb.Message.getFieldWithDefault(msg, 4, 0),
				createdat:
					(f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				updatedat:
					(f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.OrderHistory}
 */
proto.nft.OrderHistory.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.OrderHistory()
	return proto.nft.OrderHistory.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.OrderHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.OrderHistory}
 */
proto.nft.OrderHistory.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setUserid(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setBuyeruserid(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setPrice(value)
				break
			case 4:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTokenid(value)
				break
			case 5:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setCreatedat(value)
				break
			case 6:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setUpdatedat(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.OrderHistory.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.OrderHistory.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.OrderHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.OrderHistory.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getUserid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getBuyeruserid()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getPrice()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getTokenid()
	if (f !== 0) {
		writer.writeInt32(4, f)
	}
	f = message.getCreatedat()
	if (f != null) {
		writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getUpdatedat()
	if (f != null) {
		writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
}

/**
 * optional string userId = 1;
 * @return {string}
 */
proto.nft.OrderHistory.prototype.getUserid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setUserid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string buyerUserId = 2;
 * @return {string}
 */
proto.nft.OrderHistory.prototype.getBuyeruserid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setBuyeruserid = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string price = 3;
 * @return {string}
 */
proto.nft.OrderHistory.prototype.getPrice = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setPrice = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional int32 tokenID = 4;
 * @return {number}
 */
proto.nft.OrderHistory.prototype.getTokenid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setTokenid = function (value) {
	return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.OrderHistory.prototype.getCreatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setCreatedat = function (value) {
	return jspb.Message.setWrapperField(this, 5, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.clearCreatedat = function () {
	return this.setCreatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.OrderHistory.prototype.hasCreatedat = function () {
	return jspb.Message.getField(this, 5) != null
}

/**
 * optional google.protobuf.Timestamp updatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.OrderHistory.prototype.getUpdatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.setUpdatedat = function (value) {
	return jspb.Message.setWrapperField(this, 6, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.OrderHistory} returns this
 */
proto.nft.OrderHistory.prototype.clearUpdatedat = function () {
	return this.setUpdatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.OrderHistory.prototype.hasUpdatedat = function () {
	return jspb.Message.getField(this, 6) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftOrderRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftOrderRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftOrderRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftOrderRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				hash: jspb.Message.getFieldWithDefault(msg, 1, ''),
				collectionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
				price: jspb.Message.getFieldWithDefault(msg, 3, ''),
				token: jspb.Message.getFieldWithDefault(msg, 4, 0),
				kind: jspb.Message.getFieldWithDefault(msg, 5, ''),
				expireat:
					(f = msg.getExpireat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftOrderRequest}
 */
proto.nft.NftOrderRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftOrderRequest()
	return proto.nft.NftOrderRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftOrderRequest}
 */
proto.nft.NftOrderRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setHash(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setPrice(value)
				break
			case 4:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setToken(value)
				break
			case 5:
				var value = /** @type {string} */ (reader.readString())
				msg.setKind(value)
				break
			case 6:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setExpireat(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftOrderRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftOrderRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftOrderRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getHash()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getPrice()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getToken()
	if (f !== 0) {
		writer.writeInt32(4, f)
	}
	f = message.getKind()
	if (f.length > 0) {
		writer.writeString(5, f)
	}
	f = message.getExpireat()
	if (f != null) {
		writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
}

/**
 * optional string hash = 1;
 * @return {string}
 */
proto.nft.NftOrderRequest.prototype.getHash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setHash = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 collectionId = 2;
 * @return {number}
 */
proto.nft.NftOrderRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string price = 3;
 * @return {string}
 */
proto.nft.NftOrderRequest.prototype.getPrice = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setPrice = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional int32 token = 4;
 * @return {number}
 */
proto.nft.NftOrderRequest.prototype.getToken = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setToken = function (value) {
	return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional string kind = 5;
 * @return {string}
 */
proto.nft.NftOrderRequest.prototype.getKind = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setKind = function (value) {
	return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional google.protobuf.Timestamp expireAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.NftOrderRequest.prototype.getExpireat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.setExpireat = function (value) {
	return jspb.Message.setWrapperField(this, 6, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.NftOrderRequest} returns this
 */
proto.nft.NftOrderRequest.prototype.clearExpireat = function () {
	return this.setExpireat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.NftOrderRequest.prototype.hasExpireat = function () {
	return jspb.Message.getField(this, 6) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftOrderResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftOrderResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftOrderResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftOrderResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
				status: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftOrderResponse}
 */
proto.nft.NftOrderResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftOrderResponse()
	return proto.nft.NftOrderResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftOrderResponse}
 */
proto.nft.NftOrderResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setStatus(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftOrderResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftOrderResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftOrderResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getStatus()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.NftOrderResponse.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftOrderResponse} returns this
 */
proto.nft.NftOrderResponse.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string status = 2;
 * @return {string}
 */
proto.nft.NftOrderResponse.prototype.getStatus = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftOrderResponse} returns this
 */
proto.nft.NftOrderResponse.prototype.setStatus = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftCancelRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftCancelRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftCancelRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftCancelRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftCancelRequest}
 */
proto.nft.NftCancelRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftCancelRequest()
	return proto.nft.NftCancelRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftCancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftCancelRequest}
 */
proto.nft.NftCancelRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftCancelRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftCancelRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftCancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftCancelRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.NftCancelRequest.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftCancelRequest} returns this
 */
proto.nft.NftCancelRequest.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftCancelResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftCancelResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftCancelResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftCancelResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
				status: jspb.Message.getFieldWithDefault(msg, 2, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftCancelResponse}
 */
proto.nft.NftCancelResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftCancelResponse()
	return proto.nft.NftCancelResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftCancelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftCancelResponse}
 */
proto.nft.NftCancelResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setStatus(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftCancelResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftCancelResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftCancelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftCancelResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getStatus()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.NftCancelResponse.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftCancelResponse} returns this
 */
proto.nft.NftCancelResponse.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string status = 2;
 * @return {string}
 */
proto.nft.NftCancelResponse.prototype.getStatus = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftCancelResponse} returns this
 */
proto.nft.NftCancelResponse.prototype.setStatus = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.ListOrderRequest.repeatedFields_ = [4, 6]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListOrderRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListOrderRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListOrderRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListOrderRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
				nftid: jspb.Message.getFieldWithDefault(msg, 2, 0),
				filter: jspb.Message.getFieldWithDefault(msg, 3, 0),
				statusList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
				metadatafilterList: jspb.Message.toObjectList(
					msg.getMetadatafilterList(),
					proto.nft.NftMetadataFilter.toObject,
					includeInstance
				),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListOrderRequest}
 */
proto.nft.ListOrderRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListOrderRequest()
	return proto.nft.ListOrderRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListOrderRequest}
 */
proto.nft.ListOrderRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setNftid(value)
				break
			case 3:
				var value = /** @type {!proto.nft.ORDER_FILTER} */ (reader.readEnum())
				msg.setFilter(value)
				break
			case 4:
				var values = /** @type {!Array<!proto.nft.ORDER_STATUS>} */ (
					reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]
				)
				for (var i = 0; i < values.length; i++) {
					msg.addStatus(values[i])
				}
				break
			case 5:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			case 6:
				var value = new proto.nft.NftMetadataFilter()
				reader.readMessage(value, proto.nft.NftMetadataFilter.deserializeBinaryFromReader)
				msg.addMetadatafilter(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListOrderRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListOrderRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListOrderRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getNftid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getFilter()
	if (f !== 0.0) {
		writer.writeEnum(3, f)
	}
	f = message.getStatusList()
	if (f.length > 0) {
		writer.writePackedEnum(4, f)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(5, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
	f = message.getMetadatafilterList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(6, f, proto.nft.NftMetadataFilter.serializeBinaryToWriter)
	}
}

/**
 * optional int32 collectionId = 1;
 * @return {number}
 */
proto.nft.ListOrderRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional int32 nftId = 2;
 * @return {number}
 */
proto.nft.ListOrderRequest.prototype.getNftid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setNftid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional ORDER_FILTER filter = 3;
 * @return {!proto.nft.ORDER_FILTER}
 */
proto.nft.ListOrderRequest.prototype.getFilter = function () {
	return /** @type {!proto.nft.ORDER_FILTER} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {!proto.nft.ORDER_FILTER} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setFilter = function (value) {
	return jspb.Message.setProto3EnumField(this, 3, value)
}

/**
 * repeated ORDER_STATUS status = 4;
 * @return {!Array<!proto.nft.ORDER_STATUS>}
 */
proto.nft.ListOrderRequest.prototype.getStatusList = function () {
	return /** @type {!Array<!proto.nft.ORDER_STATUS>} */ (jspb.Message.getRepeatedField(this, 4))
}

/**
 * @param {!Array<!proto.nft.ORDER_STATUS>} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setStatusList = function (value) {
	return jspb.Message.setField(this, 4, value || [])
}

/**
 * @param {!proto.nft.ORDER_STATUS} value
 * @param {number=} opt_index
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.addStatus = function (value, opt_index) {
	return jspb.Message.addToRepeatedField(this, 4, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.clearStatusList = function () {
	return this.setStatusList([])
}

/**
 * optional PaginationParams paginationInfos = 5;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.ListOrderRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 5))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setPaginationinfos = function (value) {
	const paginationInfo = new proto.google.protobuf.ListValue.fromJavaScript(value)
	return jspb.Message.setWrapperField(this, 5, paginationInfo)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListOrderRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 5) != null
}

/**
 * repeated NftMetadataFilter metadataFilter = 6;
 * @return {!Array<!proto.nft.NftMetadataFilter>}
 */
proto.nft.ListOrderRequest.prototype.getMetadatafilterList = function () {
	return /** @type{!Array<!proto.nft.NftMetadataFilter>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.NftMetadataFilter, 6)
	)
}

/**
 * @param {!Array<!proto.nft.NftMetadataFilter>} value
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.setMetadatafilterList = function (value) {
	const metadataFilter = new proto.google.protobuf.ListValue.fromJavaScript(value)
	return jspb.Message.setRepeatedWrapperField(this, 6, metadataFilter)
}

/**
 * @param {!proto.nft.NftMetadataFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.NftMetadataFilter}
 */
proto.nft.ListOrderRequest.prototype.addMetadatafilter = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.nft.NftMetadataFilter, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListOrderRequest} returns this
 */
proto.nft.ListOrderRequest.prototype.clearMetadatafilterList = function () {
	return this.setMetadatafilterList([])
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.ListOrderResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.ListOrderResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.ListOrderResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.ListOrderResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.ListOrderResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderList: jspb.Message.toObjectList(msg.getOrderList(), proto.nft.Order.toObject, includeInstance),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.ListOrderResponse}
 */
proto.nft.ListOrderResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.ListOrderResponse()
	return proto.nft.ListOrderResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.ListOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.ListOrderResponse}
 */
proto.nft.ListOrderResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.Order()
				reader.readMessage(value, proto.nft.Order.deserializeBinaryFromReader)
				msg.addOrder(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.ListOrderResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.ListOrderResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.ListOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.ListOrderResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.Order.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated Order order = 1;
 * @return {!Array<!proto.nft.Order>}
 */
proto.nft.ListOrderResponse.prototype.getOrderList = function () {
	return /** @type{!Array<!proto.nft.Order>} */ (jspb.Message.getRepeatedWrapperField(this, proto.nft.Order, 1))
}

/**
 * @param {!Array<!proto.nft.Order>} value
 * @return {!proto.nft.ListOrderResponse} returns this
 */
proto.nft.ListOrderResponse.prototype.setOrderList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.Order}
 */
proto.nft.ListOrderResponse.prototype.addOrder = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.Order, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.ListOrderResponse} returns this
 */
proto.nft.ListOrderResponse.prototype.clearOrderList = function () {
	return this.setOrderList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.ListOrderResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.ListOrderResponse} returns this
 */
proto.nft.ListOrderResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.ListOrderResponse} returns this
 */
proto.nft.ListOrderResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.ListOrderResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.BuyNftRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.BuyNftRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.BuyNftRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.BuyNftRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
				tokenid: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.BuyNftRequest}
 */
proto.nft.BuyNftRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.BuyNftRequest()
	return proto.nft.BuyNftRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.BuyNftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.BuyNftRequest}
 */
proto.nft.BuyNftRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTokenid(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.BuyNftRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.BuyNftRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.BuyNftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.BuyNftRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getTokenid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.BuyNftRequest.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.BuyNftRequest} returns this
 */
proto.nft.BuyNftRequest.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 tokenId = 2;
 * @return {number}
 */
proto.nft.BuyNftRequest.prototype.getTokenid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.BuyNftRequest} returns this
 */
proto.nft.BuyNftRequest.prototype.setTokenid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.BuyNftResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.BuyNftResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.BuyNftResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.BuyNftResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.BuyNftResponse}
 */
proto.nft.BuyNftResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.BuyNftResponse()
	return proto.nft.BuyNftResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.BuyNftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.BuyNftResponse}
 */
proto.nft.BuyNftResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.BuyNftResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.BuyNftResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.BuyNftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.BuyNftResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.BuyNftResponse.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.BuyNftResponse} returns this
 */
proto.nft.BuyNftResponse.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftOrderHistoryRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftOrderHistoryRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftOrderHistoryRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftOrderHistoryRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				tokenhash: jspb.Message.getFieldWithDefault(msg, 1, ''),
				collectionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftOrderHistoryRequest}
 */
proto.nft.GetNftOrderHistoryRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftOrderHistoryRequest()
	return proto.nft.GetNftOrderHistoryRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftOrderHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftOrderHistoryRequest}
 */
proto.nft.GetNftOrderHistoryRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setTokenhash(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 3:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftOrderHistoryRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftOrderHistoryRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftOrderHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftOrderHistoryRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getTokenhash()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(3, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
}

/**
 * optional string tokenHash = 1;
 * @return {string}
 */
proto.nft.GetNftOrderHistoryRequest.prototype.getTokenhash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.GetNftOrderHistoryRequest} returns this
 */
proto.nft.GetNftOrderHistoryRequest.prototype.setTokenhash = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 CollectionId = 2;
 * @return {number}
 */
proto.nft.GetNftOrderHistoryRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.GetNftOrderHistoryRequest} returns this
 */
proto.nft.GetNftOrderHistoryRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional PaginationParams paginationInfos = 3;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.GetNftOrderHistoryRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 3))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.GetNftOrderHistoryRequest} returns this
 */
proto.nft.GetNftOrderHistoryRequest.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetNftOrderHistoryRequest} returns this
 */
proto.nft.GetNftOrderHistoryRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetNftOrderHistoryRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 3) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetNftOrderHistoryResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftOrderHistoryResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftOrderHistoryResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftOrderHistoryResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftOrderHistoryResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderhistoryList: jspb.Message.toObjectList(
					msg.getOrderhistoryList(),
					proto.nft.OrderHistory.toObject,
					includeInstance
				),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftOrderHistoryResponse}
 */
proto.nft.GetNftOrderHistoryResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftOrderHistoryResponse()
	return proto.nft.GetNftOrderHistoryResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftOrderHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftOrderHistoryResponse}
 */
proto.nft.GetNftOrderHistoryResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.OrderHistory()
				reader.readMessage(value, proto.nft.OrderHistory.deserializeBinaryFromReader)
				msg.addOrderhistory(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftOrderHistoryResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftOrderHistoryResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftOrderHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftOrderHistoryResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderhistoryList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.OrderHistory.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated OrderHistory orderHistory = 1;
 * @return {!Array<!proto.nft.OrderHistory>}
 */
proto.nft.GetNftOrderHistoryResponse.prototype.getOrderhistoryList = function () {
	return /** @type{!Array<!proto.nft.OrderHistory>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.OrderHistory, 1)
	)
}

/**
 * @param {!Array<!proto.nft.OrderHistory>} value
 * @return {!proto.nft.GetNftOrderHistoryResponse} returns this
 */
proto.nft.GetNftOrderHistoryResponse.prototype.setOrderhistoryList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.OrderHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.OrderHistory}
 */
proto.nft.GetNftOrderHistoryResponse.prototype.addOrderhistory = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.OrderHistory, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetNftOrderHistoryResponse} returns this
 */
proto.nft.GetNftOrderHistoryResponse.prototype.clearOrderhistoryList = function () {
	return this.setOrderhistoryList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.GetNftOrderHistoryResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.GetNftOrderHistoryResponse} returns this
 */
proto.nft.GetNftOrderHistoryResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetNftOrderHistoryResponse} returns this
 */
proto.nft.GetNftOrderHistoryResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetNftOrderHistoryResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetNftsByCollectionNameRequest.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftsByCollectionNameRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftsByCollectionNameRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftsByCollectionNameRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftsByCollectionNameRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				name: jspb.Message.getFieldWithDefault(msg, 1, ''),
				filtersList: jspb.Message.toObjectList(
					msg.getFiltersList(),
					proto.nft.NftMetadataFilter.toObject,
					includeInstance
				),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftsByCollectionNameRequest}
 */
proto.nft.GetNftsByCollectionNameRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftsByCollectionNameRequest()
	return proto.nft.GetNftsByCollectionNameRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftsByCollectionNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftsByCollectionNameRequest}
 */
proto.nft.GetNftsByCollectionNameRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setName(value)
				break
			case 2:
				var value = new proto.nft.NftMetadataFilter()
				reader.readMessage(value, proto.nft.NftMetadataFilter.deserializeBinaryFromReader)
				msg.addFilters(value)
				break
			case 3:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftsByCollectionNameRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftsByCollectionNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftsByCollectionNameRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getName()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getFiltersList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(2, f, proto.nft.NftMetadataFilter.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(3, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
}

/**
 * optional string name = 1;
 * @return {string}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.getName = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.GetNftsByCollectionNameRequest} returns this
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.setName = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * repeated NftMetadataFilter filters = 2;
 * @return {!Array<!proto.nft.NftMetadataFilter>}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.getFiltersList = function () {
	return /** @type{!Array<!proto.nft.NftMetadataFilter>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.NftMetadataFilter, 2)
	)
}

/**
 * @param {!Array<!proto.nft.NftMetadataFilter>} value
 * @return {!proto.nft.GetNftsByCollectionNameRequest} returns this
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.setFiltersList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.nft.NftMetadataFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.NftMetadataFilter}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.addFilters = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.nft.NftMetadataFilter, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetNftsByCollectionNameRequest} returns this
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.clearFiltersList = function () {
	return this.setFiltersList([])
}

/**
 * optional PaginationParams paginationInfos = 3;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 3))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.GetNftsByCollectionNameRequest} returns this
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetNftsByCollectionNameRequest} returns this
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetNftsByCollectionNameRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 3) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetNftsByCollectionNameResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftsByCollectionNameResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftsByCollectionNameResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftsByCollectionNameResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftsByCollectionNameResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				nftsList: jspb.Message.toObjectList(msg.getNftsList(), proto.nft.Nft.toObject, includeInstance),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftsByCollectionNameResponse}
 */
proto.nft.GetNftsByCollectionNameResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftsByCollectionNameResponse()
	return proto.nft.GetNftsByCollectionNameResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftsByCollectionNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftsByCollectionNameResponse}
 */
proto.nft.GetNftsByCollectionNameResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.Nft()
				reader.readMessage(value, proto.nft.Nft.deserializeBinaryFromReader)
				msg.addNfts(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftsByCollectionNameResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftsByCollectionNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftsByCollectionNameResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getNftsList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.Nft.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated Nft nfts = 1;
 * @return {!Array<!proto.nft.Nft>}
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.getNftsList = function () {
	return /** @type{!Array<!proto.nft.Nft>} */ (jspb.Message.getRepeatedWrapperField(this, proto.nft.Nft, 1))
}

/**
 * @param {!Array<!proto.nft.Nft>} value
 * @return {!proto.nft.GetNftsByCollectionNameResponse} returns this
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.setNftsList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.Nft=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.Nft}
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.addNfts = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.Nft, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetNftsByCollectionNameResponse} returns this
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.clearNftsList = function () {
	return this.setNftsList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.GetNftsByCollectionNameResponse} returns this
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetNftsByCollectionNameResponse} returns this
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetNftsByCollectionNameResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.NftRanking.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.NftRanking.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.NftRanking} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.NftRanking.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
				collectionname: jspb.Message.getFieldWithDefault(msg, 2, ''),
				collectionimage: jspb.Message.getFieldWithDefault(msg, 3, ''),
				totalvolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
				currentvolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
				lastvolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
				floorprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
				owners: jspb.Message.getFieldWithDefault(msg, 8, ''),
				items: jspb.Message.getFieldWithDefault(msg, 9, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.NftRanking}
 */
proto.nft.NftRanking.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.NftRanking()
	return proto.nft.NftRanking.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.NftRanking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.NftRanking}
 */
proto.nft.NftRanking.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setCollectionname(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setCollectionimage(value)
				break
			case 4:
				var value = /** @type {number} */ (reader.readDouble())
				msg.setTotalvolume(value)
				break
			case 5:
				var value = /** @type {number} */ (reader.readDouble())
				msg.setCurrentvolume(value)
				break
			case 6:
				var value = /** @type {number} */ (reader.readDouble())
				msg.setLastvolume(value)
				break
			case 7:
				var value = /** @type {number} */ (reader.readDouble())
				msg.setFloorprice(value)
				break
			case 8:
				var value = /** @type {string} */ (reader.readString())
				msg.setOwners(value)
				break
			case 9:
				var value = /** @type {string} */ (reader.readString())
				msg.setItems(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.NftRanking.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.NftRanking.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.NftRanking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.NftRanking.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getCollectionname()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getCollectionimage()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getTotalvolume()
	if (f !== 0.0) {
		writer.writeDouble(4, f)
	}
	f = message.getCurrentvolume()
	if (f !== 0.0) {
		writer.writeDouble(5, f)
	}
	f = message.getLastvolume()
	if (f !== 0.0) {
		writer.writeDouble(6, f)
	}
	f = message.getFloorprice()
	if (f !== 0.0) {
		writer.writeDouble(7, f)
	}
	f = message.getOwners()
	if (f.length > 0) {
		writer.writeString(8, f)
	}
	f = message.getItems()
	if (f.length > 0) {
		writer.writeString(9, f)
	}
}

/**
 * optional int32 collectionId = 1;
 * @return {number}
 */
proto.nft.NftRanking.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string collectionName = 2;
 * @return {string}
 */
proto.nft.NftRanking.prototype.getCollectionname = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setCollectionname = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string collectionImage = 3;
 * @return {string}
 */
proto.nft.NftRanking.prototype.getCollectionimage = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setCollectionimage = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional double totalVolume = 4;
 * @return {number}
 */
proto.nft.NftRanking.prototype.getTotalvolume = function () {
	return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setTotalvolume = function (value) {
	return jspb.Message.setProto3FloatField(this, 4, value)
}

/**
 * optional double currentVolume = 5;
 * @return {number}
 */
proto.nft.NftRanking.prototype.getCurrentvolume = function () {
	return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setCurrentvolume = function (value) {
	return jspb.Message.setProto3FloatField(this, 5, value)
}

/**
 * optional double lastVolume = 6;
 * @return {number}
 */
proto.nft.NftRanking.prototype.getLastvolume = function () {
	return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setLastvolume = function (value) {
	return jspb.Message.setProto3FloatField(this, 6, value)
}

/**
 * optional double floorPrice = 7;
 * @return {number}
 */
proto.nft.NftRanking.prototype.getFloorprice = function () {
	return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0))
}

/**
 * @param {number} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setFloorprice = function (value) {
	return jspb.Message.setProto3FloatField(this, 7, value)
}

/**
 * optional string owners = 8;
 * @return {string}
 */
proto.nft.NftRanking.prototype.getOwners = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setOwners = function (value) {
	return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional string items = 9;
 * @return {string}
 */
proto.nft.NftRanking.prototype.getItems = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.NftRanking} returns this
 */
proto.nft.NftRanking.prototype.setItems = function (value) {
	return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetNftRankingResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetNftRankingResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetNftRankingResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetNftRankingResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetNftRankingResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				rankingList: jspb.Message.toObjectList(
					msg.getRankingList(),
					proto.nft.NftRanking.toObject,
					includeInstance
				),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetNftRankingResponse}
 */
proto.nft.GetNftRankingResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetNftRankingResponse()
	return proto.nft.GetNftRankingResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetNftRankingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetNftRankingResponse}
 */
proto.nft.GetNftRankingResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.NftRanking()
				reader.readMessage(value, proto.nft.NftRanking.deserializeBinaryFromReader)
				msg.addRanking(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetNftRankingResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetNftRankingResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetNftRankingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetNftRankingResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getRankingList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.NftRanking.serializeBinaryToWriter)
	}
}

/**
 * repeated NftRanking ranking = 1;
 * @return {!Array<!proto.nft.NftRanking>}
 */
proto.nft.GetNftRankingResponse.prototype.getRankingList = function () {
	return /** @type{!Array<!proto.nft.NftRanking>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.NftRanking, 1)
	)
}

/**
 * @param {!Array<!proto.nft.NftRanking>} value
 * @return {!proto.nft.GetNftRankingResponse} returns this
 */
proto.nft.GetNftRankingResponse.prototype.setRankingList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.NftRanking=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.NftRanking}
 */
proto.nft.GetNftRankingResponse.prototype.addRanking = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.NftRanking, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetNftRankingResponse} returns this
 */
proto.nft.GetNftRankingResponse.prototype.clearRankingList = function () {
	return this.setRankingList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.WithdrawNftRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.WithdrawNftRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.WithdrawNftRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.WithdrawNftRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
				nfthash: jspb.Message.getFieldWithDefault(msg, 2, ''),
				destination: jspb.Message.getFieldWithDefault(msg, 3, ''),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.WithdrawNftRequest}
 */
proto.nft.WithdrawNftRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.WithdrawNftRequest()
	return proto.nft.WithdrawNftRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.WithdrawNftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.WithdrawNftRequest}
 */
proto.nft.WithdrawNftRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 2:
				var value = /** @type {string} */ (reader.readString())
				msg.setNfthash(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setDestination(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.WithdrawNftRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.WithdrawNftRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.WithdrawNftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.WithdrawNftRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getNfthash()
	if (f.length > 0) {
		writer.writeString(2, f)
	}
	f = message.getDestination()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
}

/**
 * optional int32 collectionId = 1;
 * @return {number}
 */
proto.nft.WithdrawNftRequest.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.WithdrawNftRequest} returns this
 */
proto.nft.WithdrawNftRequest.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string nftHash = 2;
 * @return {string}
 */
proto.nft.WithdrawNftRequest.prototype.getNfthash = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.WithdrawNftRequest} returns this
 */
proto.nft.WithdrawNftRequest.prototype.setNfthash = function (value) {
	return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string destination = 3;
 * @return {string}
 */
proto.nft.WithdrawNftRequest.prototype.getDestination = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.WithdrawNftRequest} returns this
 */
proto.nft.WithdrawNftRequest.prototype.setDestination = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.WithdrawNftResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.WithdrawNftResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.WithdrawNftResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.WithdrawNftResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
				id: jspb.Message.getFieldWithDefault(msg, 2, 0),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.WithdrawNftResponse}
 */
proto.nft.WithdrawNftResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.WithdrawNftResponse()
	return proto.nft.WithdrawNftResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.WithdrawNftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.WithdrawNftResponse}
 */
proto.nft.WithdrawNftResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {boolean} */ (reader.readBool())
				msg.setSuccess(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setId(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.WithdrawNftResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.WithdrawNftResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.WithdrawNftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.WithdrawNftResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getSuccess()
	if (f) {
		writer.writeBool(1, f)
	}
	f = message.getId()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
}

/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.nft.WithdrawNftResponse.prototype.getSuccess = function () {
	return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false))
}

/**
 * @param {boolean} value
 * @return {!proto.nft.WithdrawNftResponse} returns this
 */
proto.nft.WithdrawNftResponse.prototype.setSuccess = function (value) {
	return jspb.Message.setProto3BooleanField(this, 1, value)
}

/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.nft.WithdrawNftResponse.prototype.getId = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.WithdrawNftResponse} returns this
 */
proto.nft.WithdrawNftResponse.prototype.setId = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetUserOrderHistoryRequest.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetUserOrderHistoryRequest.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetUserOrderHistoryRequest} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetUserOrderHistoryRequest.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				ordertype: jspb.Message.getFieldWithDefault(msg, 1, 0),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationParams.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetUserOrderHistoryRequest}
 */
proto.nft.GetUserOrderHistoryRequest.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetUserOrderHistoryRequest()
	return proto.nft.GetUserOrderHistoryRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetUserOrderHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetUserOrderHistoryRequest}
 */
proto.nft.GetUserOrderHistoryRequest.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {!proto.nft.ORDER_HISTORY_TYPE} */ (reader.readEnum())
				msg.setOrdertype(value)
				break
			case 2:
				var value = new proto.nft.PaginationParams()
				reader.readMessage(value, proto.nft.PaginationParams.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetUserOrderHistoryRequest.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetUserOrderHistoryRequest.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetUserOrderHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetUserOrderHistoryRequest.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrdertype()
	if (f !== 0.0) {
		writer.writeEnum(1, f)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationParams.serializeBinaryToWriter)
	}
}

/**
 * optional ORDER_HISTORY_TYPE orderType = 1;
 * @return {!proto.nft.ORDER_HISTORY_TYPE}
 */
proto.nft.GetUserOrderHistoryRequest.prototype.getOrdertype = function () {
	return /** @type {!proto.nft.ORDER_HISTORY_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {!proto.nft.ORDER_HISTORY_TYPE} value
 * @return {!proto.nft.GetUserOrderHistoryRequest} returns this
 */
proto.nft.GetUserOrderHistoryRequest.prototype.setOrdertype = function (value) {
	return jspb.Message.setProto3EnumField(this, 1, value)
}

/**
 * optional PaginationParams paginationInfos = 2;
 * @return {?proto.nft.PaginationParams}
 */
proto.nft.GetUserOrderHistoryRequest.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationParams} */ (jspb.Message.getWrapperField(this, proto.nft.PaginationParams, 2))
}

/**
 * @param {?proto.nft.PaginationParams|undefined} value
 * @return {!proto.nft.GetUserOrderHistoryRequest} returns this
 */
proto.nft.GetUserOrderHistoryRequest.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetUserOrderHistoryRequest} returns this
 */
proto.nft.GetUserOrderHistoryRequest.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetUserOrderHistoryRequest.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.UserOrderHistory.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.UserOrderHistory.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.UserOrderHistory} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.UserOrderHistory.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				orderid: jspb.Message.getFieldWithDefault(msg, 1, ''),
				nftid: jspb.Message.getFieldWithDefault(msg, 2, 0),
				status: jspb.Message.getFieldWithDefault(msg, 3, ''),
				price: jspb.Message.getFieldWithDefault(msg, 4, ''),
				ordertype: jspb.Message.getFieldWithDefault(msg, 5, ''),
				tokenid: jspb.Message.getFieldWithDefault(msg, 6, 0),
				nftdetail: (f = msg.getNftdetail()) && proto.nft.NftInfo.toObject(includeInstance, f),
				historytype: jspb.Message.getFieldWithDefault(msg, 8, 0),
				createdat:
					(f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
				updatedat:
					(f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.UserOrderHistory}
 */
proto.nft.UserOrderHistory.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.UserOrderHistory()
	return proto.nft.UserOrderHistory.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.UserOrderHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.UserOrderHistory}
 */
proto.nft.UserOrderHistory.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrderid(value)
				break
			case 2:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setNftid(value)
				break
			case 3:
				var value = /** @type {string} */ (reader.readString())
				msg.setStatus(value)
				break
			case 4:
				var value = /** @type {string} */ (reader.readString())
				msg.setPrice(value)
				break
			case 5:
				var value = /** @type {string} */ (reader.readString())
				msg.setOrdertype(value)
				break
			case 6:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setTokenid(value)
				break
			case 7:
				var value = new proto.nft.NftInfo()
				reader.readMessage(value, proto.nft.NftInfo.deserializeBinaryFromReader)
				msg.setNftdetail(value)
				break
			case 8:
				var value = /** @type {!proto.nft.ORDER_HISTORY_TYPE} */ (reader.readEnum())
				msg.setHistorytype(value)
				break
			case 9:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setCreatedat(value)
				break
			case 10:
				var value = new google_protobuf_timestamp_pb.Timestamp()
				reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader)
				msg.setUpdatedat(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.UserOrderHistory.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.UserOrderHistory.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.UserOrderHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.UserOrderHistory.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getOrderid()
	if (f.length > 0) {
		writer.writeString(1, f)
	}
	f = message.getNftid()
	if (f !== 0) {
		writer.writeInt32(2, f)
	}
	f = message.getStatus()
	if (f.length > 0) {
		writer.writeString(3, f)
	}
	f = message.getPrice()
	if (f.length > 0) {
		writer.writeString(4, f)
	}
	f = message.getOrdertype()
	if (f.length > 0) {
		writer.writeString(5, f)
	}
	f = message.getTokenid()
	if (f !== 0) {
		writer.writeInt32(6, f)
	}
	f = message.getNftdetail()
	if (f != null) {
		writer.writeMessage(7, f, proto.nft.NftInfo.serializeBinaryToWriter)
	}
	f = message.getHistorytype()
	if (f !== 0.0) {
		writer.writeEnum(8, f)
	}
	f = message.getCreatedat()
	if (f != null) {
		writer.writeMessage(9, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
	f = message.getUpdatedat()
	if (f != null) {
		writer.writeMessage(10, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter)
	}
}

/**
 * optional string orderId = 1;
 * @return {string}
 */
proto.nft.UserOrderHistory.prototype.getOrderid = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setOrderid = function (value) {
	return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional int32 nftId = 2;
 * @return {number}
 */
proto.nft.UserOrderHistory.prototype.getNftid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setNftid = function (value) {
	return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string status = 3;
 * @return {string}
 */
proto.nft.UserOrderHistory.prototype.getStatus = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setStatus = function (value) {
	return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string price = 4;
 * @return {string}
 */
proto.nft.UserOrderHistory.prototype.getPrice = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setPrice = function (value) {
	return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string orderType = 5;
 * @return {string}
 */
proto.nft.UserOrderHistory.prototype.getOrdertype = function () {
	return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setOrdertype = function (value) {
	return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional int32 tokenID = 6;
 * @return {number}
 */
proto.nft.UserOrderHistory.prototype.getTokenid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setTokenid = function (value) {
	return jspb.Message.setProto3IntField(this, 6, value)
}

/**
 * optional NftInfo nftDetail = 7;
 * @return {?proto.nft.NftInfo}
 */
proto.nft.UserOrderHistory.prototype.getNftdetail = function () {
	return /** @type{?proto.nft.NftInfo} */ (jspb.Message.getWrapperField(this, proto.nft.NftInfo, 7))
}

/**
 * @param {?proto.nft.NftInfo|undefined} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setNftdetail = function (value) {
	return jspb.Message.setWrapperField(this, 7, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.clearNftdetail = function () {
	return this.setNftdetail(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.UserOrderHistory.prototype.hasNftdetail = function () {
	return jspb.Message.getField(this, 7) != null
}

/**
 * optional ORDER_HISTORY_TYPE historyType = 8;
 * @return {!proto.nft.ORDER_HISTORY_TYPE}
 */
proto.nft.UserOrderHistory.prototype.getHistorytype = function () {
	return /** @type {!proto.nft.ORDER_HISTORY_TYPE} */ (jspb.Message.getFieldWithDefault(this, 8, 0))
}

/**
 * @param {!proto.nft.ORDER_HISTORY_TYPE} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setHistorytype = function (value) {
	return jspb.Message.setProto3EnumField(this, 8, value)
}

/**
 * optional google.protobuf.Timestamp createdAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.UserOrderHistory.prototype.getCreatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setCreatedat = function (value) {
	return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.clearCreatedat = function () {
	return this.setCreatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.UserOrderHistory.prototype.hasCreatedat = function () {
	return jspb.Message.getField(this, 9) != null
}

/**
 * optional google.protobuf.Timestamp updatedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.nft.UserOrderHistory.prototype.getUpdatedat = function () {
	return /** @type{?proto.google.protobuf.Timestamp} */ (
		jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10)
	)
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.setUpdatedat = function (value) {
	return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.UserOrderHistory} returns this
 */
proto.nft.UserOrderHistory.prototype.clearUpdatedat = function () {
	return this.setUpdatedat(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.UserOrderHistory.prototype.hasUpdatedat = function () {
	return jspb.Message.getField(this, 10) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetUserOrderHistoryResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetUserOrderHistoryResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetUserOrderHistoryResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetUserOrderHistoryResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetUserOrderHistoryResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				dataList: jspb.Message.toObjectList(
					msg.getDataList(),
					proto.nft.UserOrderHistory.toObject,
					includeInstance
				),
				paginationinfos:
					(f = msg.getPaginationinfos()) && proto.nft.PaginationResponse.toObject(includeInstance, f),
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetUserOrderHistoryResponse}
 */
proto.nft.GetUserOrderHistoryResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetUserOrderHistoryResponse()
	return proto.nft.GetUserOrderHistoryResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetUserOrderHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetUserOrderHistoryResponse}
 */
proto.nft.GetUserOrderHistoryResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = new proto.nft.UserOrderHistory()
				reader.readMessage(value, proto.nft.UserOrderHistory.deserializeBinaryFromReader)
				msg.addData(value)
				break
			case 2:
				var value = new proto.nft.PaginationResponse()
				reader.readMessage(value, proto.nft.PaginationResponse.deserializeBinaryFromReader)
				msg.setPaginationinfos(value)
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetUserOrderHistoryResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetUserOrderHistoryResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetUserOrderHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetUserOrderHistoryResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getDataList()
	if (f.length > 0) {
		writer.writeRepeatedMessage(1, f, proto.nft.UserOrderHistory.serializeBinaryToWriter)
	}
	f = message.getPaginationinfos()
	if (f != null) {
		writer.writeMessage(2, f, proto.nft.PaginationResponse.serializeBinaryToWriter)
	}
}

/**
 * repeated UserOrderHistory data = 1;
 * @return {!Array<!proto.nft.UserOrderHistory>}
 */
proto.nft.GetUserOrderHistoryResponse.prototype.getDataList = function () {
	return /** @type{!Array<!proto.nft.UserOrderHistory>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.nft.UserOrderHistory, 1)
	)
}

/**
 * @param {!Array<!proto.nft.UserOrderHistory>} value
 * @return {!proto.nft.GetUserOrderHistoryResponse} returns this
 */
proto.nft.GetUserOrderHistoryResponse.prototype.setDataList = function (value) {
	return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.nft.UserOrderHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nft.UserOrderHistory}
 */
proto.nft.GetUserOrderHistoryResponse.prototype.addData = function (opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nft.UserOrderHistory, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetUserOrderHistoryResponse} returns this
 */
proto.nft.GetUserOrderHistoryResponse.prototype.clearDataList = function () {
	return this.setDataList([])
}

/**
 * optional PaginationResponse paginationInfos = 2;
 * @return {?proto.nft.PaginationResponse}
 */
proto.nft.GetUserOrderHistoryResponse.prototype.getPaginationinfos = function () {
	return /** @type{?proto.nft.PaginationResponse} */ (
		jspb.Message.getWrapperField(this, proto.nft.PaginationResponse, 2)
	)
}

/**
 * @param {?proto.nft.PaginationResponse|undefined} value
 * @return {!proto.nft.GetUserOrderHistoryResponse} returns this
 */
proto.nft.GetUserOrderHistoryResponse.prototype.setPaginationinfos = function (value) {
	return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.nft.GetUserOrderHistoryResponse} returns this
 */
proto.nft.GetUserOrderHistoryResponse.prototype.clearPaginationinfos = function () {
	return this.setPaginationinfos(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nft.GetUserOrderHistoryResponse.prototype.hasPaginationinfos = function () {
	return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nft.GetCollectionTokensResponse.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.nft.GetCollectionTokensResponse.prototype.toObject = function (opt_includeInstance) {
		return proto.nft.GetCollectionTokensResponse.toObject(opt_includeInstance, this)
	}

	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.nft.GetCollectionTokensResponse} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.nft.GetCollectionTokensResponse.toObject = function (includeInstance, msg) {
		var f,
			obj = {
				collectionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
				tokensidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
			}

		if (includeInstance) {
			obj.$jspbMessageInstance = msg
		}
		return obj
	}
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nft.GetCollectionTokensResponse}
 */
proto.nft.GetCollectionTokensResponse.deserializeBinary = function (bytes) {
	var reader = new jspb.BinaryReader(bytes)
	var msg = new proto.nft.GetCollectionTokensResponse()
	return proto.nft.GetCollectionTokensResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nft.GetCollectionTokensResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nft.GetCollectionTokensResponse}
 */
proto.nft.GetCollectionTokensResponse.deserializeBinaryFromReader = function (msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break
		}
		var field = reader.getFieldNumber()
		switch (field) {
			case 1:
				var value = /** @type {number} */ (reader.readInt32())
				msg.setCollectionid(value)
				break
			case 2:
				var values = /** @type {!Array<number>} */ (
					reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]
				)
				for (var i = 0; i < values.length; i++) {
					msg.addTokensids(values[i])
				}
				break
			default:
				reader.skipField()
				break
		}
	}
	return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nft.GetCollectionTokensResponse.prototype.serializeBinary = function () {
	var writer = new jspb.BinaryWriter()
	proto.nft.GetCollectionTokensResponse.serializeBinaryToWriter(this, writer)
	return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nft.GetCollectionTokensResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nft.GetCollectionTokensResponse.serializeBinaryToWriter = function (message, writer) {
	var f = undefined
	f = message.getCollectionid()
	if (f !== 0) {
		writer.writeInt32(1, f)
	}
	f = message.getTokensidsList()
	if (f.length > 0) {
		writer.writePackedInt32(2, f)
	}
}

/**
 * optional int32 collectionId = 1;
 * @return {number}
 */
proto.nft.GetCollectionTokensResponse.prototype.getCollectionid = function () {
	return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.nft.GetCollectionTokensResponse} returns this
 */
proto.nft.GetCollectionTokensResponse.prototype.setCollectionid = function (value) {
	return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * repeated int32 tokensIds = 2;
 * @return {!Array<number>}
 */
proto.nft.GetCollectionTokensResponse.prototype.getTokensidsList = function () {
	return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2))
}

/**
 * @param {!Array<number>} value
 * @return {!proto.nft.GetCollectionTokensResponse} returns this
 */
proto.nft.GetCollectionTokensResponse.prototype.setTokensidsList = function (value) {
	return jspb.Message.setField(this, 2, value || [])
}

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.nft.GetCollectionTokensResponse} returns this
 */
proto.nft.GetCollectionTokensResponse.prototype.addTokensids = function (value, opt_index) {
	return jspb.Message.addToRepeatedField(this, 2, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nft.GetCollectionTokensResponse} returns this
 */
proto.nft.GetCollectionTokensResponse.prototype.clearTokensidsList = function () {
	return this.setTokensidsList([])
}

/**
 * @enum {number}
 */
proto.nft.OKTA_CLIENT = {
	UNKNOWN: 0,
	ANDROID: 1,
	IOS: 2,
	WEB: 3,
}

/**
 * @enum {number}
 */
proto.nft.ORDER_FILTER = {
	LATEST: 0,
	PRICEASC: 1,
	PRICEDESC: 2,
}

/**
 * @enum {number}
 */
proto.nft.ORDER_STATUS = {
	ORDER_UNKNOWN: 0,
	OPEN: 1,
	CANCELED: 2,
	DONE: 3,
	EXPIRED: 4,
}

/**
 * @enum {number}
 */
proto.nft.ORDER_HISTORY_TYPE = {
	ALL: 0,
	SELL: 1,
	BUY: 2,
}

goog.object.extend(exports, proto.nft)
