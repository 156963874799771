import { Card, Skeleton } from 'antd'
import React from 'react'

const TrendingSkeleton = () => {
	return (
		<Card className='klever-trending-card' style={{ maxWidth: 320 }}>
			<Skeleton active paragraph={{ rows: 3 }} title />
		</Card>
	)
}

export default TrendingSkeleton
