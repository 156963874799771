import axios from 'axios'
import get from 'lodash/get'

// import keycloak from '../../keycloak'
import { getAccessToken, logError, verifyExpired } from '../../utils'
// const TEST = process.env.NODE_ENV === 'test'

// const isProd = TEST ? false : window.location.hostname === 'cex.bitcoin.me'
// const isBeta = TEST ? false : window.location.hostname === 'beta.cex.bitcoin.me'

const USERSETTINGS_API = process.env.REACT_APP_USERSETTINGS_API

const USERSETTINGS = axios.create({
	baseURL: USERSETTINGS_API,
	headers: { ClientOrigin: 3 },
})

USERSETTINGS.interceptors.request.use(async config => {
	config.headers.AuthToken = await getAccessToken()
	return config
})

// if (isProd || isBeta) {
// 	USERSETTINGS.interceptors.response.use(
// 		response => {
// 			return response
// 		},
// 		error => {
// 			if (error.response.status === 401) {
// 				keycloak.logout()
// 			}
// 			return error
// 		}
// 	)
// }

export const getTradeFeeWithKlv = async () => {
	try {
		const { data } = await USERSETTINGS.get(
			`v1/get-user-settings?key=${process.env.REACT_APP_API_KEY_USER_SETTINGS}`
		)
		return get(data, 'TradeFeeWithKlv', false)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getTradeFeeWithKlv logError')
		const errorStatus = get(error, 'response.status', 'error in getTradeFeeWithKlv getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/usersettings/getTradeFeeWithKlv', errorResponse)
		return { error: errorResponse }
	}
}

export const setTradeFeeWithKlv = async TradeFeeWithKlv => {
	const body = {
		TradeFeeWithKlv,
	}

	try {
		const { data } = await USERSETTINGS.post(
			`v1/set-trade-fee-klv?key=${process.env.REACT_APP_API_KEY_USER_SETTINGS}`,
			body
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in setTradeFeeWithKlv logError')
		const errorStatus = get(error, 'response.status', 'error in setTradeFeeWithKlv getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/usersettings/setTradeFeeWithKlv', errorResponse)
		return { error: errorResponse }
	}
}

export const setKeypairFavorite = async (KeypairID, Remove) => {
	try {
		const { data } = await USERSETTINGS.post(
			`v1/set-keypair-favorite?key=${process.env.REACT_APP_API_KEY_USER_SETTINGS}`,
			{ KeypairID, Remove }
		)
		return get(data, 'success', false)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in setKeypairFavorite logError')
		logError('klv_setKeypairFavorite_error')
		return { error: errorResponse }
	}
}

export const getKeypairFavorite = async KeypairFavorites => {
	try {
		const { data } = await USERSETTINGS.get(
			`v1/get-user-settings?key=${process.env.REACT_APP_API_KEY_USER_SETTINGS}`,
			{ KeypairFavorites }
		)
		return get(data, 'KeypairFavorites', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getKeypairFavorite logError')
		logError('klv_getKeypairFavorite_error')
		return { error: errorResponse }
	}
}
