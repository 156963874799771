import { Button, Modal, Skeleton } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import styled from 'styled-components/macro'

import { COMMUNICATION_STORAGE } from '../../../constants'

export const HeaderSkeletonContainer = styled(Header)`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 64px;
	padding: 0;

	> * {
		&:first-child {
			width: 100%;
			max-width: 1440px;
			margin: 0 auto;
			padding: 0 10px 0 30px;
			@media only screen and (min-width: 1392px) {
				padding: 0 40px;
			}
		}
	}
`
export const MenuItemSkeleton = styled(Skeleton)`
	width: auto;
	margin-right: 15px;
	@media screen and (max-width: 992px) {
		display: none;
	}
`

export const HeaderLogo = styled.div`
	width: ${props => props.isfromtrade === 'true' && '269px'};
	display: flex;
	justify-content: space-between;

	svg {
		margin: 12px 6px 0 0;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
`

export const LoginButtonDisconnectedWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: -3px;
`

export const LoginButtonConnectedWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: ${props => props.theme.colors.secondary};
	border-radius: 50px;
	padding: 0 8px;
	gap: 10px;
	height: 34px;
`

export const LoginButton = styled(Button)`
	border-radius: 50px;
	font-weight: bold;
	font-size: 12px;

	@media screen and (max-width: 325px) {
		display: none;
	}
`

export const CloseButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	margin: 0;
	padding: 0;
	cursor: pointer;
`

export const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 0;
	padding: 6px 10px 6px 6px;
	background-color: ${props => props.theme.colors.secondary};
	border-radius: 50px;
	font-weight: bold;
`

export const ModalStyled = styled(Modal)`
	margin: auto;
`

export const LanguageButton = styled.button`
	color: ${({ language }) => (language === 'true' ? '#d96200' : '#a5a5a5')};
	font-weight: ${({ language }) => (language ? 700 : 400)};
	margin-bottom: 5px;
	margin-right: 15px;
	border: none;
	background: none;
	cursor: pointer;
	padding: 0;
	font-size: 14px;
	transition: all 0.3s ease-in-out;

	&:hover {
		opacity: 0.8;
	}
`

export const LanguagesContainer = styled.div`
	display: flex;
	margin-bottom: 5px;
`

export const LanguageLineWrapper = styled.div`
	width: 100%;
`

export const ChooseContainer = styled.div`
	border-bottom-width: 1px;
	border-bottom-color: #a5a5a5;
	border-bottom-style: solid;
	width: 100%;
	margin-bottom: 10px;

	p {
		font-size: 16px;
		color: #a5a5a5;
		font-weight: bold;
		padding: 0 0 10px;
		margin: 0;
	}
`

export const ArrowBackWrapper = styled.div`
	display: flex;
	width: 100%;
	position: sticky;
	top: 0;
	background-color: #1e1e26;
	z-index: 10;
	align-items: center;
	padding-left: ${props => (props.pageUrl === true ? '15px' : '20px')};
	padding-top: ${props => {
		if (props.pageUrl === true) return '8px'
		if (props.communicationLength > 100 && !localStorage.getItem(COMMUNICATION_STORAGE)) {
			return '170px'
		}
		return '70px'
	}};
	padding-bottom: 10px;
	gap: 5px;
	border-bottom: 1px solid #2c2c2c;

	span {
		font-family: 'Noto Sans';
		font-size: 14px;
	}
`
