import { Col, Skeleton } from 'antd'
import React from 'react'

import { HeaderContainerSkeleton, TitleContainer } from './styles'

const DefaultHeroSkeleton = () => {
	return (
		<HeaderContainerSkeleton data-testid='header-container-skeleton'>
			<Col xxl={8} xl={8} lg={10} md={10} sm={18} xs={20}>
				<TitleContainer />
				<Skeleton active paragraph={{ rows: 3 }} title={{ style: { height: 25, width: 320 } }} />
				<Skeleton.Button active style={{ height: 40, width: 130, marginTop: 30, borderRadius: 8 }} />
			</Col>
		</HeaderContainerSkeleton>
	)
}

export default DefaultHeroSkeleton
