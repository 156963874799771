import { Row } from 'antd'
import styled from 'styled-components/macro'

export const Section = styled(Row)`
	padding: 60px 40px 20px 40px;
`
export const Space = styled(Row)`
	padding-left: 40px;
	padding-right: 40px;
	flex-flow: nowrap;
`

export const NewsWrapper = styled.div`
	padding-bottom: 40px;
`
