import { combineReducers } from 'redux'

import accountLimit from './accountLimit'
import apiKeys from './apiKeys'
import audit from './audit'
import balance from './balance'
import bfs from './bfs'
import collections from './collections'
import currency from './currency'
import deposits from './deposits'
import dust from './dust'
import favorites from './favorites'
import fills from './fills'
import keypairs from './keypairs'
import kyc from './kyc'
import language from './language'
import mfa from './mfa'
import networks from './networks'
import news from './news'
import nfts from './nfts'
import orders from './orders'
import passKeys from './passKeys'
import pools from './pools'
import route from './route'
import tokens from './tokens'
import trade from './trade'
import user from './user'
import userSettings from './userSettings'
import withdrawals from './withdrawals'

const reducers = combineReducers({
	keypairs,
	language,
	currency,
	orders,
	pools,
	tokens,
	deposits,
	dust,
	withdrawals,
	networks,
	balance,
	accountLimit,
	trade,
	fills,
	favorites,
	kyc,
	mfa,
	apiKeys,
	passKeys,
	nfts,
	news,
	route,
	user,
	userSettings,
	collections,
	audit,
	bfs,
})

export default reducers
