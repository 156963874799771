export const classicDraggableInitialGrid = [
	{ w: 6.97, h: 29.2, x: 0, y: 0, i: 'sidebar', minW: 6.97, moved: false, static: false },
	{ w: 34.03, h: 12.5, x: 6.98, y: 2, i: 'chart', minW: 15, minH: 10, moved: false, static: false },
	{ w: 34.03, h: 8.3, x: 6.98, y: 18, i: 'placeorder', minW: 30, minH: 8.3, moved: false, static: false },
	{ w: 34.03, h: 8.4, x: 6.98, y: 30, i: 'orders', minW: 30, minH: 8.4, moved: false, static: false },
	{ w: 6.97, h: 12.51, x: 41.8, y: 0, i: 'orderbook', minW: 6.97, minH: 7.5, moved: false, static: false },
	{ w: 6.97, h: 16.69, x: 41.8, y: 16, i: 'trades', minW: 6.97, minH: 7.5, moved: false, static: false },
]

export const advancedDraggableInitialGrid = [
	{ w: 32, h: 8.5, x: 0, y: 0, i: 'tradingview', minW: 30, minH: 8.5, moved: false, static: false },
	{ w: 8, h: 8.5, x: 32, y: 0, i: 'orderbook', minW: 8, minH: 8.5, moved: false, static: false },
	{ w: 32, h: 5.3, x: 0, y: 44, i: 'orders', minW: 32, minH: 5.3, moved: false, static: false },
	{ w: 8, h: 8.5, x: 52, y: 0, i: 'trades_market_depth', minW: 8, minH: 8.5, moved: false, static: false },
	{ w: 16, h: 5.2, x: 56, y: 80, i: 'placeorder', minW: 18, minH: 5.2, moved: false, static: false },
]
