import axios from 'axios'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import enUS from '../translate/en-US.json'

const ar = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/ar.json'
const de = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/de.json'
const es = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/es.json'
const fr = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/fr.json'
const he = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/he.json'
const hi = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/hi.json'
const id = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/id.json'
const it = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/it.json'
const ja = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/ja.json'
const ko = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/ko.json'
const nl = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/nl.json'
const pl = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/pl.json'
const ptBr = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/pt-br.json'
const ro = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/ro.json'
const ru = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/ru.json'
const sv = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/sv.json'
const tr = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/tr.json'
const zh = 'https://res.cloudinary.com/bitcoinme/raw/upload/translate/zh.json'

const useTranslateHook = () => {
	const selectedLang = JSON.parse(localStorage.getItem('language'))?.code || 'en-US'
	const [lang, setLang] = useState({})

	const fetchSelectedLang = useCallback(async () => {
		const results = {
			ar,
			de,
			es,
			fr,
			he,
			hi,
			id,
			it,
			ja,
			ko,
			nl,
			pl,
			'pt-br': ptBr,
			ro,
			ru,
			sv,
			tr,
			zh,
		}

		const response = await axios.get(results[selectedLang])

		if (selectedLang === 'pt-br') {
			return {
				'pt-BR': response?.data,
			}
		}

		return {
			[selectedLang]: response?.data,
		}
	}, [selectedLang])

	useEffect(() => {
		fetchSelectedLang()
			.then(data => setLang(data))
			.catch(() => {
				localStorage.setItem(
					'language',
					JSON.stringify({
						label: 'en',
						code: 'en',
						abbr: 'us',
						aLang: 'en_US',
						dir: 'ltr',
					})
				)
				setLang({ en: enUS })
			})
	}, [fetchSelectedLang])

	return !isEmpty(lang) ? lang : { en: enUS }
}

export default useTranslateHook
