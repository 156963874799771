import {
	getEstimatedQuoteAmount,
	getLiquidityPools,
	addLiquidityPool,
	getUserPortions,
	getClaimsHistory,
	getPoolHistory,
	getCalcAmount,
} from '../../services/pools'

export const Types = {
	FETCH_ESTIMATED_QUOTE_AMOUNT: 'pools/FETCH_ESTIMATED_QUOTE_AMOUNT',
	FETCH_CALC_AMOUNT: 'pools/FETCH_CALC_AMOUNT',
	FETCH_ADD_LIQUIDITY_POOL: 'pools/FETCH_ADD_LIQUIDITY_POOL',
	FETCH_LIQUIDITY_POOLS: 'pools/FETCH_LIQUIDITY_POOLS',
	FETCH_LIQUIDITY_POOL: 'pools/FETCH_LIQUIDITY_POOL',
	FETCH_PORTIONS_BY_USER: 'pools/FETCH_PORTIONS_BY_USER',
	FETCH_CLAIMS_HISTORY: 'pools/FETCH_CLAIMS_HISTORY',
	FETCH_POOL_HISTORY: 'pools/FETCH_POOL_HISTORY',
	LOADING_ESTIMATED_QUOTE_AMOUNT: 'pools/LOADING_ESTIMATED_QUOTE_AMOUNT',
	LOADING_CALC_AMOUNT: 'pools/LOADING_CALC_AMOUNT',
	LOADING_LIQUIDITY_POOLS: 'pools/LOADING_LIQUIDITY_POOLS',
	LOADING_LIQUIDITY_POOL: 'pools/LOADING_LIQUIDITY_POOL',
	LOADING_PORTIONS_BY_USER: 'pools/LOADING_PORTIONS_BY_USER',
	LOADING_ADD_LIQUIDITY_POOL: 'pools/LOADING_ADD_LIQUIDITY_POOL',
	LOADING_CLAIMS_HISTORY: 'pools/LOADING_CLAIMS_HISTORY',
	LOADING_POOL_HISTORY: 'pools/LOADING_POOL_HISTORY',
	FAILURE_ESTIMATED_QUOTE_AMOUNT: 'pools/FAILURE_ESTIMATED_QUOTE_AMOUNT',
	FAILURE_CALC_AMOUNT: 'pools/FAILURE_CALC_AMOUNT',
	FAILURE_LIQUIDITY_POOLS: 'pools/FAILURE_LIQUIDITY_POOLS',
	FAILURE_LIQUIDITY_POOL: 'pools/FAILURE_LIQUIDITY_POOL',
	FAILURE_PORTIONS_BY_USER: 'pools/FAILURE_PORTIONS_BY_USER',
	FAILURE_ADD_LIQUIDITY_POOL: 'pools/FAILURE_ADD_LIQUIDITY_POOL',
	FAILURE_CLAIMS_HISTORY: 'pools/FAILURE_CLAIMS_HISTORY',
	FAILURE_POOL_HISTORY: 'pools/FAILURE_POOL_HISTORY',
	SET_SELECTED_PAIR: 'pools/SET_SELECTED_PAIR',
	SET_SELECTED_ORDER_BY: 'pools/SET_SELECTED_ORDER_BY',
}

const initialState = {
	estimatedQuoteAmount: '',
	liquidityPools: [],
	liquidityPool: {},
	liquidityUserPortions: [],
	claimsHistory: {},
	poolHistory: {},
	loading_estimated_quote_amount: false,
	loading_liquidity_pools: false,
	loading_liquidity_pool: false,
	loading_portions_by_user: false,
	loading_add_liquidity_pool: false,
	loading_claims_history: false,
	loading_pool_history: false,
	failure_estimated_quote_amount: false,
	failure_liquidity_pools: false,
	failure_liquidity_pool: false,
	failure_portions_by_user: false,
	failure_add_liquidity_pool: false,
	failure_claims_history: false,
	failure_pool_history: false,
	selectedPairState: null,
	selectedOrderByState: null,
}

export const fetchEstimatedQuoteAmount = ({ baseAmount, baseTokenID, quoteTokenID }) => {
	return async dispatch => {
		if (!baseAmount || !baseTokenID || !quoteTokenID) return

		try {
			dispatch({
				type: Types.LOADING_ESTIMATED_QUOTE_AMOUNT,
				payload: true,
			})

			const data = await getEstimatedQuoteAmount({ baseAmount, baseTokenID, quoteTokenID })
			if (data) {
				dispatch({
					type: Types.FETCH_ESTIMATED_QUOTE_AMOUNT,
					payload: data,
				})
				return data
			} else {
				return {}
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_ESTIMATED_QUOTE_AMOUNT })
		}
	}
}

export const fetchCalcQuote = ({ baseAmount, liquidityPoolID }) => {
	return async dispatch => {
		if (!baseAmount || !liquidityPoolID) return

		try {
			dispatch({
				type: Types.LOADING_CALC_AMOUNT,
				payload: true,
			})

			const data = await getCalcAmount({ baseAmount, liquidityPoolID })
			if (data) {
				dispatch({
					type: Types.FETCH_CALC_AMOUNT,
					payload: data,
				})
				return data
			} else {
				return {}
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_CALC_AMOUNT })
		}
	}
}

export const fetchLiquidityPools = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_LIQUIDITY_POOLS,
				payload: true,
			})

			const data = await getLiquidityPools({})
			if (data?.error) {
				return dispatch({ type: Types.FAILURE_LIQUIDITY_POOLS, payload: true })
			}

			dispatch({
				type: Types.FETCH_LIQUIDITY_POOLS,
				payload: data,
			})

			dispatch({ type: Types.FAILURE_LIQUIDITY_POOLS, payload: false })
			return data
		} catch (error) {
			dispatch({ type: Types.FAILURE_LIQUIDITY_POOLS, payload: true })
		}
	}
}

export const fetchLiquidityPoolById = ({ id }) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_LIQUIDITY_POOL,
				payload: true,
			})

			const { liquidityPools, error } = await getLiquidityPools({ id })
			if (error) {
				return dispatch({ type: Types.FAILURE_LIQUIDITY_POOL, payload: true })
			}

			dispatch({
				type: Types.FETCH_LIQUIDITY_POOL,
				payload: liquidityPools?.[0],
			})

			dispatch({ type: Types.FAILURE_LIQUIDITY_POOL, payload: false })
			return liquidityPools?.[0]
		} catch (error) {
			dispatch({ type: Types.FAILURE_LIQUIDITY_POOL, payload: true })
		}
	}
}

export const fetchUserPortions = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_PORTIONS_BY_USER,
				payload: true,
			})

			const data = await getUserPortions()

			dispatch({
				type: Types.FETCH_PORTIONS_BY_USER,
				payload: data,
			})
			return data
		} catch (error) {
			dispatch({ type: Types.FAILURE_PORTIONS_BY_USER })
		}
	}
}

export const fetchAddLiquidityPool = ({ baseAmount, baseTokenID, liquidityPoolID, quoteAmount, quoteTokenID }) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_ADD_LIQUIDITY_POOL,
				payload: true,
			})

			const data = await addLiquidityPool({ baseAmount, baseTokenID, liquidityPoolID, quoteAmount, quoteTokenID })

			dispatch({
				type: Types.FETCH_ADD_LIQUIDITY_POOL,
			})

			return data
		} catch (error) {
			dispatch({ type: Types.FAILURE_ADD_LIQUIDITY_POOL })
		}
	}
}

export const fetchClaimsHistory = ({ Limit, Skip }) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_CLAIMS_HISTORY,
				payload: true,
			})

			const data = await getClaimsHistory({ Limit, Skip })

			dispatch({
				type: Types.FETCH_CLAIMS_HISTORY,
				payload: data,
			})

			return data
		} catch (error) {
			dispatch({ type: Types.FAILURE_CLAIMS_HISTORY })
		}
	}
}

export const claimsReducer = () => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING_LIQUIDITY_POOL,
			payload: false,
		})
		dispatch({
			type: Types.FAILURE_LIQUIDITY_POOL,
			payload: false,
		})
	}
}

export const fetchPoolHistory = ({ limit, skip, operation, status }) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING_POOL_HISTORY,
				payload: true,
			})

			const data = await getPoolHistory({ limit, skip, operation, status })

			dispatch({
				type: Types.FETCH_POOL_HISTORY,
				payload: data,
			})

			return data
		} catch (error) {
			dispatch({ type: Types.FAILURE_POOL_HISTORY })
		}
	}
}

export const setSelectedPairAction = selectedPair => {
	return async dispatch => {
		dispatch({
			type: Types.SET_SELECTED_PAIR,
			payload: selectedPair,
		})
	}
}

export const setSelectedOrderByAction = selectedOrderByState => {
	return async dispatch => {
		dispatch({
			type: Types.SET_SELECTED_ORDER_BY,
			payload: selectedOrderByState,
		})
	}
}

export const reducerObject = {
	'pools/LOADING_ESTIMATED_QUOTE_AMOUNT': (state, action) => {
		return { ...state, loading_estimated_quote_amount: action.payload }
	},
	'pools/LOADING_LIQUIDITY_POOLS': (state, action) => {
		return { ...state, loading_liquidity_pools: action.payload }
	},
	'pools/LOADING_LIQUIDITY_POOL': (state, action) => {
		return { ...state, loading_liquidity_pool: action.payload, failure_liquidity_pool: false }
	},
	'pools/LOADING_PORTIONS_BY_USER': (state, action) => {
		return { ...state, loading_portions_by_user: action.payload }
	},
	'pools/LOADING_ADD_LIQUIDITY_POOL': (state, action) => {
		return { ...state, loading_add_liquidity_pool: action.payload }
	},
	'pools/LOADING_CLAIMS_HISTORY': (state, action) => {
		return { ...state, loading_claims_history: action.payload }
	},
	'pools/LOADING_POOL_HISTORY': (state, action) => {
		return { ...state, loading_claims_history: action.payload }
	},
	'pools/FETCH_ESTIMATED_QUOTE_AMOUNT': (state, action) => {
		return { ...state, loading_estimated_quote_amount: false, estimatedQuoteAmount: action.payload }
	},
	'pools/FETCH_LIQUIDITY_POOLS': (state, action) => {
		return { ...state, loading_liquidity_pools: false, liquidityPools: action.payload }
	},
	'pools/FETCH_LIQUIDITY_POOL': (state, action) => {
		return { ...state, loading_liquidity_pool: false, failure_liquidity_pool: false, liquidityPool: action.payload }
	},
	'pools/FETCH_PORTIONS_BY_USER': (state, action) => {
		return { ...state, loading_portions_by_user: false, liquidityUserPortions: action.payload }
	},
	'pools/FETCH_ADD_LIQUIDITY_POOL': state => {
		return { ...state, loading_add_liquidity_pool: false }
	},
	'pools/FETCH_CLAIMS_HISTORY': (state, action) => {
		return { ...state, claimsHistory: action.payload }
	},
	'pools/FETCH_POOL_HISTORY': (state, action) => {
		return { ...state, poolHistory: action.payload }
	},
	'pools/FAILURE_ESTIMATED_QUOTE_AMOUNT': state => {
		return { ...state, loading_estimated_quote_amount: false, failure_estimated_quote_amount: true }
	},
	'pools/FAILURE_LIQUIDITY_POOLS': (state, action) => {
		return { ...state, loading_liquidity_pools: false, failure_liquidity_pools: action.payload }
	},
	'pools/FAILURE_LIQUIDITY_POOL': (state, action) => {
		return { ...state, loading_liquidity_pool: false, failure_liquidity_pool: action.payload }
	},
	'pools/FAILURE_PORTIONS_BY_USER': state => {
		return { ...state, loading_portions_by_user: false, failure_portions_by_user: true }
	},
	'pools/FAILURE_ADD_LIQUIDITY_POOL': state => {
		return { ...state, loading_add_liquidity_pool: false, failure_add_liquidity_pool: true }
	},
	'pools/FAILURE_CLAIMS_HISTORY': state => {
		return { ...state, loading_claims_history: false, failure_claims_history: true }
	},
	'pools/FAILURE_POOL_HISTORY': state => {
		return { ...state, loading_pool_history: false, failure_pool_history: true }
	},
	'pools/SET_SELECTED_PAIR': (state, action) => {
		return { ...state, selectedPairState: action.payload }
	},
	'pools/SET_SELECTED_ORDER_BY': (state, action) => {
		return { ...state, selectedOrderByState: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
