import { sleep } from '@lifeomic/attempt'
import isEmpty from 'lodash/isEmpty'

import {
	fetchCollections,
	getCollectionsRanking,
	fetchCollectionsId,
	getNftOrdersByAnUser,
	fetchNfts,
	fetchNftOrders,
	fetchNftsLocked,
	getOffersReceived,
	getUserOffers,
} from '../../services/nft'

export const Types = {
	FETCH_MY_NFTS_LIST_IN_MARKETEPLACE: 'nfts/MY_NFTS_LIST_IN_MARKETEPLACE',
	FETCH_NFTS: 'nfts/FETCH_NFTS',
	FETCH_NFT_ORDERS: 'nfts/FETCH_NFT_ORDERS',
	FETCH_NFT_OFFERS: 'nfts/FETCH_NFT_OFFERS',
	FETCH_COLLECTIONS: 'nfts/FETCH_COLLECTIONS',
	FETCH_COLLECTION_DETAILS: 'nfts/FETCH_COLLECTION_DETAILS',
	FETCH_COLLECTIONS_RANKINGS: 'nfts/FETCH_COLLECTIONS_RANKINGS',
	FAILURE_FETCH_NFTS: 'nfts/FAILURE_FETCH_NFTS',
	FAILURE_FETCH_COLLECTIONS: 'nfts/FAILURE_FETCH_COLLECTIONS',
	FAILURE_COLLECTION_DETAILS: 'nfts/FAILURE_COLLECTION_DETAILS',
	FAILURE_FETCH_COLLECTIONS_RANKINGS: 'nfts/FAILURE_FETCH_COLLECTIONS_RANKINGS',
	FAILURE_FETCH_NFT_ORDERS: 'nfts/FAILURE_FETCH_NFT_ORDERS',
	FAILURE_FETCH_NFT_OFFERS: 'nfts/FAILURE_FETCH_NFT_OFFERS',
	FAILURE_MY_NFTS_IN_MARKETEPLACE: 'nfts/FAILURE_MY_NFTS_IN_MARKETEPLACE',
	NOT_FOUND_MY_NFTS_IN_MARKETEPLACE: 'nfts/NOT_FOUND_MY_NFTS_IN_MARKETEPLACE',
	LOADING_MY_NFTS_IN_MARKETEPLACE: 'nfts/LOADING_MY_NFTS_IN_MARKETEPLACE',
	MY_NFTS_IN_MARKETEPLACE_STATUS: 'nfts/MY_NFTS_IN_MARKETEPLACE_STATUS',
	MY_NFTS_IN_MARKETEPLACE_FILTER: 'nfts/MY_NFTS_IN_MARKETEPLACE_FILTER',
	MY_NFTS_IN_MARKETEPLACE_LIMIT: 'nfts/MY_NFTS_IN_MARKETEPLACE_LIMIT',
	MY_NFTS_IN_MARKETEPLACE_PAGE: 'nfts/MY_NFTS_IN_MARKETEPLACE_PAGE',
	MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS: 'nfts/MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS',
	MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES: 'nfts/MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES',
	MY_NFTS_IN_MARKETEPLACE_METADATA_FILTERS: 'nfts/MY_NFTS_IN_MARKETEPLACE_METADATA_FILTERS',
	MY_NFTS_IN_MARKETEPLACE_GENE_METADATA_FILTER: 'nfts/MY_NFTS_IN_MARKETEPLACE_GENE_METADATA_FILTER',
	FAILED_USER_CREATED_NFT_OFFER: 'nfts/FAILED_USER_CREATED_NFT_OFFER',
	USER_PAGINATION: 'nfts/USER_PAGINATION',
	TOGGLE_IS_BFS: 'nfts/TOGGLE_IS_BFS',
}

const initialState = {
	nftsList: [],
	nftOrders: [],
	nftOffers: [],
	collections: [],
	collectionDetails: {},
	collectionsRankings: [],
	failedFetchCollections: false,
	failedFetchNFTList: false,
	failedFetchUserCreatedNftOffers: '',
	nftOrderList: [],
	failed: false,
	failedFetchCollectionDetails: false,
	failedFetchCollectionsRankings: false,
	failedFetchNftOrders: false,
	failedFetchNftOffers: false,
	myNftsInMarketplaceStatus: '',
	myNftsInMarketplaceFilter: 'ALL',
	myNftsInMarketplaceLimit: 20,
	myNftsInMarketplacePage: 1,
	myNftsInMarketplaceTotalItems: 0,
	myNftsInMarketplaceTotalPages: 0,
	myNftsListInMarketplace: [],
	myNftsInMarketplaceMetadataFilters: [],
	myNftsInMarketplaceGeneMetadataFilter: [],
	loadingMyNftsListInMarketplace: false,
	failedMyNftsListInMarketplace: false,
	notFoundMyNftsInMarketplace: true,
	paginationInfos: {},
	isBfsReducer: false,
}

export const fetchMyNftsInMarketplace = filters => {
	return async (dispatch, getStore) => {
		dispatch({
			type: Types.LOADING_MY_NFTS_IN_MARKETEPLACE,
			payload: true,
		})
		dispatch({
			type: Types.FETCH_MY_NFTS_LIST_IN_MARKETEPLACE,
			payload: [],
		})
		const { nfts: nftsStore, collections: collectionsReducer } = getStore()
		const { filter, limit, status, collectionId, clearMetadataFilter, skip: skipFilter } = filters
		const {
			myNftsInMarketplaceStatus,
			myNftsInMarketplaceFilter,
			myNftsInMarketplaceLimit,
			myNftsInMarketplacePage,
			myNftsInMarketplaceMetadataFilters,
			myNftsInMarketplaceGeneMetadataFilter,
		} = nftsStore

		const { selectedCollection } = collectionsReducer
		const changedLimitOrFilter =
			(filter && filter !== myNftsInMarketplaceFilter) || limit !== myNftsInMarketplaceLimit

		if (changedLimitOrFilter) {
			dispatch(setMyNftsInMarketplaceLimitReducer(limit || myNftsInMarketplaceLimit))
			dispatch(setMyNftsInMarketplacePageReducer(1))
			dispatch(setMyNftsInMarketplaceFilterReducer(filter || myNftsInMarketplaceFilter))
		}
		const skip = changedLimitOrFilter
			? 0
			: skipFilter || myNftsInMarketplacePage * myNftsInMarketplaceLimit - myNftsInMarketplaceLimit

		const nftsFilters = {
			collectionId: collectionId || selectedCollection,
			status: status || myNftsInMarketplaceStatus,
			paginationInfos: {
				limit: limit || myNftsInMarketplaceLimit,
				skip,
			},
		}
		let nftsArray = []
		let PaginationInfos = {}
		let Error = null

		const NftsLocked = filter || myNftsInMarketplaceFilter

		if (NftsLocked && NftsLocked !== 'ALL') {
			dispatch(setMyNftsInMarketplaceMetadataFiltersReducer([]))

			const { nfts, paginationInfos, error } = await fetchNftsLocked({
				...nftsFilters,
				listFilter: NftsLocked,
			})

			PaginationInfos = paginationInfos
			if (nfts) {
				nftsArray = nfts.map(nfts => {
					return { ...nfts.nfts, order: nfts.order }
				})
			}

			if (error) {
				Error = error
			}
		}

		const indexGeneSelected = myNftsInMarketplaceMetadataFilters.findIndex(filter => filter?.field === 'Gene')

		if (!clearMetadataFilter && !isEmpty(myNftsInMarketplaceMetadataFilters)) {
			const removeGeneOnArray = myNftsInMarketplaceMetadataFilters.filter(filter => filter?.field !== 'Gene')
			nftsFilters.filters =
				indexGeneSelected > -1
					? [...removeGeneOnArray, myNftsInMarketplaceGeneMetadataFilter]
					: removeGeneOnArray
		}

		if (nftsFilters?.collectionId > 0) {
			if (NftsLocked === 'ALL') {
				const { nfts, error, paginationInfos } = await fetchNfts(nftsFilters)

				for (let index = 0; index < nfts?.length; index++) {
					const nft = nfts[index]
					if (nft?.states?.inOrder) {
						const { order } = await fetchNftOrders({ nftId: nft.id, status: 'OPEN' })
						if (order) nft.order = order?.[0]
					}
				}
				PaginationInfos = paginationInfos
				if (nfts) {
					nftsArray = nfts
				}
				if (error) {
					Error = error
				}
			}

			if (
				Error?.includes('user does not have any NFT') ||
				Error?.includes('nfts not found') ||
				(NftsLocked === 'IN_WITHDRAW' && isEmpty(PaginationInfos) && !Error)
			) {
				dispatch({
					type: Types.NOT_FOUND_MY_NFTS_IN_MARKETEPLACE,
					payload: true,
				})
				dispatch({
					type: Types.FAILURE_MY_NFTS_IN_MARKETEPLACE,
					payload: false,
				})
				dispatch({
					type: Types.FETCH_MY_NFTS_LIST_IN_MARKETEPLACE,
					payload: [],
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS,
					payload: 0,
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES,
					payload: 0,
				})
			} else if (Error || (NftsLocked === 'IN_WITHDRAW' && !isEmpty(PaginationInfos))) {
				dispatch({
					type: Types.FAILURE_MY_NFTS_IN_MARKETEPLACE,
					payload: true,
				})
				dispatch({
					type: Types.NOT_FOUND_MY_NFTS_IN_MARKETEPLACE,
					payload: false,
				})
				dispatch({
					type: Types.FETCH_MY_NFTS_LIST_IN_MARKETEPLACE,
					payload: [],
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS,
					payload: 0,
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES,
					payload: 0,
				})
			}

			if (nftsArray && PaginationInfos) {
				dispatch({
					type: Types.FETCH_MY_NFTS_LIST_IN_MARKETEPLACE,
					payload: nftsArray,
				})
				dispatch({
					type: Types.FAILURE_MY_NFTS_IN_MARKETEPLACE,
					payload: false,
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS,
					payload: PaginationInfos?.totalItems,
				})
				dispatch({
					type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES,
					payload: PaginationInfos?.totalPages,
				})
			}
		}
	}
}

export const setMyNftsInMarketplaceStatusReducer = status => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_STATUS,
			payload: status,
		})
	}
}

export const setMyNftsInMarketplaceFilterReducer = filter => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_FILTER,
			payload: filter,
		})
	}
}

export const setMyNftsInMarketplaceLimitReducer = limit => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_LIMIT,
			payload: limit,
		})
	}
}

export const setMyNftsInMarketplacePageReducer = page => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_PAGE,
			payload: page,
		})
	}
}

export const setMyNftsInMarketplaceTotalItemsReducer = total => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS,
			payload: total,
		})
	}
}

export const setMyNftsInMarketplaceMetadataFiltersReducer = metadataFilter => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_METADATA_FILTERS,
			payload: metadataFilter,
		})
	}
}

export const setMyNftsInMarketplaceGeneMetadataFilterReducer = geneMetadataFilter => {
	return async dispatch => {
		dispatch({
			type: Types.MY_NFTS_IN_MARKETEPLACE_GENE_METADATA_FILTER,
			payload: geneMetadataFilter,
		})
	}
}

export const handlerSetFiltersReducer = (value, field) => {
	return async (dispatch, getStore) => {
		const { nfts: nftsStore } = getStore()
		const { myNftsInMarketplaceMetadataFilters } = nftsStore
		// multiple Filters
		const isSelected = myNftsInMarketplaceMetadataFilters?.findIndex(fil => fil?.field === field)
		const newFilters = myNftsInMarketplaceMetadataFilters
		if (isSelected >= 0) {
			newFilters.splice(isSelected, 1)
		}

		dispatch(setMyNftsInMarketplaceMetadataFiltersReducer([...newFilters, { field, value }]))
	}
}

export const fetchCollectionDetails = id => {
	return async dispatch => {
		const collectionDetails = await fetchCollectionsId(id)
		if (!collectionDetails?.error) {
			dispatch({
				type: Types.FETCH_COLLECTION_DETAILS,
				payload: collectionDetails,
			})
		} else {
			dispatch({ type: Types.FAILURE_COLLECTION_DETAILS })
		}
	}
}

export const fetchCollectionList = () => {
	return async dispatch => {
		const collections = await fetchCollections()
		if (!collections?.error) {
			dispatch({
				type: Types.FETCH_COLLECTIONS,
				payload: collections,
			})
		} else {
			dispatch({ type: Types.FAILURE_FETCH_COLLECTIONS })
		}
	}
}

export const fetchNftOrdersByAnUser = paginationInfos => {
	return async dispatch => {
		const history = await getNftOrdersByAnUser(paginationInfos)
		if (!history?.error) {
			dispatch({
				type: Types.FETCH_NFT_ORDERS,
				payload: history,
			})
		} else {
			dispatch({ type: Types.FAILURE_FETCH_NFT_ORDERS })
		}
	}
}

export const fetchOffersReceived = ({ limit, skip, status }) => {
	return async dispatch => {
		dispatch({
			type: Types.FETCH_NFT_OFFERS,
			payload: [],
		})
		const offers = await getOffersReceived({ limit, skip, status })
		if (!offers?.error) {
			dispatch({
				type: Types.FETCH_NFT_OFFERS,
				payload: offers,
			})
		} else {
			dispatch({ type: Types.FAILURE_FETCH_NFT_OFFERS })
		}
	}
}

export const fetchCreatedOffers = ({ limit, skip, status }) => {
	return async dispatch => {
		dispatch({
			type: Types.FETCH_NFT_OFFERS,
			payload: [],
		})
		await sleep(300)
		const userOffers = await getUserOffers({ limit, skip, status })
		if (userOffers.error) {
			return dispatch({ type: Types.FAILED_USER_CREATED_NFT_OFFER, payload: userOffers.error })
		}
		dispatch({
			type: Types.FETCH_NFT_OFFERS,
			payload: { offers: userOffers.offers },
		})
		dispatch({
			type: Types.USER_PAGINATION,
			payload: { paginationInfos: userOffers.paginationInfos },
		})
	}
}

export const fetchCollectionsRankings = () => {
	return async dispatch => {
		const collectionsRanking = await getCollectionsRanking()
		if (!collectionsRanking.error) {
			dispatch({
				type: Types.FETCH_COLLECTIONS_RANKINGS,
				payload: collectionsRanking,
			})
		} else {
			dispatch({ type: Types.FAILURE_FETCH_COLLECTIONS_RANKINGS })
		}
	}
}

export const toggleIsBfs = payload => {
	return async dispatch => {
		dispatch({
			type: Types.TOGGLE_IS_BFS,
			payload,
		})
	}
}

export const reducerObject = {
	'nfts/FETCH_NFTS': (state, action) => {
		return { ...state, nftsList: action.payload, failedFetchNFTList: false }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_PAGE': (state, action) => {
		return { ...state, myNftsInMarketplacePage: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_METADATA_FILTERS': (state, action) => {
		return { ...state, myNftsInMarketplaceMetadataFilters: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_GENE_METADATA_FILTER': (state, action) => {
		return { ...state, myNftsInMarketplaceGeneMetadataFilter: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_LIMIT': (state, action) => {
		return { ...state, myNftsInMarketplaceLimit: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_FILTER': (state, action) => {
		return { ...state, myNftsInMarketplaceFilter: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_STATUS': (state, action) => {
		return { ...state, myNftsInMarketplaceStatus: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_TOTAL_ITEMS': (state, action) => {
		return { ...state, myNftsInMarketplaceTotalItems: action.payload }
	},
	'nfts/MY_NFTS_IN_MARKETEPLACE_TOTAL_PAGES': (state, action) => {
		return { ...state, myNftsInMarketplaceTotalPages: action.payload }
	},
	'nfts/MY_NFTS_LIST_IN_MARKETEPLACE': (state, action) => {
		return { ...state, myNftsListInMarketplace: action.payload }
	},
	'nfts/FAILURE_MY_NFTS_IN_MARKETEPLACE': (state, action) => {
		return {
			...state,
			failedMyNftsListInMarketplace: action.payload,
			loadingMyNftsListInMarketplace: false,
		}
	},
	'nfts/NOT_FOUND_MY_NFTS_IN_MARKETEPLACE': (state, action) => {
		return {
			...state,
			notFoundMyNftsInMarketplace: action.payload,
			loadingMyNftsListInMarketplace: false,
			failedMyNftsListInMarketplace: false,
		}
	},
	'nfts/LOADING_MY_NFTS_IN_MARKETEPLACE': (state, action) => {
		return { ...state, loadingMyNftsListInMarketplace: action.payload }
	},
	'nfts/FETCH_NFT_ORDERS': (state, action) => {
		return { ...state, nftOrders: action.payload, failedFetchNftOrders: false }
	},
	'nfts/FETCH_NFT_OFFERS': (state, action) => {
		return { ...state, nftOffers: action.payload, failedFetchNftOffers: false }
	},
	'nfts/FETCH_COLLECTIONS': (state, action) => {
		return { ...state, collections: action.payload, failedFetchCollections: false }
	},
	'nfts/FETCH_COLLECTION_DETAILS': (state, action) => {
		return { ...state, collectionDetails: action.payload, failedFetchCollectionDetails: false }
	},
	'nfts/FETCH_COLLECTIONS_RANKINGS': (state, action) => {
		return { ...state, collectionsRankings: action.payload, failedFetchCollectionsRankings: false }
	},
	'nfts/FAILURE_FETCH_NFTS': state => {
		return { ...state, failedFetchNFTList: true }
	},
	'nfts/FAILURE_FETCH_NFT_ORDERS': state => {
		return { ...state, failedFetchNftOrders: true }
	},
	'nfts/FAILURE_FETCH_NFT_OFFERS': state => {
		return { ...state, failedFetchNftOffers: true }
	},
	'nfts/FAILURE_FETCH_COLLECTIONS': state => {
		return { ...state, failedFetchCollections: true }
	},
	'nfts/FAILURE_FETCH_COLLECTION_DETAILS': state => {
		return { ...state, failedFetchCollectionDetails: true }
	},
	'nfts/FAILURE_FETCH_COLLECTIONS_RANKINGS': state => {
		return { ...state, failedFetchCollectionsRankings: true }
	},
	'nfts/FAILED_USER_CREATED_NFT_OFFER': (state, action) => {
		return { ...state, failedFetchUserCreatedNftOffers: action.payload }
	},
	'nfts/USER_PAGINATION': (state, action) => {
		return { ...state, paginationInfos: action.payload }
	},
	'nfts/TOGGLE_IS_BFS': (state, action) => {
		return { ...state, isBfsReducer: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
