import { setUserCurrency, getUserCurrency } from '../../utils'

export const Types = {
	SET_CURRENCY: 'currency/SET_CURRENCY',
}

const initialState = getUserCurrency()

export const setCurrency = lang => {
	return dispatch => {
		setUserCurrency(JSON.stringify(lang))
		dispatch({
			type: Types.SET_CURRENCY,
			payload: lang,
		})
	}
}

export const reducerObject = {
	'currency/SET_CURRENCY': (state, action) => {
		return { ...state, currency: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
