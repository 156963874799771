import { EventsServiceClient } from './protoBuffers/events_grpc_web_pb.js'
import { MFAServiceClient } from './protoBuffers/mfa_grpc_web_pb.js'
import { NftServiceClient } from './protoBuffers/nft_grpc_web_pb.js'

const grpc = {}
grpc.web = require('grpc-web')

const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})

class GenericClient {
	constructor() {
		this.eventsService = new EventsServiceClient(process.env.REACT_APP_EVENTS_API)
		this.mfaService = new MFAServiceClient(process.env.REACT_APP_MFA_API)
		this.nftService = new NftServiceClient(process.env.REACT_APP_NFT_API)

		window?.location?.hostname === 'localhost' &&
			enableDevTools([this.eventsService, this.mfaService, this.nftService])
	}

	toPascalCase(unserialized) {
		if (typeof unserialized !== 'object') {
			return unserialized
		}

		const entries = Object.entries(unserialized).map(r => {
			let key = r[0]
			let value = r[1]
			key = key.replace(key[0], key[0].toUpperCase()) // Camel to Pascal
			key = key.replace(/Id$/g, 'ID') // Id to ID

			if (typeof value === 'object') {
				value = this.toPascalCase(value)
			}

			return [key, value]
		})

		return Object?.fromEntries?.(entries) || {}
	}

	serializeResponse(response) {
		const unserialized = response.toObject()
		return this.toPascalCase(unserialized)
	}
}

export default GenericClient
