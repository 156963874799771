import { fetchCollections } from '../../services/nft'

export const Types = {
	FETCH_COLLECTIONS: 'collections/FETCH_COLLECTIONS',
	LOADING_COLLECTIONS: 'collections/LOADING_COLLECTIONS',
	FAILURE_COLLECTIONS: 'collections/FAILURE_COLLECTIONS',
	SET_SELECT_COLLECTION: 'collections/SET_SELECT_COLLECTION',
}

const initialState = {
	collections: [],
	selectedCollection: 0,
	loadingCollections: [],
	failureCollections: [],
}

export const fetchCollectionsReducer = () => {
	return async dispatch => {
		const { collections, error } = await fetchCollections()
		dispatch({
			type: Types.LOADING_COLLECTIONS,
			payload: true,
		})
		if (error)
			dispatch({
				type: Types.FAILURE_COLLECTIONS,
				payload: true,
			})
		if (collections) {
			const collectionsSorted = collections?.sort((a, b) => a.id - b.id)
			const onlyDevikins = collectionsSorted?.filter(c => c?.collectionName === 'Devikins KDA')
			dispatch({
				type: Types.FETCH_COLLECTIONS,
				payload: onlyDevikins,
			})
			dispatch(setSelectedCollectionReducer(onlyDevikins?.[0]?.id))
		}
	}
}

export const setSelectedCollectionReducer = collection => {
	return async dispatch => {
		dispatch({
			type: Types.SET_SELECT_COLLECTION,
			payload: collection,
		})
	}
}

export const reducerObject = {
	'collections/FETCH_COLLECTIONS': (state, action) => {
		return { ...state, collections: action.payload, loadingCollections: false, failureCollections: false }
	},
	'collections/LOADING_COLLECTIONS': (state, action) => {
		return { ...state, loadingCollections: action.payload }
	},
	'collections/FAILURE_COLLECTIONS': (state, action) => {
		return { ...state, failureCollections: action.payload, loadingCollections: false }
	},
	'collections/SET_SELECT_COLLECTION': (state, action) => {
		return { ...state, selectedCollection: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
