export const images = {
	bitcoinmeLogo: 'https://res.cloudinary.com/bitcoinme/image/upload/images/bitcoinme-new_hv497k.png',
	bitcoinmeLogoLight:
		'https://res.cloudinary.com/bitcoinme/image/upload/images/bitcoinme-new-light_jzmruj_n5blg4.svg',
	filterIcon: 'https://res.cloudinary.com/bitcoinme/image/upload/images/filter_tcsoae_eey48o.svg',
	placeholderIcon: 'https://res.cloudinary.com/bitcoinme/image/upload/images/icon-placeholder_uctm9z_j3nkd2.svg',
	instantPriceIcon: 'https://res.cloudinary.com/bitcoinme/image/upload/images/instant-price_zpmy9u_msfqem.svg',
	kleverLogo: 'https://res.cloudinary.com/bitcoinme/image/upload/images/klever-logo_ommrxe_pwb0jx.svg',
	userOrderIndicator:
		'https://res.cloudinary.com/bitcoinme/image/upload/v1694012246/images/user-order-indicator_nbhrhj_aguk40.svg',
}
