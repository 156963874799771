import { Row, Button } from 'antd'
import styled from 'styled-components/macro'

import { COMMUNICATION_STORAGE } from '../../../constants'

export const HeaderContainer = styled(Row)`
	padding: 0 22px 0 42px;
	justify-content: space-between;
	min-height: 480px;

	@media (max-width: 780px) {
		padding: 0;
	}

	@media (max-width: 572px) {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
`

export const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1345px) {
		flex-direction: column;
		height: 100%;
	}
`

export const HeaderContainerSkeleton = styled(Row)`
	padding: 60px 40px 20px 40px;
	justify-content: space-between;
	min-height: 480px;
`
export const TitleContainer = styled.div`
	width: 68%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	margin-right: 20px;
	margin-top: 20px;

	@media (max-width: 1345px) {
		width: 100%;
		text-align: center;
		margin-right: 0;
		margin-top: 20px;
	}

	button {
		margin-top: 30px;
	}
`
export const MobileTitleContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	margin-right: 0;
	margin-top: ${props =>
		props.communicationLength > 100 && !localStorage.getItem(COMMUNICATION_STORAGE) ? '60px' : '20px'};
	text-align: left;

	h1,
	h2 {
		margin-right: 20px;
		margin-left: 20px;
	}

	button {
		margin-top: 30px;
		margin-left: 20px;
	}
`

export const BackgroundImage = styled.div`
	width: 620px;

	&::before {
		content: '';
		position: absolute;
		top: ${props =>
			props.communicationLength > 100 && !localStorage.getItem(COMMUNICATION_STORAGE) ? '50px' : '0px'};
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.5;
		background: url('https://res.cloudinary.com/bitcoinme/image/upload/images/mobile-bitcoinme-hero_iwl0wr.webp')
			no-repeat no-repeat;
		background-size: 100%;

		@media screen and (min-width: 440px) {
			width: 100%;
			height: 350px;
		}

		@media screen and (max-width: 459px) {
			height: 400px;
			background-size: 450px;
		}
	}

	@media screen and (max-width: 440px) {
		width: 100%;
		background-size: 96.5%;
	}
`

export const HomeTitle = styled.h1`
	width: 100%;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700 !important;
	font-size: 46px !important;
	line-height: 68px !important;
	color: #ffffff !important;

	strong {
		color: ${props => props.theme.tradeColors.primary};
	}

	@media screen and (max-width: 1345px) {
		margin-top: 36px;
		width: 100%;
	}

	@media screen and (max-width: 920px) {
		font-size: 48px !important;
	}

	@media screen and (max-width: 360px) {
		font-size: 32px !important;
		line-height: 42px;
	}
`

export const MobileHomeTitle = styled.h1`
	width: 305px;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700 !important;
	font-size: 32px !important;
	line-height: 48px !important;
	color: #ffffff !important;

	strong {
		color: ${props => props.theme.tradeColors.primary};
	}

	@media screen and (min-width: 460px) {
		width: 380px;
	}

	@media screen and (max-width: 380px) {
		font-size: 28px !important;
		line-height: 36px !important;
	}
	@media screen and (max-width: 320px) {
		font-size: 22px !important;
		line-height: 32px !important;
	}
`

export const MobileHomeSubTitle = styled.h2`
	width: 250px;
	margin-top: 20px;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff !important;

	strong {
		color: ${props => props.theme.tradeColors.primary};
	}
`

export const TradeButton = styled(Button)`
	height: 39px;
	background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
	border: none;
	border-radius: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	padding: 0 20px;

	&:hover {
		background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
		color: #ffffff;
		opacity: 0.8;
	}

	@media (max-width: 572px) {
		margin: 30px auto;
	}
`

export const MobileTradeButton = styled(Button)`
	height: 44px;
	background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
	border: none;
	border-radius: 50px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	padding: 14px, 16px, 14px, 16px;

	&:hover {
		background: linear-gradient(90deg, #ff6700 0%, #ff0000 100%);
		color: #ffffff;
		opacity: 0.8;
	}

	@media (max-width: 572px) {
		margin: 30px auto;
	}
`

export const FeaturesWrapper = styled.div`
	position: relative;
	width: 78%;
	margin-bottom: 34px;

	@media (max-width: 1345px) {
		margin-top: 20px;
		width: 100%;
	}

	@media (max-width: 572px) {
		margin: 0;
	}
`

export const LPImageBanner = styled.img`
	margin-top: ${props => (props.isFromMobile === 'true' ? '0' : '30px')};
	border-radius: 8px;
	margin-left: 0px;
`
