import isEmpty from 'lodash/isEmpty'

import EventsClient from '../../services/grpcweb/EventsClient'
import { getFills } from '../../services/manager'
import { secondsToDateTime } from '../../utils'

export const Types = {
	FETCH_FILLS: 'fills/FETCH_FILLS',
	LOADING_FILLS: 'fills/LOADING_FILLS',
	SET_LAST_EVENT_ID: 'fills/SET_LAST_EVENT_ID',
	SET_FILLS_STREAM: 'fills/SET_FILLS_STREAM',
	SET_FILLS: 'fills/SET_FILLS',
	FAILURE: 'fills/FAILURE',
}

const initialState = {
	fillsList: [],
	fillStream: {},
	lastEventId: null,
	failed: false,
	loading: false,
}

export const fetchFills = keypairsID => {
	return async (dispatch, getStore) => {
		dispatch({
			type: Types.LOADING_FILLS,
			payload: {
				loading: true,
			},
		})
		const { Fills, error } = await getFills(keypairsID)
		if (error) {
			dispatch({
				type: Types.FAILURE,
			})
		}
		if (!error && !Fills) {
			dispatch({
				type: Types.LOADING_FILLS,
				payload: {
					loading: false,
				},
			})
		}
		if (Fills) {
			const { fills, keypairs } = getStore()

			if (keypairsID === keypairs?.selectedKeypair?.ID) {
				if (isEmpty(fills.fillStream)) dispatch(setStreamFills(keypairsID))

				dispatch({
					type: Types.FETCH_FILLS,
					payload: Fills,
				})
			}
		}
	}
}

export const setStreamFills = () => {
	return async dispatch => {
		try {
			const streamFills = new EventsClient()
			dispatch({
				payload: streamFills,
				type: Types.SET_FILLS_STREAM,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearStreamFills = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_FILLS_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearFills = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.SET_FILLS,
				payload: [],
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const updateFills = (event, keypairID) => {
	return (dispatch, getStore) => {
		try {
			const { fills, keypairs } = getStore()
			const { lastEventId } = fills

			dispatch({
				type: Types.SET_LAST_EVENT_ID,
				payload: event?.ID,
			})

			if (lastEventId !== event.ID && event.ID && keypairID === keypairs?.selectedKeypair?.ID) {
				const newFillList = []
				event.CreatedAt = secondsToDateTime(event.CreatedAt.Seconds)
				event.UpdatedAt = secondsToDateTime(event.UpdatedAt.Seconds)
				event.Side = event.Side > 0 || event.Side === 'SELL' ? 'SELL' : 'BUY'
				event.Price = event?.MatchPrice
				newFillList.push(event, ...fills.fillsList)
				dispatch({
					type: Types.SET_FILLS,
					payload: newFillList,
				})
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'fills/FETCH_FILLS': (state, action) => {
		return { ...state, fillsList: action.payload, failed: false, loading: false }
	},
	'fills/LOADING_FILLS': (state, action) => {
		return { ...state, ...action.payload }
	},
	'fills/SET_LAST_EVENT_ID': (state, action) => {
		return { ...state, lastEventId: action.payload }
	},
	'fills/SET_FILLS_STREAM': (state, action) => {
		return { ...state, fillStream: action.payload }
	},
	'fills/SET_FILLS': (state, action) => {
		return { ...state, fillsList: action.payload }
	},
	'fills/FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
