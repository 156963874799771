import { getUserConversion, getEstimatedDust, getConvertDustHistory } from '../../services/manager'

export const Types = {
	GET_ESTIMATED_DUST: 'dust/GET_ESTIMATED_DUST',
	GET_DUST_HISTORY: 'dust/GET_DUST_HISTORY',
	GET_CONVERSION_TIMES: 'dust/GET_CONVERSION_TIMES',
	FAILURE_GET_ESTIMATED_DUST: 'dust/FAILURE_GET_ESTIMATED_DUST',
	FAILURE_GET_DUST_HISTORY: 'dust/FAILURE_GET_DUST_HISTORY',
	FAILURE_GET_CONVERSION_TIMES: 'dust/FAILURE_GET_CONVERSION_TIMES',
}

const initialState = {
	estimatedDust: [],
	dustHistory: [],
	conversionTimes: {},
	failed_estimated_dust: false,
	failed_dust_history: false,
	failed_get_conversion_times: false,
}

export const fetchEstimatedDust = currency => {
	return async dispatch => {
		try {
			const estimatedDust = await getEstimatedDust(currency)
			dispatch({
				type: Types.GET_ESTIMATED_DUST,
				payload: estimatedDust,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_GET_ESTIMATED_DUST })
		}
	}
}

export const fetchDustHistory = ({ skip, limit, status }) => {
	return async dispatch => {
		try {
			const dustHistory = await getConvertDustHistory({ skip, limit, status })
			dispatch({
				type: Types.GET_DUST_HISTORY,
				payload: dustHistory,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_GET_DUST_HISTORY })
		}
	}
}

export const fetchUserConversionTimes = () => {
	return async dispatch => {
		try {
			const conversionTimes = await getUserConversion()
			dispatch({
				type: Types.GET_CONVERSION_TIMES,
				payload: conversionTimes,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_GET_CONVERSION_TIMES })
		}
	}
}

export const reducerObject = {
	'dust/GET_ESTIMATED_DUST': (state, action) => {
		return { ...state, estimatedDust: action.payload, failed_estimated_dust: false }
	},
	'dust/GET_DUST_HISTORY': (state, action) => {
		return { ...state, dustHistory: action.payload, failed_dust_history: false }
	},
	'dust/GET_CONVERSION_TIMES': (state, action) => {
		return { ...state, conversionTimes: action.payload, failed_get_conversion_times: false }
	},
	'dust/FAILURE_GET_ESTIMATED_DUST': state => {
		return { ...state, failed_estimated_dust: true }
	},
	'dust/FAILURE_GET_DUST_HISTORY': state => {
		return { ...state, failed_dust_history: true }
	},
	'dust/FAILURE_GET_CONVERSION_TIMES': state => {
		return { ...state, failed_get_conversion_times: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
