import { Row } from 'antd'
import React, { useCallback } from 'react'

import { images } from '../../../utils/images'
import * as S from './styles'

const HeaderMenuSkeleton = () => {
	const MenuItem = useCallback(
		({ width = 120 }) => <S.MenuItemSkeleton paragraph={{ rows: 1, width }} title={false} active />,
		[]
	)

	return (
		<S.HeaderSkeletonContainer>
			<Row justify='space-between'>
				<Row align='middle'>
					<img loading='lazy' className='klever-logo' src={images.bitcoinmeLogo} />
					<MenuItem width={110} />
					<MenuItem />
					<MenuItem width={80} />
				</Row>
				<Row align='middle'>
					<MenuItem width={200} />
					<MenuItem />
					<MenuItem width={60} />
				</Row>
			</Row>
		</S.HeaderSkeletonContainer>
	)
}

export default HeaderMenuSkeleton
