export const darkTheme = {
	name: 'dark',
	tradeColors: {
		primary: '#d96200',
		background: '#1e1e26',
		secondary: '#2c2c2c',
		font: '#ffffff',
		fontContrast: '#a5a5a5',
		modalBackground: '#242424',
		red: '#f84960',
		green: '#00cc66',
		yellow: '#f4c039',
		lightGray: '#b7bdc6',
		backgroundWithOpacity: 'rgba(30, 30, 38, 0.4)',
		noData: 'hsla(0,0%,100%,.3)',
	},
	colors: {
		primary: '#d96200',
		primaryContrast: '#ff800820',
		background: '#1e1e26',
		backgroundContrast: '#5c5c5c',
		secondary: '#2c2c2c',
		tertiary: '#242424',
		font: '#ffffff',
		red: '#f84960',
		green: '#00cc66',
		fontContrast: '#a5a5a5',
		description: '#909090',
	},
}

export const lightTheme = {
	name: 'light',
	tradeColors: {
		primary: '#d96200',
		background: '#FaFaFa',
		secondary: '#ffffff',
		font: '#1e1e26',
		fontContrast: '#a5a5a5',
		modalBackground: '#787878',
		red: '#f84960',
		green: '#00cc66',
		yellow: '#f4c039',
		lightGray: '#B7BDC6',
		backgroundWithOpacity: 'rgba(30, 30, 38, 0.4)',
		noData: 'hsla(0,0%,100%,.3)',
	},
	colors: {
		primary: '#d96200',
		primaryContrast: '#ff800820',
		background: '#1e1e26',
		backgroundContrast: '#5c5c5c',
		secondary: '#2c2c2c',
		tertiary: '#242424',
		red: '#f84960',
		green: '#00cc66',
		font: '#ffffff',
		fontContrast: '#a5a5a5',
		description: '#909090',
	},
}
