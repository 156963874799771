import isEmpty from 'lodash/isEmpty'

import { getPassKeys, createPassKey, deletePassKey } from '../../services/manager'

export const Types = {
	LOADING: 'passKeys/LOADING',
	SET_PASS_KEYS: 'passKeys/SET_PASS_KEYS',
	SET_CREATED_PASS_KEYS: 'passKeys/SET_CREATED_PASS_KEYS',
	FAILURE_PASS_KEYS: 'passKeys/FAILURE_PASS_KEYS',
	FAILURE_CREATED: 'passKeys/FAILURE_CREATED',
	FAILURE_DELETED: 'passKeys/FAILURE_DELETED',
	FAILURE_CLEARED: 'passKeys/FAILURE_CLEARED',
}

const initialState = {
	loading: false,
	passKeys: [],
	createdPassKeys: null,
	failedPassKey: false,
	failedCreated: false,
	failedDeleted: false,
	failedCleared: false,
}

export const fetchPassKeys = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			const keys = await getPassKeys()

			dispatch({
				type: Types.SET_PASS_KEYS,
				payload: keys,
			})

			if (!isEmpty(keys)) {
				dispatch({
					type: Types.LOADING,
					payload: { loading: false },
				})
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE_PASS_KEYS })
		} finally {
			dispatch({
				type: Types.LOADING,
				payload: { loading: false },
			})
		}
	}
}

export const fetchCreatePassKey = () => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING,
			payload: { loading: true },
		})

		try {
			await createPassKey()

			dispatch({
				type: Types.SET_CREATED_PASS_KEYS,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE_CREATED })
		}
	}
}

export const fetchDeletePassKey = credentialId => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			await deletePassKey(credentialId)

			dispatch(fetchPassKeys())
		} catch (error) {
			dispatch({ type: Types.FAILURE_DELETED })
			throw new Error(error)
		} finally {
			dispatch({
				type: Types.LOADING,
				payload: { loading: false },
			})
		}
	}
}

export const reducerObject = {
	'passKeys/LOADING': (state, action) => {
		return { ...state, ...action.payload }
	},
	'passKeys/SET_PASS_KEYS': (state, action) => {
		return { ...state, passKeys: action.payload, loading: false }
	},
	'passKeys/SET_CREATED_PASS_KEYS': state => {
		return { ...state, loading: false }
	},
	'passKeys/FAILURE_PASS_KEYS': state => {
		return { ...state, failedPassKey: true, loading: false }
	},
	'passKeys/FAILURE_CREATED': state => {
		return { ...state, failedCreated: true, loading: false }
	},
	'passKeys/FAILURE_DELETED': state => {
		return { ...state, failedDeleted: true, loading: false }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
