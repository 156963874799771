import moment from 'moment'

import { fetchKycStatus, initiateKYC, updateKYC } from '../../services/manager'

export const Types = {
	LOADING: 'kyc/LOADING',
	FAILURE: 'kyc/FAILURE',
	SET_STATUS: 'kyc/SET_STATUS',
	SET_ACTIONS: 'kyc/SET_ACTIONS',
}

const initialState = {
	loading: false,
	status: null,
	actions: null,
}

export const fetchStatus = () => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING,
			payload: { loading: true },
		})

		const { StatusKYC, error } = await fetchKycStatus()
		if (error) {
			dispatch({ type: Types.FAILURE })
		}
		if (StatusKYC) {
			dispatch({
				type: Types.SET_STATUS,
				payload: StatusKYC,
			})

			if (
				!StatusKYC?.RedirectURL ||
				StatusKYC?.Status === 'KYC_ERROR' ||
				StatusKYC?.Status === 'KYC_UNKNOWN' ||
				StatusKYC?.Status === 'KYC_NEED_ID_UPLOAD'
			) {
				if (StatusKYC?.Status === 'KYC_NEED_ID_UPLOAD') {
					const init = moment(new Date(StatusKYC?.UpdatedAt))
					const now = moment(new Date())

					const diff = now.diff(init, 'minutes')

					if (diff > 30) {
						dispatch(initKYC())
					} else {
						dispatch({
							type: Types.SET_ACTIONS,
							payload: {
								KycURL: StatusKYC?.RedirectURL,
								ScanReference: StatusKYC?.ScanReference,
							},
						})
					}
				} else {
					dispatch(initKYC())
				}
			} else {
				dispatch({
					type: Types.SET_ACTIONS,
					payload: {
						KycURL: StatusKYC?.RedirectURL,
						ScanReference: StatusKYC?.ScanReference,
					},
				})
			}
		}
	}
}

export const initKYC = () => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING,
			payload: { loading: true },
		})

		const response = await initiateKYC()

		response?.error
			? dispatch({ type: Types.FAILURE })
			: dispatch({
					type: Types.SET_ACTIONS,
					payload: response,
			  })
	}
}

export const updateStatus = data => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: { loading: true },
			})

			await updateKYC(data)

			dispatch(fetchStatus())
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'kyc/LOADING': (state, action) => {
		return { ...state, ...action.payload }
	},
	'kyc/SET_STATUS': (state, action) => {
		return { ...state, status: action.payload }
	},
	'kyc/SET_ACTIONS': (state, action) => {
		return { ...state, actions: action.payload }
	},
	'kyc/FAILURE': state => {
		return { ...state, failed: true, loading: false }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
