import { STORAGE_TOKENS } from '../../constants'
import GenericClient from './GenericClient.js'
import {
	ConnectFills,
	ConnectOrders,
	ConnectOrderGroups,
	ConnectStreamTicker,
	ConnectBalances,
	ConnectGetKeypairs,
	ConnectOrderbookStatus,
	ConnectStopOrders,
} from './protoBuffers/events_pb.js'
class EventsClient extends GenericClient {
	constructor() {
		super()
		this.busy = false
	}

	// Subscription Controllers

	subscribeOrders(keypairId = 0, metadata = {}) {
		const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token

		if (TOKEN) {
			metadata.AuthToken = TOKEN

			const request = new ConnectOrders()
			request.setKeypairId(keypairId)

			this.stream = this.eventsService.subscribeOrders(request, metadata)
			this.busy = true
		}
	}

	subscribeOrderGroups(keypairId = 0, decimals = 6, limit = 70, metadata = {}) {
		const request = new ConnectOrderGroups()
		request.setKeypairId(keypairId)
		request.setDecimals(decimals)
		request.setLimit(limit)

		this.stream = this.eventsService.subscribeOrderGroups(request, metadata)
		this.busy = true
	}

	subscribeStopOrders(keypairId = 0, metadata = { ClientOrigin: 3 }) {
		const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token

		if (TOKEN) {
			metadata.AuthToken = TOKEN

			const request = new ConnectStopOrders()
			request.setKeypairId(keypairId)

			this.stream = this.eventsService.subscribeStopOrders(request, metadata)
			this.busy = true
		}
	}

	async subscribeBalances(tokenId, metadata = { ClientOrigin: 3 }) {
		const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token

		if (TOKEN) {
			metadata.AuthToken = TOKEN

			const request = new ConnectBalances()
			if (tokenId) {
				request.setTokenId(tokenId)
			}

			this.stream = this.eventsService.subscribeBalances(request, metadata)
			this.busy = true
		}
	}

	subscribeFills(keypairId = 0, metadata = {}) {
		const request = new ConnectFills()
		request.setKeypairId(keypairId)

		this.stream = this.eventsService.subscribeFills(request, metadata)
		this.busy = true
	}

	subscribeGetKeypairs(currency = 0, metadata = {}) {
		const request = new ConnectGetKeypairs()
		request.setCurrency(currency)

		this.stream = this.eventsService.subscribeGetKeypairs(request, metadata)
		this.busy = true
	}

	subscribeOrderbookStatus(keypairId = 0, currency = 0, metadata = {}) {
		const request = new ConnectOrderbookStatus()
		request.setCurrency(currency)
		request.setKeypairId(keypairId)

		this.stream = this.eventsService.subscribeOrderbookStatus(request, metadata)
		this.busy = true
	}

	subscribeTickers(keypairId = 0, timerange = 0, metadata = {}) {
		const request = new ConnectStreamTicker()
		request.setTimeRange(timerange)
		request.setKeypairId(keypairId)

		this.stream = this.eventsService.subscribeTickers(request, metadata)
		this.busy = true
	}

	// Event Handlers
	onEnd(callback) {
		this.stream.on('end', callback)
		this.busy = false
	}

	onCancel(callback) {
		this.stream.on('cancel', callback)
		this.busy = false
	}

	onError(callback) {
		this.stream.on('error', callback)
	}

	onData(callback) {
		this.stream.on('data', response => {
			callback(this.serializeResponse(response))
		})
	}

	onStatus(callback) {
		this.stream.on('status', callback)
	}

	cancelStream() {
		this?.stream?.cancel()
	}
}

export default EventsClient
