import { getTradeFeeWithKlv } from '../../services/userSettings'

export const Types = {
	SET_TRADE_FEE_WITH_KLV: 'usersettings/SET_TRADE_FEE_WITH_KLV',
	FAILURE: 'usersettings/FAILURE',
}

const initialState = {
	settings: {
		TradeFeeWithKlv: false,
	},
	failed: true,
}

export const fetchTradeFeeWithKlv = () => {
	return async dispatch => {
		const TradeFeeWithKlv = await getTradeFeeWithKlv()
		if (!TradeFeeWithKlv?.error) {
			dispatch({
				type: Types.SET_TRADE_FEE_WITH_KLV,
				payload: TradeFeeWithKlv,
			})
		} else {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const reducerObject = {
	'usersettings/SET_TRADE_FEE_WITH_KLV': (state, action) => {
		return { ...state, settings: { TradeFeeWithKlv: action.payload }, failed: false }
	},
	'usersettings/SET_FAILURE': state => {
		return { ...state, failed: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
