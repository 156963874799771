import { getAllBfs, getBfs, getBfsAcceptableTokens, getBfsHistory } from '../../services/manager'

export const Types = {
	BFS_LIST: 'bfs/BFS_LIST',
	BFS_DETAILS: 'bfs/BFS_DETAILS',
	BFS_HISTORY: 'bfs/BFS_HISTORY',
	BFS_LIST_LOADING: 'bfs/BFS_LIST_LOADING',
	BFS_LIST_FAILURE: 'bfs/BFS_LIST_FAILURE',
	BFS_DETAILS_LOADING: 'bfs/BFS_DETAILS_LOADING',
	BFS_DETAILS_FAILURE: 'bfs/BFS_DETAILS_FAILURE',
	BFS_ACCEPTABLE_TOKENS: 'bfs/BFS_ACCEPTABLE_TOKENS',
	BFS_ACCEPTABLE_TOKENS_FAILURE: 'bfs/BFS_ACCEPTABLE_TOKENS_FAILURE',
	BFS_HISTORY_LOADING: 'bfs/BFS_HISTORY_LOADING',
	BFS_HISTORY_FAILURE: 'bfs/BFS_HISTORY_FAILURE',
}

const initialState = {
	bfsList: [],
	bfsDetails: [],
	bfsListLoading: false,
	bfsListFailure: false,
	bfsDetailsLoading: false,
	bfsDetailsFailure: false,
	bfsAcceptableTokens: [],
	bfsAcceptableTokensFailure: false,
	bfsHistory: {},
	bfsHistoryLoading: false,
	bfsHistoryFailure: false,
}

export const fetchAllBfs = () => {
	return async dispatch => {
		try {
			dispatch({ type: Types.BFS_LIST_LOADING, payload: true })
			const data = await getAllBfs()
			dispatch({
				type: Types.BFS_LIST,
				payload: data?.buyForStakes,
			})
		} catch (error) {
			dispatch({
				type: Types.BFS_LIST_FAILURE,
			})
		} finally {
			dispatch({ type: Types.BFS_LIST_LOADING, payload: false })
		}
	}
}

export const fetchBfs = ({ bfsId }) => {
	return async dispatch => {
		try {
			dispatch({ type: Types.BFS_DETAILS_LOADING, payload: true })
			const data = await getBfs({ bfsId })
			dispatch({
				type: Types.BFS_DETAILS,
				payload: data?.buyForStakePeriods,
			})
		} catch (error) {
			dispatch({
				type: Types.BFS_DETAILS_FAILURE,
			})
		} finally {
			dispatch({ type: Types.BFS_DETAILS_LOADING, payload: false })
		}
	}
}

export const fetchBfsAcceptableTokens = ({ bfsId }) => {
	return async dispatch => {
		try {
			const data = await getBfsAcceptableTokens({ bfsId })
			dispatch({
				type: Types.BFS_ACCEPTABLE_TOKENS,
				payload: data?.Tokens,
			})
		} catch (error) {
			dispatch({
				type: Types.BFS_ACCEPTABLE_TOKENS_FAILURE,
			})
		}
	}
}

export const fetchBfsHistory = ({ limit, skip, status }) => {
	return async dispatch => {
		try {
			dispatch({ type: Types.BFS_HISTORY_LOADING, payload: true })
			const data = await getBfsHistory({ limit, skip, status })
			dispatch({
				type: Types.BFS_HISTORY,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: Types.BFS_HISTORY_FAILURE,
			})
		} finally {
			dispatch({ type: Types.BFS_HISTORY_LOADING, payload: false })
		}
	}
}

export const reducerObject = {
	'bfs/BFS_LIST': (state, action) => {
		return { ...state, bfsList: action.payload, bfsListFailure: false }
	},
	'bfs/BFS_LIST_LOADING': (state, action) => {
		return { ...state, bfsListLoading: action.payload }
	},
	'bfs/BFS_LIST_FAILURE': state => {
		return { ...state, bfsListFailure: true }
	},
	'bfs/BFS_DETAILS': (state, action) => {
		return { ...state, bfsDetails: action.payload, bfsDetailsFailure: false }
	},
	'bfs/BFS_DETAILS_LOADING': (state, action) => {
		return { ...state, bfsDetailsLoading: action.payload }
	},
	'bfs/BFS_DETAILS_FAILURE': state => {
		return { ...state, bfsDetailsFailure: true }
	},
	'bfs/BFS_ACCEPTABLE_TOKENS': (state, action) => {
		return { ...state, bfsAcceptableTokens: action.payload, bfsAcceptableTokensFailure: false }
	},
	'bfs/BFS_ACCEPTABLE_TOKENS_FAILURE': state => {
		return { ...state, bfsAcceptableTokensFailure: true }
	},
	'bfs/BFS_HISTORY': (state, action) => {
		return { ...state, bfsHistory: action.payload, bfsHistoryFailure: false }
	},
	'bfs/BFS_HISTORY_LOADING': (state, action) => {
		return { ...state, bfsHistoryLoading: action.payload }
	},
	'bfs/BFS_HISTORY_FAILURE': state => {
		return { ...state, bfsHistoryFailure: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
