import { Layout, Skeleton } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'

import HeaderMenuSkeleton from '../../components/Desktop/HeaderMenu/HeaderMenuSkeleton'
import TrendingSkeleton from '../../components/Desktop/TrendingCard/TrendingSkeleton'
import DefaultHeroSkeleton from './DefaultHero/DefaultHeroSkeleton'
import * as S from './styles'

const HomeSkeleton = () => {
	return (
		<Layout>
			<HeaderMenuSkeleton />
			<Content className='klever-home-layout'>
				<DefaultHeroSkeleton />
				<S.Section>
					<Skeleton active title={{ style: { height: 25, width: 270 } }} paragraph={false} />
				</S.Section>
				<S.Space>
					<TrendingSkeleton />
					<TrendingSkeleton />
					<TrendingSkeleton />
					<TrendingSkeleton />
				</S.Space>
				<S.Section>
					<Skeleton active title={{ style: { height: 25, width: 220 } }} paragraph={false} />
				</S.Section>
				<S.Space>
					<TrendingSkeleton />
					<TrendingSkeleton />
					<TrendingSkeleton />
					<TrendingSkeleton />
				</S.Space>
			</Content>
		</Layout>
	)
}

export default HomeSkeleton
