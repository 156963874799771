import { getWithdrawals } from '../../services/manager'

export const Types = {
	FETCH_WITHDRAWALS: 'withdrawals/FETCH_WITHDRAWALS',
	FETCH_WITHDRAWALS_LAST_ADDRESS: 'withdrawals/FETCH_WITHDRAWALS_LAST_ADDRESS',
	SET_PAGE_WITHDRAWALS: 'withdrawals/SET_PAGE_WITHDRAWALS',
	SET_LIMIT_WITHDRAWALS: 'withdrawals/SET_LIMIT_WITHDRAWALS',
	SET_TOTAL_WITHDRAWALS: 'withdrawals/SET_TOTAL_WITHDRAWALS',
	FAILURE: 'withdrawals/FAILURE',
	LOADING: 'withdrawals/LOADING',
	LAST_ADDRESS_LOADING: 'withdrawals/LAST_ADDRESS_LOADING',
}

const initialState = {
	withdrawalsList: [],
	withdrawalsLastAddress: [],
	failed: false,
	loading: true,
	lastAddressLoading: false,
	page: 1,
	limit: 20,
	total: '0',
}

export const fetchWithdrawals = ({ limit, page, search = '' }) => {
	return async dispatch => {
		dispatch({
			type: Types.LOADING,
			payload: true,
		})
		const { Withdrawals, error, TotalElements } = await getWithdrawals({
			limit,
			skip: page * limit - limit,
			search,
		})

		if (error) dispatch({ type: Types.FAILURE })

		dispatch({
			type: Types.FETCH_WITHDRAWALS,
			payload: Withdrawals,
		})

		dispatch(setTotalWithdrawalsReducer(TotalElements))
		dispatch(setPageWithdrawalsReducer(page))
		dispatch(setLimitWithdrawalsReducer(limit))

		dispatch({
			type: Types.LOADING,
			payload: false,
		})
	}
}

export const fetchWithdrawalsLastAddress = ({ limit = 100, search = '' }) => {
	return async dispatch => {
		dispatch({
			type: Types.LAST_ADDRESS_LOADING,
			payload: true,
		})
		const { Withdrawals, error } = await getWithdrawals({
			limit,
			search,
		})

		if (error) dispatch({ type: Types.FAILURE })

		if (Withdrawals) {
			dispatch({
				type: Types.FETCH_WITHDRAWALS_LAST_ADDRESS,
				payload: Withdrawals,
			})
		}
		dispatch({
			type: Types.LAST_ADDRESS_LOADING,
			payload: false,
		})
	}
}

export const setPageWithdrawalsReducer = page => {
	return async dispatch => {
		dispatch({
			type: Types.SET_PAGE_WITHDRAWALS,
			payload: page,
		})
	}
}

export const setLimitWithdrawalsReducer = limit => {
	return async dispatch => {
		dispatch({
			type: Types.SET_LIMIT_WITHDRAWALS,
			payload: limit,
		})
	}
}

export const setTotalWithdrawalsReducer = total => {
	return async dispatch => {
		dispatch({
			type: Types.SET_TOTAL_WITHDRAWALS,
			payload: total,
		})
	}
}

export const reducerObject = {
	'withdrawals/FETCH_WITHDRAWALS': (state, action) => {
		return { ...state, withdrawalsList: action.payload, failed: false, loading: false }
	},
	'withdrawals/FETCH_WITHDRAWALS_LAST_ADDRESS': (state, action) => {
		return { ...state, withdrawalsLastAddress: action.payload, failed: false, loading: false }
	},
	'withdrawals/SET_LIMIT_WITHDRAWALS': (state, action) => {
		return { ...state, limit: action.payload }
	},
	'withdrawals/SET_PAGE_WITHDRAWALS': (state, action) => {
		return { ...state, page: action.payload }
	},
	'withdrawals/SET_TOTAL_WITHDRAWALS': (state, action) => {
		return { ...state, total: action.payload }
	},
	'withdrawals/FAILURE': state => {
		return { ...state, failed: true, loading: false }
	},
	'withdrawals/LOADING': (state, action) => {
		return { ...state, loading: action.payload }
	},
	'withdrawals/LAST_ADDRESS_LOADING': (state, action) => {
		return { ...state, lastAddressLoading: action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
