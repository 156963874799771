import arEG from 'antd/lib/locale/ar_EG'
import deDe from 'antd/lib/locale/de_DE'
import enUS from 'antd/lib/locale/en_US'
import esES from 'antd/lib/locale/es_ES'
import frFR from 'antd/lib/locale/fr_FR'
import heIL from 'antd/lib/locale/he_IL'
import hiIN from 'antd/lib/locale/hi_IN'
import idID from 'antd/lib/locale/id_ID'
import itIT from 'antd/lib/locale/it_IT'
import jaJP from 'antd/lib/locale/ja_JP'
import koKR from 'antd/lib/locale/ko_KR'
import nlNL from 'antd/lib/locale/nl_NL'
import plPL from 'antd/lib/locale/pl_PL'
import ptBR2 from 'antd/lib/locale/pt_BR'
import roRO from 'antd/lib/locale/ro_RO'
import ruRU from 'antd/lib/locale/ru_RU'
import svSE from 'antd/lib/locale/sv_SE'
import trTR from 'antd/lib/locale/tr_TR'
import zhCN from 'antd/lib/locale/zh_CN'

export const antdLanguages = {
	en_US: enUS,
	pt_BR: ptBR2,
	es_ES: esES,
	fr_FR: frFR,
	ru_RU: ruRU,
	de_DE: deDe,
	hi_IN: hiIN,
	it_IT: itIT,
	id_ID: idID,
	nl_NL: nlNL,
	ro_RO: roRO,
	tr_TR: trTR,
	ko_KR: koKR,
	zh_CN: zhCN,
	he_IL: heIL,
	ja_JP: jaJP,
	pl_PL: plPL,
	sv_SE: svSE,
	ar_EG: arEG,
}

// function unflattenObject(obj) {
// 	const result = {}

// 	for (const key in obj) {
// 		// eslint-disable-next-line no-prototype-builtins
// 		if (obj.hasOwnProperty(key)) {
// 			const keys = key.split('.')
// 			let currentObj = result

// 			for (let i = 0; i < keys.length - 1; i++) {
// 				const nestedKey = keys[i]
// 				currentObj[nestedKey] = currentObj[nestedKey] || {}
// 				currentObj = currentObj[nestedKey]
// 			}

// 			currentObj[keys[keys.length - 1]] = obj[key]
// 		}
// 	}

// 	return result
// }

// console.log(unflattenObject(ar))
