import { STORAGE_TOKENS } from '../../constants'
import { logError } from '../../utils'
import GenericClient from './GenericClient.js'
import {
	MFAOptions,
	CreateMFARequest,
	ActivateMFARequest,
	RecoverMFARequest,
	RemoveMFARequest,
	ValidateMFARequest,
	RecoverTOTPBackupKeyRequest,
} from './protoBuffers/mfa_pb.js'

class MFAClient extends GenericClient {
	constructor() {
		super()
		this.busy = false
	}

	// MFA Controllers

	async getMFAOptions(token) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: token || TOKEN,
				ClientOrigin: 3,
			}

			const request = new MFAOptions()

			const promise = new Promise(resolve => {
				this.mfaService.getMFAOptions(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						const { optionsList } = response.toObject()
						resolve(optionsList)
					}
				})
			})

			return promise.then(data => data)
		} catch (error) {
			logError('klv_getMFAOptions_error', error)
			return error
		}
	}

	async createMFA(option = 2, AuthToken) {
		try {
			const metadata = {
				AuthToken,
				ClientOrigin: 3,
			}
			const request = new CreateMFARequest()
			request.setOption(option)
			const promise = new Promise(resolve => {
				this.mfaService.createMFA(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response.toObject())
					}
				})
			})

			return promise.then(data => data)
		} catch (error) {
			logError('klv_createMFA_error', error)
			return error
		}
	}

	async activateMFA(secret, code, authToken, option = 2) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: TOKEN || authToken,
				ClientOrigin: 3,
			}

			const request = new ActivateMFARequest()
			request.setSecret(secret)
			request.setCode(code)
			request.setOption(option)
			const promise = new Promise(resolve => {
				this.mfaService.activateMFA(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response?.toObject())
					}
				})
			})
			return promise.then(data => data)
		} catch (error) {
			logError('klv_activateMFA_error', error)
			return error
		}
	}

	async validateMFA(code) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: TOKEN,
				ClientOrigin: 3,
			}

			const request = new ValidateMFARequest()
			request.setCode(code)

			const promise = new Promise(resolve => {
				this.mfaService.validateMFA(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response?.toObject())
					}
				})
			})

			return promise.then(data => data)
		} catch (error) {
			logError('klv_validateMFA_error', error)
			return error
		}
	}

	async recoverMFA(backupkey, authToken, option = 2) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: TOKEN || authToken,
				ClientOrigin: 3,
				BackupKey: backupkey,
			}
			const request = new RecoverMFARequest()
			request.setOption(option)

			const promise = new Promise(resolve => {
				this.mfaService.recoverMFA(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response?.toObject())
					}
				})
			})
			return promise.then(data => data)
		} catch (error) {
			logError('klv_removeMFA_error', error)
			return error
		}
	}

	async removeMFA(code, option = 2) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: TOKEN,
				ClientOrigin: 3,
			}
			const request = new RemoveMFARequest()
			request.setCode(code)
			request.setOption(option)

			const promise = new Promise(resolve => {
				this.mfaService.removeMFA(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response?.toObject())
					}
				})
			})
			return promise.then(data => data)
		} catch (error) {
			logError('klv_removeMFA_error', error)
			return error
		}
	}

	async recoverTOTPBackupKey(code) {
		try {
			const TOKEN = JSON.parse(localStorage.getItem(STORAGE_TOKENS))?.token
			const metadata = {
				AuthToken: TOKEN,
				ClientOrigin: 3,
			}

			const request = new RecoverTOTPBackupKeyRequest()
			request.setCode(code)

			const promise = new Promise(resolve => {
				this.mfaService.recoverTOTPBackupKey(request, metadata, (err, response) => {
					if (err) {
						resolve({ error: true, ...err })
					} else {
						resolve(response?.toObject())
					}
				})
			})

			return promise.then(data => data)
		} catch (error) {
			logError('klv_recoverTOTPBackupKey_error', error)
			return error
		}
	}
}

export default MFAClient
