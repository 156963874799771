export const MESSAGE_EXPIRED =
	'Fail when try to check user authorization: Failed when try to verify user token: the `Expiration` was not able to be validated. The token is expired'

export const FAVORITES = '@KLEVER_FAVORITES'

export const CURRENCIES = {
	USD: 0,
	EUR: 1,
	BRL: 2,
	BTC: 3,
}

export const NETWORKS = {
	TRX: 'TRC20',
	ETH: 'ERC20',
	KLV: 'KDA',
}

export const MINIMUM = Math.pow(10, -8)

export const TV_DISABLED_FEATURES = [
	'use_localstorage_for_settings',
	'header_symbol_search',
	'header_compare',
	'study_templates',
	'go_to_date',
	'save_shortcut',
	'save_chart_properties_to_local_storage',
	'header_saveload',
	'symbol_info',
	'symbol_search_hot_key',
	'popup_hints',
	'volume_force_overlay',
]

export const TV_OVERRIDES = {
	'paneProperties.vertGridProperties.color': 'rgba(61, 63, 112, 0.07)',
	'paneProperties.horzGridProperties.color': 'rgba(61, 63, 112, 0.07)',
	'paneProperties.backgroundType': 'solid',
	'paneProperties.background': '#2c2c2c',
	'mainSeriesProperties.lineStyle.color': '#d96200',
	'mainSeriesProperties.areaStyle.color1': 'rgba(170,51,181, 0.28)',
	'mainSeriesProperties.areaStyle.color2': '#d96200',
	'mainSeriesProperties.areaStyle.linecolor': '#d96200',
}

export const TV_TIME_FRAMES = [
	{ text: '5y', resolution: 'W', description: '5 Years', title: '5yrs' },
	{ text: '1y', resolution: 'D', description: '1 Year', title: '1yr' },
	{ text: '3m', resolution: '60', description: '3 Months', title: '3m' },
	{ text: '2w', resolution: '15', description: '2 Weeks', title: '2w' },
	{ text: '1d', resolution: '1', description: '1 Day', title: '1d' },
]

export const KYC_DOCUMENT_STATUS = {
	KYC_NEED_ID_UPLOAD: 'messages.kyc.no_id_uploaded',
	KYC_APPROVED: 'messages.kyc.approved_verified',
	KYC_PENDING: 'messages.kyc.pending',
	KYC_ERROR: 'messages.kyc.failed',
}

export const KYC_VERIFICATION_STATUS = {
	MATCH: 'messages.kyc.match',
	NO_MATCH: 'messages.kyc.not_match',
	NOT_POSSIBLE: 'messages.kyc.not_possible',
}

export const SERVER_DOWN_MSG = 'The server is currently offline, try refreshing the page or try again later'

export const TIMEZONES = [
	{ name: 'Africa/Cairo', hour: -2 },
	{ name: 'Africa/Johannesburg', hour: -2 },
	{ name: 'Africa/Lagos', hour: -1 },
	{ name: 'America/Argentina/Buenos_Aires', hour: 3 },
	{ name: 'America/Bogota', hour: 5 },
	{ name: 'America/Caracas', hour: 4 },
	{ name: 'America/Chicago', hour: 6 },
	{ name: 'America/El_Salvador', hour: 6 },
	{ name: 'America/Juneau', hour: 9 },
	{ name: 'America/Lima', hour: 5 },
	{ name: 'America/Los_Angeles', hour: 8 },
	{ name: 'America/Mexico_City', hour: 6 },
	{ name: 'America/New_York', hour: 5 },
	{ name: 'America/Phoenix', hour: 7 },
	{ name: 'America/Santiago', hour: 3 },
	{ name: 'America/Sao_Paulo', hour: 3 },
	{ name: 'America/Toronto', hour: 5 },
	{ name: 'America/Vancouver', hour: 8 },
	{ name: 'Asia/Almaty', hour: -6 },
	{ name: 'Asia/Ashkhabad', hour: -5 },
	{ name: 'Asia/Bahrain', hour: -3 },
	{ name: 'Asia/Bangkok', hour: -7 },
	{ name: 'Asia/Chongqing', hour: -8 },
	{ name: 'Asia/Dubai', hour: -4 },
	{ name: 'Asia/Ho_Chi_Minh', hour: -7 },
	{ name: 'Asia/Hong_Kong', hour: -8 },
	{ name: 'Asia/Jakarta', hour: -7 },
	{ name: 'Asia/Jerusalem', hour: -2 },
	{ name: 'Asia/Kathmandu', hour: -5.75 },
	{ name: 'Asia/Kolkata', hour: -5.5 },
	{ name: 'Asia/Kuwait', hour: -3 },
	{ name: 'Asia/Muscat', hour: -4 },
	{ name: 'Asia/Qatar', hour: -3 },
	{ name: 'Asia/Riyadh', hour: -3 },
	{ name: 'Asia/Seoul', hour: -9 },
	{ name: 'Asia/Shanghai', hour: -8 },
	{ name: 'Asia/Singapore', hour: -8 },
	{ name: 'Asia/Taipei', hour: -8 },
	{ name: 'Asia/Tehran', hour: -3.5 },
	{ name: 'Asia/Tokyo', hour: -9 },
	{ name: 'Atlantic/Reykjavik', hour: 0 },
	{ name: 'Australia/ACT', hour: -9.5 },
	{ name: 'Australia/Adelaide', hour: -10.5 },
	{ name: 'Australia/Brisbane', hour: -10 },
	{ name: 'Australia/Perth', hour: -8 },
	{ name: 'Australia/Sydney', hour: -11 },
	{ name: 'Europe/Amsterdam', hour: -1 },
	{ name: 'Europe/Athens', hour: -2 },
	{ name: 'Europe/Belgrade', hour: -1 },
	{ name: 'Europe/Berlin', hour: -1 },
	{ name: 'Europe/Brussels', hour: -1 },
	{ name: 'Europe/Copenhagen', hour: -1 },
	{ name: 'Europe/Dublin', hour: 0 },
	{ name: 'Europe/Helsinki', hour: -2 },
	{ name: 'Europe/Istanbul', hour: -3 },
	{ name: 'Europe/Lisbon', hour: 0 },
	{ name: 'Europe/London', hour: 0 },
	{ name: 'Europe/Luxembourg', hour: -1 },
	{ name: 'Europe/Madrid', hour: -1 },
	{ name: 'Europe/Malta', hour: -1 },
	{ name: 'Europe/Moscow', hour: -3 },
	{ name: 'Europe/Oslo', hour: -1 },
	{ name: 'Europe/Paris', hour: -1 },
	{ name: 'Europe/Riga', hour: -2 },
	{ name: 'Europe/Rome', hour: -1 },
	{ name: 'Europe/Stockholm', hour: -1 },
	{ name: 'Europe/Tallinn', hour: -2 },
	{ name: 'Europe/Vilnius', hour: -2 },
	{ name: 'Europe/Warsaw', hour: -1 },
	{ name: 'Europe/Zurich', hour: -1 },
	{ name: 'Pacific/Auckland', hour: -13 },
	{ name: 'Pacific/Chatham', hour: -13.75 },
	{ name: 'Pacific/Fakaofo', hour: -13 },
	{ name: 'Pacific/Honolulu', hour: 10 },
	{ name: 'Pacific/Norfolk', hour: -12 },
	{ name: 'US/Mountain', hour: 7 },
]

export const navItems = (language, keypairBase, keypairQuote) => [
	{
		name: `/${language}`,
		featureMenus: [
			{
				title: 'components.header_menu.markets',
				path: `/${language}/markets`,
				id: 'markets',
				active: location?.pathname === `/${language}/markets`,
			},
			{
				title: 'components.header_menu.trade',
				path: `/${language}/trade/${keypairBase}-${keypairQuote}`,
				id: 'trade',
				children: [
					{
						title: 'components.header_menu.spot_trade',
						description: 'components.header_menu.spot_trade_desc',
						id: 'spot-trade',
						path: `/${language}/trade/${keypairBase}-${keypairQuote}`,
						active: location?.pathname === `/${language}/trade/${keypairBase}-${keypairQuote}`,
					},
					{
						title: 'components.header_menu.convert',
						description: 'components.header_menu.convert_desc',
						path: `/${language}/convert`,
						id: 'convert',
						active: location?.pathname === `/${language}/convert`,
					},
					{
						title: 'components.header_menu.nft_marketplace',
						description: 'components.header_menu.nft_marketplace_desc',
						path: `/${language}/nft`,
						id: 'nft-marketplace',
						active: location?.pathname === `/${language}/nft`,
					},
					{
						title: 'components.header_menu.trading_view',
						description: 'components.header_menu.trading_view_desc',
						path: `/${language}/tradingview`,
						id: 'tradingview',
						active: location?.pathname === `/${language}/tradingview`,
					},
					// {
					// 	title: 'components.header_menu.nft_stats',
					// 	description: 'components.header_menu.nft_stats_desc',
					// 	path: `/${language}/nft/collections`,
					// 	active: location?.pathname === `/${language}/nft/collections`,
					// },
				],
			},
			{
				title: 'components.header_menu.convert',
				description: 'components.header_menu.convert_desc',
				path: `/${language}/convert`,
				id: 'convert',
				active: location?.pathname === `/${language}/convert`,
				mobile: true,
			},
			// {
			// 	title: 'components.header_menu.dojo',
			// 	path: `/${language}/trading-competition`,
			// 	id: 'dojo',
			// 	children: [
			// 		{
			// 			title: 'components.header_menu.trading_competition',
			// 			description: 'components.header_menu.trading_competition_desc',
			// 			path: `/${language}/trading-competition`,
			// 			active: location?.pathname === `/${language}/trading-competition`,
			// 		},
			// 	],
			// },
			{
				title: 'components.header_menu.pools',
				path: `/${language}/pools`,
				id: 'pools',
				active: location?.pathname === `/${language}/pools`,
				// hasBadge: true,
			},
			{
				title: 'components.header_menu.buy_and_hodl',
				path: `/${language}/buy-and-hodl`,
				id: 'buy-and-hodl',
				active: location?.pathname === `/${language}/buy-and-hodl`,
				// hasHot: true,
			},
			{
				title: 'components.header_menu.nft',
				description: 'components.header_menu.nft_marketplace_desc',
				path: `/${language}/nft`,
				id: 'nft',
				active: location?.pathname === `/${language}/nft`,
				mobile: true,
			},
		],
	},
]

export const CURRENCIES_OBJ = [
	{
		id: '1',
		symbol: '$',
		name: 'USD',
	},
	{
		id: '2',
		symbol: 'R$',
		name: 'BRL',
	},
	{
		id: '3',
		symbol: '₿',
		name: 'BTC',
	},
]

export const LANGUAGES = [
	{
		id: '1',
		name: 'English',
		label: 'en',
		abbr: 'us',
		currency: {
			symbol: '$ ',
			name: 'USD',
		},
		code: 'en-US',
		aLang: 'en_US',
		dir: 'ltr',
	},
	{
		id: '2',
		name: 'Português (BR)',
		label: 'pt-br',
		abbr: 'pt-br',
		currency: {
			symbol: 'R$',
			name: 'BRL',
		},
		code: 'pt-br',
		aLang: 'pt_BR',
		dir: 'ltr',
	},
	{
		id: '3',
		name: 'Español',
		label: 'es',
		abbr: 'es',
		currency: {
			symbol: 'EUR',
			name: 'EUR',
		},
		code: 'es',
		aLang: 'es_ES',
		dir: 'ltr',
	},
	{
		id: '4',
		name: 'Français',
		label: 'fr',
		abbr: 'fr',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'fr',
		aLang: 'fr_FR',
		dir: 'ltr',
	},
	{
		id: '5',
		name: 'Deutsch (German)',
		label: 'de',
		abbr: 'de',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'de',
		aLang: 'de_DE',
		dir: 'ltr',
	},
	{
		id: '6',
		name: 'Русский',
		label: 'ru',
		abbr: 'ru',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'ru',
		aLang: 'ru_RU',
		dir: 'ltr',
	},
	{
		id: '7',
		name: 'हिंदी',
		label: 'hi',
		abbr: 'hi',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'hi',
		aLang: 'hi_IN',
		dir: 'ltr',
	},
	{
		id: '8',
		name: 'Italiano',
		label: 'it',
		abbr: 'it',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'it',
		aLang: 'it_IT',
		dir: 'ltr',
	},
	{
		id: '9',
		name: 'Indonesia',
		label: 'id',
		abbr: 'id',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'id',
		aLang: 'id_ID',
		dir: 'ltr',
	},
	{
		id: '10',
		name: 'Nederlands',
		label: 'nl',
		abbr: 'nl',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'nl',
		aLang: 'nl_NL',
		dir: 'ltr',
	},
	{
		id: '11',
		name: 'Română',
		label: 'ro',
		abbr: 'ro',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'ro',
		aLang: 'ro_RO',
		dir: 'ltr',
	},
	{
		id: '12',
		name: 'Türkçe',
		label: 'tr',
		abbr: 'tr',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'tr',
		aLang: 'tr_TR',
		dir: 'ltr',
	},
	{
		id: '13',
		name: '한국어',
		label: 'ko',
		abbr: 'ko',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'ko',
		aLang: 'ko_KR',
		dir: 'ltr',
	},
	{
		id: '14',
		name: '中國人',
		label: 'zh',
		abbr: 'zh',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'zh',
		aLang: 'zh_CN',
		dir: 'ltr',
	},
	{
		id: '15',
		name: 'עִברִית',
		label: 'he',
		abbr: 'he',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'he',
		aLang: 'he_IL',
		dir: 'ltr',
	},
	{
		id: '16',
		name: '日本語',
		label: 'ja',
		abbr: 'ja',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'ja',
		aLang: 'ja_JP',
		dir: 'ltr',
	},
	{
		id: '17',
		name: 'Polski',
		label: 'pl',
		abbr: 'pl',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'pl',
		aLang: 'pl_PL',
		dir: 'ltr',
	},
	{
		id: '18',
		name: 'Svenska',
		label: 'sv',
		abbr: 'sv',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'sv',
		aLang: 'sv_SE',
		dir: 'ltr',
	},
	{
		id: '19',
		name: 'عربي',
		label: 'ar',
		abbr: 'ar',
		currency: {
			symbol: '€',
			name: 'EUR',
		},
		code: 'ar',
		aLang: 'ar_AE',
		dir: 'ltr',
	},
]

export const MAX_API_KEYS = 5

export const marketingBannersImages = [
	// {
	// 	id: 11,
	// 	image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1706016476/images/LP_DOT_USDT_hxkzfy.webp',
	// 	url: 'pools',
	// 	alt: 'Liquidity Pool DOT-USDT',
	// },
	{
		id: 12,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1706537432/images/LIQUIDITY_POOLS_USDT_DVK_i3coer.webp',
		url: 'pools',
		alt: 'Liquidity Pool DVK-USDT',
	},
	{
		id: 10,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1705417124/images/LIQUIDITY_POOLS_TRX_USDT_ulssxh.webp',
		url: 'pools',
		alt: 'Liquidity Pool TRX-USDT',
	},
	{
		id: 9,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1704894283/lp_eth_usdt_pon4h1.webp',
		url: 'pools',
		alt: 'Liquidity Pool ETH-USDT',
	},
	{
		id: 8,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1704215683/images/bkqdaolannosgktccdmf.webp',
		url: 'pools',
		alt: 'Liquidity Pool BTC-USDT',
	},
	{
		id: 7,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1703023061/images/LIQUIDITY_POOLS_KLV_USDT_2_ibxzux.webp',
		url: 'pools',
		alt: 'Liquidity Pool KLV-USDT',
	},
	{
		id: 6,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1698343942/images/KFI_USDT_LP_FINAL_kioue7.webp',
		url: 'pools',
		alt: 'Liquidity Pool KFI-USDT',
	},
	{
		id: 2,
		image: 'https://res.cloudinary.com/bitcoinme/image/upload/v1698343942/images/KLV_USDT_LP_FINAL_lvzfk1.webp',
		url: 'pools',
		alt: 'Liquidity Pool KLV-USDT',
	},
]
