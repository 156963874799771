import { getDeposits, getDepositsAddress } from '../../services/manager'

export const Types = {
	FETCH_DEPOSITS: 'deposits/FETCH_DEPOSITS',
	FETCH_DEPOSITS_ADDRESS: 'deposits/FETCH_DEPOSITS_ADDRESS',
	FAILURE: 'deposits/FAILURE',
	LOADING: 'deposits/LOADING',
}

const initialState = {
	depositsList: [],
	depositsAddress: [],
	failed: false,
	loading: false,
}

export const fetchDeposits = ({ limit, skip, search }) => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.LOADING,
				payload: true,
			})
			const deposits = await getDeposits({ limit, skip, search })
			dispatch({
				type: Types.FETCH_DEPOSITS,
				payload: deposits,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const fetchDepositsAddress = networkID => {
	return async dispatch => {
		const { Addresses, error } = await getDepositsAddress(networkID)
		error
			? dispatch({ type: Types.FAILURE })
			: Addresses &&
			  dispatch({
					type: Types.FETCH_DEPOSITS_ADDRESS,
					payload: Addresses,
			  })
	}
}

export const reducerObject = {
	'deposits/FETCH_DEPOSITS': (state, action) => {
		return { ...state, depositsList: action.payload, failed: false, loading: false }
	},
	'deposits/FETCH_DEPOSITS_ADDRESS': (state, action) => {
		return { ...state, depositsAddress: action.payload, failed: false }
	},
	'deposits/FAILURE': state => {
		return { ...state, failed: true, loading: false }
	},
	'deposits/LOADING': state => {
		return { ...state, loading: true }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
