import React, { lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import ScrollToTop from '../components/ScrollToTop'
import HomeSkeleton from '../pages/home/HomeSkeleton'
import { componentLoader } from '../utils'

const createLazy = (importComponent, LoadingComponent = null) => {
	const Content = lazy(() => componentLoader(importComponent))
	return () => (
		<Suspense fallback={LoadingComponent}>
			<Content />
		</Suspense>
	)
}

const Home = createLazy(() => import('../pages/home'), <HomeSkeleton />)
const MainRoutes = createLazy(() => import('./Main'))

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<ScrollToTop>
				<Toaster
					toastOptions={{
						style: {
							background: '#787878',
							color: '#ffffff',
						},
					}}
				/>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/*' element={<MainRoutes />} />
				</Routes>
			</ScrollToTop>
		</BrowserRouter>
	)
}

export default AppRoutes
