import { createBrowserHistory } from 'history'
import i18n from 'i18next'

export const Types = {
	SET_LANGUAGE: 'language/SET_LANGUAGE',
}

const LANGUAGE_STORAGE = JSON.parse(localStorage.getItem('language'))

const initialState = {
	label: LANGUAGE_STORAGE?.label || 'en',
	language: LANGUAGE_STORAGE?.abbr || 'us',
	code: LANGUAGE_STORAGE?.code || 'en-US',
	aLang: LANGUAGE_STORAGE?.aLang || 'en_US',
	dir: LANGUAGE_STORAGE?.dir || 'ltr',
}

export const setLanguage = (lang, path) => {
	return dispatch => {
		if (lang?.abbr === 'en') {
			lang = { ...lang, abbr: 'us' }
		}

		if (lang?.code === 'en-US') {
			lang = { ...lang, code: 'en' }
		}

		i18n.changeLanguage(lang?.code, () => {
			dispatch({
				type: Types.SET_LANGUAGE,
				payload: lang || initialState,
			})

			const splitPath = path?.split('/')
			const slicePath = splitPath?.slice(2, splitPath.length)

			localStorage?.setItem(
				'language',
				JSON.stringify({
					label: lang?.label,
					code: lang?.code,
					abbr: lang?.abbr,
					aLang: lang?.aLang,
					dir: lang?.dir,
				})
			)

			if (slicePath?.length === 0) return createBrowserHistory().replace(`/${lang?.abbr}`)
			createBrowserHistory().replace(`/${lang?.abbr}/${slicePath?.join('/')}`)
		})

		if (lang?.aLang !== LANGUAGE_STORAGE?.aLang) {
			document.location.reload()
		}
	}
}

export const reducerObject = {
	'language/SET_LANGUAGE': (state, action) => {
		return {
			...state,
			label: action?.payload?.label,
			language: action?.payload?.abbr,
			code: action?.payload?.code,
			aLang: action?.payload?.aLang,
			dir: action?.payload?.dir,
		}
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
