import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import moment from 'moment'

import { ORDERS_LIMIT_QUANTITY } from '../../constants'
import EventsClient from '../../services/grpcweb/EventsClient'
import {
	getAllOpenOrders,
	getAllStopOrders,
	getAllOrdersStatus,
	getOrderGroups,
	getOrders,
	getStopOrders,
	getConvertHistory,
} from '../../services/manager'

export const Types = {
	FETCH_ORDERS: 'orders/FETCH_ORDERS',
	LOADING_ORDERS: 'orders/LOADING_ORDERS',
	FETCH_OPEN_ORDERS: 'orders/FETCH_OPEN_ORDERS',
	FETCH_OPEN_STOP_ORDERS: 'orders/FETCH_OPEN_STOP_ORDERS',
	FETCH_TRADES_HISTORY: 'orders/FETCH_TRADES_HISTORY',
	FETCH_CONVERT_HISTORY: 'orders/FETCH_CONVERT_HISTORY',
	FETCH_CONVERT_HISTORY_PAGINATION: 'orders/FETCH_CONVERT_HISTORY_PAGINATION',
	SET_ORDERS_STREAM: 'orders/SET_ORDERS_STREAM',
	SET_ORDER_GROUPS_STREAM: 'orders/SET_ORDER_GROUPS_STREAM',
	SET_STOP_ORDERS_STREAM: 'orders/SET_STOP_ORDERS_STREAM',
	FETCH_ORDERBOOK: 'orders/FETCH_ORDERBOOK',
	FETCH_ALL_ORDERS: 'orders/FETCH_ALL_ORDERS',
	FAILURE_ALL_ORDERS: 'orders/FAILURE_ALL_ORDERS',
	FAILURE_CONVERT_ORDERS: 'orders/FAILURE_CONVERT_ORDERS',
	LOADING_ALL_ORDERS: 'orders/LOADING_ALL_ORDERS',
	FETCH_ALL_OPEN_ORDERS: 'orders/FETCH_ALL_OPEN_ORDERS',
	FETCH_ALL_STOP_ORDERS: 'orders/FETCH_ALL_STOP_ORDERS',
	SET_DECIMALS: 'orders/SET_DECIMALS',
	SET_BUY_PRICE: 'orders/SET_BUY_PRICE',
	SET_SELL_PRICE: 'orders/SET_SELL_PRICE',
	SET_STOP_BUY: 'orders/SET_STOP_BUY',
	SET_STOP_SELL: 'orders/SET_STOP_SELL',
	SET_BUY_AMOUNT: 'orders/SET_BUY_AMOUNT',
	SET_SELL_AMOUNT: 'orders/SET_SELL_AMOUNT',
	FAILURE: 'orders/FAILURE',
	RESET_ORDERS: 'orders/RESET_ORDERS',
	SET_LAST_EVENT: 'orders/SET_LAST_EVENT',
}

const initialState = {
	loading: false,
	ordersList: [],
	openOrdersList: [],
	openStopOrdersList: [],
	ordersHistoryList: [],
	tradesHistoryList: [],
	convertHistory: [],
	convertHistoryPagination: {},
	holdingsList: [],
	allOrders: [],
	failedAllOrders: false,
	failedConvertOrders: false,
	loadingAllOrders: false,
	allOpenOrders: [],
	allStopOrders: [],
	allOrdersParams: {},
	allOpenOrdersParams: {},
	allStopOrdersParams: {},
	orderStream: {},
	orderGroupsStream: {},
	stopOrderStream: {},
	orderBookList: {},
	decimals: undefined,
	failed: false,
	reducerStopBuy: '',
	reducerStopSell: '',
	reducerBuyPrice: undefined,
	reducerSellPrice: undefined,
	reducerBuyAmount: '',
	reducerSellAmount: '',
	lastEventID: '',
	lastEventStatus: '',
}

export const fetchOrders = (keypairsID, status) => {
	return async (dispatch, getStore) => {
		const { Orders, error } = await getOrders(keypairsID, status)
		const { orders, keypairs } = getStore()
		if (error) dispatch({ type: Types.FAILURE })
		if (keypairsID === keypairs?.selectedKeypair?.ID && Orders) {
			if (isEmpty(orders.orderStream)) dispatch(setStreamOrders(keypairsID))
			if (isEmpty(orders.orderGroupsStream)) dispatch(setStreamOrderGroups(keypairsID))
			if (isEmpty(orders.stopOrderStream)) dispatch(setStreamStopOrders(keypairsID))
			dispatch({
				type: Types.FETCH_ORDERS,
				payload: Orders,
			})
		}
	}
}

export const fetchOpenOrders = keypairsID => {
	return async (dispatch, getStore) => {
		const { Orders, error } = await getOrders(keypairsID, ['OPEN', 'PARTIALLY'])
		const { orders, keypairs } = getStore()
		if (error) dispatch({ type: Types.FAILURE })
		if (keypairsID === keypairs?.selectedKeypair?.ID && Orders) {
			if (isEmpty(orders.orderStream)) dispatch(setStreamOrders(keypairsID))
			dispatch({
				type: Types.FETCH_OPEN_ORDERS,
				payload: Orders,
			})
		}
	}
}

export const fetchAllOpenOrders = (limit, skip, search, sort, sortDirection, side) => {
	return async dispatch => {
		try {
			const response = await getAllOpenOrders(
				limit,
				skip,
				['OPEN', 'PARTIALLY'],
				search,
				sort,
				sortDirection,
				side
			)
			dispatch({
				type: Types.FETCH_ALL_OPEN_ORDERS,
				payload: response,
			})
		} catch (error) {
			dispatch({
				type: Types.FAILURE,
			})
		}
	}
}

export const fetchAllOrders = ({ limit, skip, status, search, sort, sortDirection, operation, side }) => {
	return async dispatch => {
		dispatch({
			type: Types.FETCH_ALL_ORDERS,
			payload: { HeaderPagination: {}, Orders: [] },
		})
		dispatch({
			type: Types.LOADING_ALL_ORDERS,
		})

		const { HeaderPagination, Orders, error } = await getAllOrdersStatus(
			limit,
			skip,
			status,
			search,
			sort,
			sortDirection,
			operation,
			side
		)

		if (HeaderPagination && Orders) {
			dispatch({
				type: Types.FETCH_ALL_ORDERS,
				payload: { HeaderPagination, Orders },
			})
		}
		if (HeaderPagination && !Orders) {
			dispatch({
				type: Types.FETCH_ALL_ORDERS,
				payload: { HeaderPagination, Orders: [] },
			})
		}
		if (error) {
			dispatch({
				type: Types.FAILURE_ALL_ORDERS,
			})
		}
	}
}

export const fetchOpenStopOrders = keypairID => {
	return async (dispatch, getStore) => {
		const { Orders, error } = await getStopOrders(keypairID, ['OPEN', 'PARTIALLY'])
		const { orders, keypairs } = getStore()
		if (error) dispatch({ type: Types.FAILURE })
		if (keypairID === keypairs?.selectedKeypair?.ID && Orders) {
			if (isEmpty(orders.orderStream)) dispatch(setStreamStopOrders(keypairID))
			dispatch({
				type: Types.FETCH_OPEN_STOP_ORDERS,
				payload: Orders,
			})
		}
	}
}

export const fetchAllStopOrders = (limit, skip) => {
	return async dispatch => {
		try {
			const response = await getAllStopOrders(limit, skip, ['OPEN', 'PARTIALLY'])
			dispatch({
				type: Types.FETCH_ALL_STOP_ORDERS,
				payload: response,
			})
		} catch (error) {
			dispatch({
				type: Types.FAILURE,
			})
		}
	}
}

export const fetchTradesHistory = keypairsID => {
	return async (dispatch, getStore) => {
		const { Orders, error } = await getOrders(keypairsID, 'DONE', 100)
		const { keypairs } = getStore()

		if (error) dispatch({ type: Types.FAILURE })
		if (keypairsID === keypairs?.selectedKeypair?.ID && Orders) {
			dispatch({
				type: Types.FETCH_TRADES_HISTORY,
				payload: Orders,
			})
		}
	}
}

export const setStreamOrders = () => {
	return async dispatch => {
		try {
			const streamOrders = new EventsClient()
			dispatch({
				type: Types.SET_ORDERS_STREAM,
				payload: streamOrders,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const setStreamOrderGroups = () => {
	return async dispatch => {
		try {
			const streamOrderGroups = new EventsClient()
			dispatch({
				type: Types.SET_ORDER_GROUPS_STREAM,
				payload: streamOrderGroups,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const setStreamStopOrders = () => {
	return async dispatch => {
		try {
			const streamStopOrders = new EventsClient()
			dispatch({
				type: Types.SET_STOP_ORDERS_STREAM,
				payload: streamStopOrders,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const fetchOrdersGroups = (id, decimals, limit, status) => {
	return async (dispatch, getStore) => {
		try {
			dispatch({
				type: Types.LOADING_ORDERS,
				payload: {
					loading: true,
				},
			})
			const orderGroups = await getOrderGroups(id, decimals, limit, status)

			const { orders, keypairs } = getStore()

			if (id === keypairs?.selectedKeypair?.ID) {
				if (isEmpty(orders.orderStream)) dispatch(setStreamOrders(id))

				dispatch({
					type: Types.FETCH_ORDERBOOK,
					payload: orderGroups,
				})
			} else {
				dispatch({
					type: Types.LOADING_ORDERS,
					payload: {
						loading: false,
					},
				})
			}
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearStreamOrders = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_ORDERS_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearStreamOrderGroups = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_ORDER_GROUPS_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearStreamStopOrders = () => {
	return async dispatch => {
		try {
			const newObject = Object.create({})
			dispatch({
				type: Types.SET_STOP_ORDERS_STREAM,
				payload: newObject,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

export const clearOrders = () => {
	return async dispatch => {
		try {
			dispatch({
				type: Types.RESET_ORDERS,
			})
		} catch (error) {
			dispatch({ type: Types.FAILURE })
		}
	}
}

const resolveOpenOrdersUpdate = event => {
	return (dispatch, getStore) => {
		const { orders } = getStore()
		const shouldRemove = event.Status === 'CANCELED' || event.Status === 'DONE'
		let newList = Array.from(orders.openOrdersList, item => item)
		const index = newList.findIndex(order => event.OrderID === order.OrderID)

		if (index > -1) {
			if (shouldRemove) {
				newList.splice(index, 1)
			} else {
				newList[index] = event
			}
		} else if (!shouldRemove) {
			if (newList?.length >= ORDERS_LIMIT_QUANTITY) {
				newList.pop()
			}
			newList = [event, ...newList]
		}
		dispatch({
			type: Types.FETCH_OPEN_ORDERS,
			payload: newList,
		})
	}
}

const resolveTradesHistoryUpdate = event => {
	return (dispatch, getStore) => {
		const { orders } = getStore()
		if (event.Status === 'DONE') {
			const alreadyExists = orders.tradesHistoryList?.find(order => order.OrderID === event.OrderID)
			if (!alreadyExists) {
				const newOrderList = [event, ...orders.tradesHistoryList]
				dispatch({
					type: Types.FETCH_TRADES_HISTORY,
					payload: newOrderList,
				})
			}
		}
	}
}

const resolveOrdersList = event => {
	// verific if already exists in order list and add or update
	return (dispatch, getStore) => {
		const { orders } = getStore()

		const newList = orders.ordersList
		const alreadyExists = newList?.find(order => order.OrderID === event.OrderID)
		let newOrderList = Array.from(newList, item => item)
		if (alreadyExists) {
			const indexExists = newOrderList.findIndex(order => order.OrderID === event.OrderID)
			newOrderList[indexExists] = event
		} else {
			newOrderList = [event, ...newOrderList]
		}

		dispatch({
			type: Types.FETCH_ORDERS,
			payload: newOrderList,
		})
	}
}

export const updateOrders = event => {
	return (dispatch, getStore) => {
		const { keypairs } = getStore()
		if (event?.Orders?.KeypairID === keypairs?.selectedKeypair?.ID) {
			const Side = event.Orders.Side > 0 || event.Orders.Side === 'SELL' ? 'SELL' : 'BUY'
			// create new order object
			const eventOrder = {
				...event.Orders,
				Price: event.Orders.Price,
				CreatedAt: moment.unix(event.Orders.CreatedAt.Seconds).format(),
				Side: Side,
			}

			dispatch(resolveOpenOrdersUpdate(eventOrder))
			dispatch(resolveTradesHistoryUpdate(eventOrder))
			dispatch(resolveOrdersList(eventOrder))
		}
	}
}

let LAST_ORDERBOOK_UPDATE = 0

export const updateOrderGroups = event => {
	return (dispatch, getStore) => {
		const { keypairs } = getStore()
		const { BuyOrdersList, SellOrdersList, EventTime } = event

		if (!isEmpty(event?.BuyOrdersList)) {
			if (event?.BuyOrdersList[0]?.KeypairID !== keypairs?.selectedKeypair?.ID) return
		}

		if (!isEmpty(event?.SellOrdersList)) {
			if (event?.SellOrdersList[0]?.KeypairID !== keypairs?.selectedKeypair?.ID) return
		}

		if (LAST_ORDERBOOK_UPDATE < EventTime?.Seconds) {
			const sortedBuyList = orderBy(BuyOrdersList, ['Price'], ['desc'])
			const sortedSellList = orderBy(SellOrdersList, ['Price'], ['desc'])

			dispatch({
				type: Types.FETCH_ORDERBOOK,
				payload: {
					BuyOrders: sortedBuyList.slice(0, 59),
					SellOrders: sortedSellList.splice(-60),
				},
			})

			LAST_ORDERBOOK_UPDATE = EventTime?.Seconds
		}
	}
}

export const updateStopOrders = event => {
	return (dispatch, getStore) => {
		const { StopOrders } = event
		const { orders } = getStore()
		const shouldRemove = StopOrders?.Status === 'CANCELED' || StopOrders?.Status === 'DONE'
		let newList = Array.from(orders?.openStopOrdersList, item => item)
		const index = newList.findIndex(order => StopOrders.OrderID === order.OrderID)

		if (index > -1) {
			if (shouldRemove) {
				newList.splice(index, 1)
			} else {
				newList[index] = StopOrders
			}
		} else if (!shouldRemove) {
			if (newList?.length >= ORDERS_LIMIT_QUANTITY) {
				newList.pop()
			}
			newList = [{ ...StopOrders }, ...newList]
		}

		dispatch({
			type: Types.FETCH_OPEN_STOP_ORDERS,
			payload: newList,
		})
	}
}

export const fetchConvertHistory = ({ limit, skip, side, status }) => {
	return async dispatch => {
		try {
			const response = await getConvertHistory({ limit, skip, side, status })
			dispatch({
				type: Types.FETCH_CONVERT_HISTORY,
				payload: response?.convertTransactions,
			})
			dispatch({
				type: Types.FETCH_CONVERT_HISTORY_PAGINATION,
				payload: response?.pagination,
			})
		} catch (error) {
			dispatch({
				type: Types.FAILURE_CONVERT_ORDERS,
			})
		}
	}
}

export const setBuyPrice = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_BUY_PRICE,
			payload: value,
		})
	}
}

export const setSellPrice = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_SELL_PRICE,
			payload: value,
		})
	}
}

export const setStopBuy = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_STOP_BUY,
			payload: value,
		})
	}
}

export const setStopSell = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_STOP_SELL,
			payload: value,
		})
	}
}

export const setBuyAmount = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_BUY_AMOUNT,
			payload: value,
		})
	}
}

export const setSellAmount = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_SELL_AMOUNT,
			payload: value,
		})
	}
}

export const setDecimals = value => {
	return dispatch => {
		dispatch({
			type: Types.SET_DECIMALS,
			payload: value,
		})
	}
}

export const reducerObject = {
	'orders/FETCH_ORDERS': (state, action) => {
		return { ...state, ordersList: action.payload, failed: false }
	},
	'orders/LOADING_ORDERS': (state, action) => {
		return { ...state, ...action.payload }
	},
	'orders/FETCH_OPEN_ORDERS': (state, action) => {
		return { ...state, openOrdersList: action.payload, failed: false }
	},
	'orders/FETCH_OPEN_STOP_ORDERS': (state, action) => {
		return { ...state, openStopOrdersList: action.payload, failed: false }
	},
	'orders/FETCH_TRADES_HISTORY': (state, action) => {
		return { ...state, tradesHistoryList: action.payload, failed: false }
	},
	'orders/FETCH_CONVERT_HISTORY': (state, action) => {
		return { ...state, convertHistory: action.payload, failed: false }
	},
	'orders/FETCH_CONVERT_HISTORY_PAGINATION': (state, action) => {
		return { ...state, convertHistoryPagination: action.payload, failed: false }
	},
	'orders/SET_ORDERS_STREAM': (state, action) => {
		return { ...state, orderStream: action.payload, failed: false }
	},
	'orders/SET_ORDER_GROUPS_STREAM': (state, action) => {
		return { ...state, orderGroupsStream: action.payload, failed: false }
	},
	'orders/SET_STOP_ORDERS_STREAM': (state, action) => {
		return { ...state, stopOrderStream: action.payload, failed: false }
	},
	'orders/FETCH_ORDERBOOK': (state, action) => {
		return { ...state, orderBookList: action.payload, failed: false, loading: false }
	},
	'orders/SET_DECIMALS': (state, action) => {
		return { ...state, decimals: action.payload, failed: false }
	},
	'orders/SET_BUY_PRICE': (state, action) => {
		return { ...state, reducerBuyPrice: action.payload, failed: false }
	},
	'orders/SET_SELL_PRICE': (state, action) => {
		return { ...state, reducerSellPrice: action.payload, failed: false }
	},
	'orders/SET_BUY_AMOUNT': (state, action) => {
		return { ...state, reducerBuyAmount: action.payload, failed: false }
	},
	'orders/SET_SELL_AMOUNT': (state, action) => {
		return { ...state, reducerSellAmount: action.payload, failed: false }
	},
	'orders/SET_STOP_BUY': (state, action) => {
		return { ...state, reducerStopBuy: action.payload, failed: false }
	},
	'orders/SET_STOP_SELL': (state, action) => {
		return { ...state, reducerStopSell: action.payload, failed: false }
	},
	'orders/SET_LAST_EVENT': (state, action) => {
		return { ...state, lastEventID: action.payload.OrderID, lastEventStatus: action.payload.Status }
	},
	'orders/FAILURE': state => {
		return { ...state, failed: true }
	},
	'orders/FAILURE_ALL_ORDERS': state => {
		return { ...state, failedAllOrders: true, loadingAllOrders: false }
	},
	'orders/FAILURE_CONVERT_ORDERS': state => {
		return { ...state, failedConvertOrders: true }
	},
	'orders/LOADING_ALL_ORDERS': state => {
		return { ...state, loadingAllOrders: true, failedAllOrders: false }
	},
	'orders/FETCH_ALL_OPEN_ORDERS': (state, action) => {
		return {
			...state,
			allOpenOrders: action.payload.Orders,
			allOpenOrdersParams: action.payload.HeaderPagination,
			failed: false,
		}
	},
	'orders/FETCH_ALL_STOP_ORDERS': (state, action) => {
		return {
			...state,
			allStopOrders: action.payload.Orders,
			allStopOrdersParams: action.payload.HeaderPagination,
			failed: false,
		}
	},
	'orders/FETCH_ALL_ORDERS': (state, action) => {
		return {
			...state,
			allOrders: action.payload.Orders,
			allOrdersParams: action.payload.HeaderPagination,
			failedAllOrders: false,
			loadingAllOrders: false,
		}
	},
	'orders/RESET_ORDERS': () => {
		return { ...initialState }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
