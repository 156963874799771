import axios from 'axios'
import get from 'lodash/get'

import { logError, verifyExpired } from '../../utils'

const WP_URL = process.env.REACT_APP_WORDPRESS_API

const WP_API = axios.create({
	baseURL: WP_URL,
})

export const getAllPosts = async () => {
	try {
		const { data } = await WP_API.get(`/posts?per_page=20&page=1&_embed`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getAllPosts logError')
		const errorStatus = get(error, 'response.status', 'error in getAllPosts getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/wordpress/getAllPosts', errorResponse)
		return { error: errorResponse }
	}
}
