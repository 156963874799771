import { limitOrder, marketOrder, stopLimitOrder, stopMarketOrder } from '../../services/manager'

export const Types = {
	SET_LOADING_TRADE: 'trade/SET_LOADING_TRADE',
	SUCCESS: 'trade/SUCCESS',
	FAILURE: 'trade/FAILURE',
	CLEAR_RESPONSE: 'trade/CLEAR_RESPONSE',
}

const initialState = {
	loading: false,
	error: null,
	limitOrderResponse: {},
	marketOrderResponse: {},
	stopMarketOrder: {},
}

export const createLimitOrder = payload => {
	return async dispatch => {
		const { data, error } = await limitOrder(payload)

		if (error) {
			return { error }
		}
		if (data) {
			dispatch({
				type: Types.SUCCESS,
				payload: {
					limitOrderResponse: data,
				},
			})

			dispatch({
				type: Types.CLEAR_RESPONSE,
				payload: {
					limitOrderResponse: {},
				},
			})
			return { data }
		}
	}
}

export const createMarketOrder = payload => {
	return async dispatch => {
		dispatch({
			type: Types.SET_LOADING_TRADE,
			payload: {
				loading: true,
			},
		})

		const { data, error } = await marketOrder(payload)
		if (error) {
			return { error }
		}

		if (data) {
			dispatch({
				type: Types.SUCCESS,
				payload: {
					marketOrderResponse: { ...data },
				},
			})

			dispatch({
				type: Types.CLEAR_RESPONSE,
				payload: {
					marketOrderResponse: {},
				},
			})
			return { data }
		}
	}
}

export const createStopLimitOrder = payload => {
	return async dispatch => {
		const { data, error } = await stopLimitOrder(payload)

		if (error) {
			return { error }
		}
		if (data) {
			dispatch({
				type: Types.SUCCESS,
				payload: {
					stopLimitOrderResponse: data,
				},
			})

			dispatch({
				type: Types.CLEAR_RESPONSE,
				payload: {
					stopLimitOrderResponse: {},
				},
			})
			return { data }
		}
	}
}

export const createStopMarketOrder = payload => {
	return async dispatch => {
		const { data, error } = await stopMarketOrder(payload)

		if (error) {
			return { error }
		}

		if (data) {
			dispatch({
				type: Types.SUCCESS,
				payload: {
					stopMarketOrderResponse: { ...data },
				},
			})

			dispatch({
				type: Types.CLEAR_RESPONSE,
				payload: {
					stopMarketOrderResponse: {},
				},
			})
			return { data }
		}
	}
}

export const reducerObject = {
	'trade/SET_LOADING_TRADE': (state, action) => {
		return { ...state, ...action.payload }
	},
	'trade/CLEAR_RESPONSE': (state, action) => {
		return { ...state, ...action.payload }
	},
	'trade/SUCCESS': (state, action) => {
		return { ...state, loading: false, error: null, ...action.payload }
	},
	'trade/FAILURE': (state, action) => {
		return { ...state, loading: false, ...action.payload }
	},
}

export default function reducer(state = initialState, action) {
	return reducerObject[action.type]?.(state, action) || state
}
