import axios from 'axios'
import get from 'lodash/get'

// import keycloak from '../../keycloak'
import { getAccessToken, logError } from '../../utils'
// const TEST = process.env.NODE_ENV === 'test'

// const isProd = TEST ? false : window.location.hostname === 'cex.bitcoin.me'
// const isBeta = TEST ? false : window.location.hostname === 'beta.cex.bitcoin.me'

const NFT_API = process.env.REACT_APP_NFT_API

const MANAGER = axios.create({
	baseURL: NFT_API,
})

const MANAGER_AUTHORIZED = axios.create({
	baseURL: NFT_API,
	headers: { ClientOrigin: 3, 'Content-Type': '/application/json' },
})

MANAGER_AUTHORIZED.interceptors.request.use(async config => {
	config.headers.AuthToken = await getAccessToken()
	return config
})

// if (isProd || isBeta) {
// 	MANAGER_AUTHORIZED.interceptors.response.use(
// 		response => {
// 			return response
// 		},
// 		error => {
// 			if (error.response.status === 401) {
// 				keycloak.logout()
// 			}
// 			return error
// 		}
// 	)
// }

export const getNFTCollections = async () => {
	try {
		const { data } = await MANAGER_AUTHORIZED.get('/v1/collections')
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getNFTCollections logError')
		logError('getNFTCollections', errorResponse)
		return { error: errorResponse }
	}
}

export const getOffersReceived = async ({ filter = 'Latest', limit = 100, skip = 0, status = 'OPEN_OFFER' }) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post(`v1/offers-received`, {
			filter,
			paginationInfos: {
				limit,
				skip,
			},
			status,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in offers-received logError')
		logError('offers-received', errorResponse)
		return { error: errorResponse }
	}
}

export const rejectOffer = async ({ offerId }) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.put(`v1/offer/reject`, { offerId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in rejectOffer logError')
		logError('rejectOffer', errorResponse)
		return { error: errorResponse }
	}
}

export const acceptOffer = async ({ offerId }) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.put(`v1/offer/accept`, { offerId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in acceptOffer logError')
		logError('acceptOffer', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelOffer = async ({ offerId }) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.put(`v1/offer/cancel`, { offerId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelOffer logError')
		logError('cancelOffer', errorResponse)
		return { error: errorResponse }
	}
}

export const createOffer = async ({ orderId, price, tokenId }) => {
	const priceToString = price.toString()
	try {
		const { data } = await MANAGER_AUTHORIZED.post('v1/offer', { orderId, price: priceToString, tokenId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in createOffer logError')
		logError('createOffer', errorResponse)
		return { error: errorResponse }
	}
}

export const getUserOffers = async ({ filter = 'Latest', limit = 100, skip = 0, status = 'OPEN_OFFER' }) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('v1/offers', {
			filter,
			status,
			paginationInfos: {
				limit,
				skip,
			},
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchOffers logError')
		logError('fetchOffers', errorResponse)
		return { error: errorResponse }
	}
}

export const getOffers = async ({ filter = 'Latest', orderId, limit = 100, skip = 0, status = 'OPEN_OFFER' }) => {
	try {
		const { data } = await MANAGER.post('v1/offers', {
			filter,
			status,
			orderId,
			paginationInfos: {
				limit,
				skip,
			},
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchOffers logError')
		logError('fetchOffers', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchCollections = async () => {
	try {
		const { data } = await MANAGER.get('v1/collections')
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollections logError')
		logError('klv_fetchCollections_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchCollectionsId = async id => {
	try {
		const { data } = await MANAGER.get(`v1/collection?id=${id}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionsId logError')
		logError('klv_fetchCollectionsId_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchCollectionByName = async collectionName => {
	try {
		const { data } = await MANAGER.get(`v1/collection?collectionName=${collectionName}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionByName logError')
		logError('klv_fetchCollectionByName_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchMetadataCollection = async collectionID => {
	try {
		const { data } = await MANAGER.get(`v1/metadata/collection/${collectionID}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionByName logError')
		logError('klv_fetchCollectionByName_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNfts = async filters => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('v1/nfts', filters)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNfts logError')
		logError('klv_fetchNft_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNFTMetadata = async id => {
	try {
		const { data } = await MANAGER.get(`v1/metadata/${id}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNFTMetadata logError')
		logError('klv_fetchNFTMetadata_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNFTDetails = async (collectionId, hash) => {
	try {
		const { data } = await MANAGER.get(`v1/nfts/${collectionId}/${hash}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNFTDetails logError')
		logError('klv_fetchNFTDetails_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchCollectionDetails = async id => {
	try {
		const { data } = await MANAGER.get(`v1/collection?id=${id}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionDetails logError')
		logError('klv_fetchCollectionDetails_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchBuyNft = async (orderId, tokenId) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('v1/buy', { orderId, tokenId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchBuyNft logError')
		logError('klv_fetchBuyNft_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getNftOrdersByAnUser = async payload => {
	const { pageSize: limit = 10, skip = 0, orderType = 'ALL_USER_HISTORY_TYPE' } = payload
	try {
		const { data } = await MANAGER_AUTHORIZED.post(`/v1/get-user-order-history`, {
			paginationInfos: {
				limit,
				skip,
			},
			orderType,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getNftOrdersByAnUser logError')
		logError('klv_getNftOrdersByAnUser_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNftOrders = async filters => {
	try {
		const { data } = await MANAGER.post(`/v1/orders`, filters)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNftOrders logError')
		logError('klv_fetchNftOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNftsLocked = async filter => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post(`/v1/nfts-locked`, filter)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNftsLocked logError')
		logError('klv_fetchNftsLocked_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchUserNFTOrders = async filter => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post(`/v1/orders`, filter)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchUserNFTOrders logError')
		logError('klv_fetchUserNFTOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const removeFromSaleNft = async orderId => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('v1/cancel', { orderId })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in removeNft logError')
		logError('klv_removeNft_error', errorResponse)
		return { error: errorResponse }
	}
}

export const saleNft = async (collectionId, hash, price, token, kind = 'SELL') => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('/v1/order', {
			collectionId,
			hash: hash,
			kind: kind,
			price: price,
			token: token,
		})
		const { orderId, status } = data
		return { orderId, status }
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in saleNft logError')
		logError('klv_saleNft_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchNFTHistory = async (CollectionId, tokenHash, type) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('/v1/get-nft-order-history', {
			tokenHash,
			CollectionId,
			type,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchNFTHistory logError')
		logError('klv_fetchNFTHistory_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchCollectionTokens = async id => {
	try {
		const { data } = await MANAGER.get(`/v1/collection/${id}/tokens`)
		return data?.tokensIds
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionTokens logError')
		logError('klv_fetchCollectionTokens_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchWithdrawNft = async ({
	collectionAssetId,
	collectionId,
	destination,
	feeTokenID,
	nftHash,
	factorTOTP,
}) => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post(
			'/v1/withdraw-nft',
			{
				collectionAssetId,
				collectionId,
				destination,
				feeTokenID,
				nftHash,
			},
			{
				headers: {
					FactorTOTP: factorTOTP,
				},
			}
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchWithdrawNft logError')
		logError('klv_fetchWithdrawNft_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getCollectionsRanking = async () => {
	try {
		const { data } = await MANAGER_AUTHORIZED.post('/v1/rankings', {})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchCollectionsRanking logError')
		logError('klv_fetchCollectionsRanking_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getBfsNfts = async id => {
	try {
		const response = await MANAGER_AUTHORIZED.post(`v1/nfts?collectionId=${id}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchBfsNfts logError')
		logError('klv_fetchBfsNfts_error', errorResponse)
		return { error: errorResponse }
	}
}
