export const disabledCodes = [
	16, 36, 40, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
	107, 109, 110, 111, 192, 187, 188, 189, 191, 193, 219, 220, 221, 222, 226,
]

export const excludeInputChars = [
	'$',
	'^',
	'?',
	'>',
	'ç',
	'Ç',
	'%',
	'[',
	']',
	',',
	'~',
	'´',
	'!',
	'@',
	'#',
	'¨',
	'&',
	'*',
	'(',
	')',
	'}',
	'f',
	'p',
	'P',
	'`',
	':',
	';',
]
