/**
 * @fileoverview gRPC-Web generated client stub for nft
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {}
grpc.web = require('grpc-web')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')

var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var validate_validate_pb = require('./validate/validate_pb.js')
const proto = {}
proto.nft = require('./nft_pb.js')

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.nft.NftServiceClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options.format = 'binary'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.nft.NftServicePromiseClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options.format = 'binary'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.nft.HealthResponse>}
 */
const methodDescriptor_NftService_Health = new grpc.web.MethodDescriptor(
	'/nft.NftService/Health',
	grpc.web.MethodType.UNARY,
	google_protobuf_empty_pb.Empty,
	proto.nft.HealthResponse,
	/**
	 * @param {!proto.google.protobuf.Empty} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.HealthResponse.deserializeBinary
)

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.HealthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.HealthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.health = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/Health',
		request,
		metadata || {},
		methodDescriptor_NftService_Health,
		callback
	)
}

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.HealthResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.health = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/Health',
		request,
		metadata || {},
		methodDescriptor_NftService_Health
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetNftRequest,
 *   !proto.nft.Nft>}
 */
const methodDescriptor_NftService_GetNft = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetNft',
	grpc.web.MethodType.UNARY,
	proto.nft.GetNftRequest,
	proto.nft.Nft,
	/**
	 * @param {!proto.nft.GetNftRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.Nft.deserializeBinary
)

/**
 * @param {!proto.nft.GetNftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.Nft)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.Nft>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getNft = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetNft',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNft,
		callback
	)
}

/**
 * @param {!proto.nft.GetNftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.Nft>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getNft = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetNft',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNft
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.ListNftsRequest,
 *   !proto.nft.ListNftsResponse>}
 */
const methodDescriptor_NftService_ListNfts = new grpc.web.MethodDescriptor(
	'/nft.NftService/ListNfts',
	grpc.web.MethodType.UNARY,
	proto.nft.ListNftsRequest,
	proto.nft.ListNftsResponse,
	/**
	 * @param {!proto.nft.ListNftsRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.ListNftsResponse.deserializeBinary
)

/**
 * @param {!proto.nft.ListNftsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.ListNftsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.ListNftsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.listNfts = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/ListNfts',
		request,
		metadata || {},
		methodDescriptor_NftService_ListNfts,
		callback
	)
}

/**
 * @param {!proto.nft.ListNftsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.ListNftsResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.listNfts = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/ListNfts',
		request,
		metadata || {},
		methodDescriptor_NftService_ListNfts
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.ListCollectionsRequest,
 *   !proto.nft.ListCollectionsResponse>}
 */
const methodDescriptor_NftService_ListCollections = new grpc.web.MethodDescriptor(
	'/nft.NftService/ListCollections',
	grpc.web.MethodType.UNARY,
	proto.nft.ListCollectionsRequest,
	proto.nft.ListCollectionsResponse,
	/**
	 * @param {!proto.nft.ListCollectionsRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.ListCollectionsResponse.deserializeBinary
)

/**
 * @param {!proto.nft.ListCollectionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.ListCollectionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.ListCollectionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.listCollections = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/ListCollections',
		request,
		metadata || {},
		methodDescriptor_NftService_ListCollections,
		callback
	)
}

/**
 * @param {!proto.nft.ListCollectionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.ListCollectionsResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.listCollections = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/ListCollections',
		request,
		metadata || {},
		methodDescriptor_NftService_ListCollections
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetNftsByCollectionNameRequest,
 *   !proto.nft.GetNftsByCollectionNameResponse>}
 */
const methodDescriptor_NftService_GetNftsByCollectionName = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetNftsByCollectionName',
	grpc.web.MethodType.UNARY,
	proto.nft.GetNftsByCollectionNameRequest,
	proto.nft.GetNftsByCollectionNameResponse,
	/**
	 * @param {!proto.nft.GetNftsByCollectionNameRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.GetNftsByCollectionNameResponse.deserializeBinary
)

/**
 * @param {!proto.nft.GetNftsByCollectionNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.GetNftsByCollectionNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.GetNftsByCollectionNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getNftsByCollectionName = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetNftsByCollectionName',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftsByCollectionName,
		callback
	)
}

/**
 * @param {!proto.nft.GetNftsByCollectionNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.GetNftsByCollectionNameResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getNftsByCollectionName = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetNftsByCollectionName',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftsByCollectionName
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetCollectionRequest,
 *   !proto.nft.Collection>}
 */
const methodDescriptor_NftService_GetCollection = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetCollection',
	grpc.web.MethodType.UNARY,
	proto.nft.GetCollectionRequest,
	proto.nft.Collection,
	/**
	 * @param {!proto.nft.GetCollectionRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.Collection.deserializeBinary
)

/**
 * @param {!proto.nft.GetCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.Collection)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.Collection>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getCollection = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetCollection',
		request,
		metadata || {},
		methodDescriptor_NftService_GetCollection,
		callback
	)
}

/**
 * @param {!proto.nft.GetCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.Collection>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getCollection = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetCollection',
		request,
		metadata || {},
		methodDescriptor_NftService_GetCollection
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetId,
 *   !proto.nft.GetCollectionTokensResponse>}
 */
const methodDescriptor_NftService_GetCollectionTokens = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetCollectionTokens',
	grpc.web.MethodType.UNARY,
	proto.nft.GetId,
	proto.nft.GetCollectionTokensResponse,
	/**
	 * @param {!proto.nft.GetId} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.GetCollectionTokensResponse.deserializeBinary
)

/**
 * @param {!proto.nft.GetId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.GetCollectionTokensResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.GetCollectionTokensResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getCollectionTokens = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetCollectionTokens',
		request,
		metadata || {},
		methodDescriptor_NftService_GetCollectionTokens,
		callback
	)
}

/**
 * @param {!proto.nft.GetId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.GetCollectionTokensResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getCollectionTokens = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetCollectionTokens',
		request,
		metadata || {},
		methodDescriptor_NftService_GetCollectionTokens
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.NftOrderRequest,
 *   !proto.nft.NftOrderResponse>}
 */
const methodDescriptor_NftService_CreateOrder = new grpc.web.MethodDescriptor(
	'/nft.NftService/CreateOrder',
	grpc.web.MethodType.UNARY,
	proto.nft.NftOrderRequest,
	proto.nft.NftOrderResponse,
	/**
	 * @param {!proto.nft.NftOrderRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.NftOrderResponse.deserializeBinary
)

/**
 * @param {!proto.nft.NftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.NftOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.NftOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.createOrder = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/CreateOrder',
		request,
		metadata || {},
		methodDescriptor_NftService_CreateOrder,
		callback
	)
}

/**
 * @param {!proto.nft.NftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.NftOrderResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.createOrder = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/CreateOrder',
		request,
		metadata || {},
		methodDescriptor_NftService_CreateOrder
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.ListOrderRequest,
 *   !proto.nft.ListOrderResponse>}
 */
const methodDescriptor_NftService_ListOrders = new grpc.web.MethodDescriptor(
	'/nft.NftService/ListOrders',
	grpc.web.MethodType.UNARY,
	proto.nft.ListOrderRequest,
	proto.nft.ListOrderResponse,
	/**
	 * @param {!proto.nft.ListOrderRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.ListOrderResponse.deserializeBinary
)

/**
 * @param {!proto.nft.ListOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.ListOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.ListOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.listOrders = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/ListOrders',
		request,
		metadata || {},
		methodDescriptor_NftService_ListOrders,
		callback
	)
}

/**
 * @param {!proto.nft.ListOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.ListOrderResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.listOrders = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/ListOrders',
		request,
		metadata || {},
		methodDescriptor_NftService_ListOrders
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.NftCancelRequest,
 *   !proto.nft.NftCancelResponse>}
 */
const methodDescriptor_NftService_Cancel = new grpc.web.MethodDescriptor(
	'/nft.NftService/Cancel',
	grpc.web.MethodType.UNARY,
	proto.nft.NftCancelRequest,
	proto.nft.NftCancelResponse,
	/**
	 * @param {!proto.nft.NftCancelRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.NftCancelResponse.deserializeBinary
)

/**
 * @param {!proto.nft.NftCancelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.NftCancelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.NftCancelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.cancel = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/Cancel',
		request,
		metadata || {},
		methodDescriptor_NftService_Cancel,
		callback
	)
}

/**
 * @param {!proto.nft.NftCancelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.NftCancelResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.cancel = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/Cancel',
		request,
		metadata || {},
		methodDescriptor_NftService_Cancel
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.BuyNftRequest,
 *   !proto.nft.BuyNftResponse>}
 */
const methodDescriptor_NftService_Buy = new grpc.web.MethodDescriptor(
	'/nft.NftService/Buy',
	grpc.web.MethodType.UNARY,
	proto.nft.BuyNftRequest,
	proto.nft.BuyNftResponse,
	/**
	 * @param {!proto.nft.BuyNftRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.BuyNftResponse.deserializeBinary
)

/**
 * @param {!proto.nft.BuyNftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.BuyNftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.BuyNftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.buy = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/Buy',
		request,
		metadata || {},
		methodDescriptor_NftService_Buy,
		callback
	)
}

/**
 * @param {!proto.nft.BuyNftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.BuyNftResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.buy = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/Buy',
		request,
		metadata || {},
		methodDescriptor_NftService_Buy
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetNftOrderHistoryRequest,
 *   !proto.nft.GetNftOrderHistoryResponse>}
 */
const methodDescriptor_NftService_GetNftOrderHistory = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetNftOrderHistory',
	grpc.web.MethodType.UNARY,
	proto.nft.GetNftOrderHistoryRequest,
	proto.nft.GetNftOrderHistoryResponse,
	/**
	 * @param {!proto.nft.GetNftOrderHistoryRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.GetNftOrderHistoryResponse.deserializeBinary
)

/**
 * @param {!proto.nft.GetNftOrderHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.GetNftOrderHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.GetNftOrderHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getNftOrderHistory = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetNftOrderHistory',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftOrderHistory,
		callback
	)
}

/**
 * @param {!proto.nft.GetNftOrderHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.GetNftOrderHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getNftOrderHistory = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetNftOrderHistory',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftOrderHistory
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.GetUserOrderHistoryRequest,
 *   !proto.nft.GetUserOrderHistoryResponse>}
 */
const methodDescriptor_NftService_GetUserOrderHistory = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetUserOrderHistory',
	grpc.web.MethodType.UNARY,
	proto.nft.GetUserOrderHistoryRequest,
	proto.nft.GetUserOrderHistoryResponse,
	/**
	 * @param {!proto.nft.GetUserOrderHistoryRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.GetUserOrderHistoryResponse.deserializeBinary
)

/**
 * @param {!proto.nft.GetUserOrderHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.GetUserOrderHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.GetUserOrderHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getUserOrderHistory = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetUserOrderHistory',
		request,
		metadata || {},
		methodDescriptor_NftService_GetUserOrderHistory,
		callback
	)
}

/**
 * @param {!proto.nft.GetUserOrderHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.GetUserOrderHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getUserOrderHistory = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetUserOrderHistory',
		request,
		metadata || {},
		methodDescriptor_NftService_GetUserOrderHistory
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nft.WithdrawNftRequest,
 *   !proto.nft.WithdrawNftResponse>}
 */
const methodDescriptor_NftService_AddWithdrawNft = new grpc.web.MethodDescriptor(
	'/nft.NftService/AddWithdrawNft',
	grpc.web.MethodType.UNARY,
	proto.nft.WithdrawNftRequest,
	proto.nft.WithdrawNftResponse,
	/**
	 * @param {!proto.nft.WithdrawNftRequest} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.WithdrawNftResponse.deserializeBinary
)

/**
 * @param {!proto.nft.WithdrawNftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.WithdrawNftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.WithdrawNftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.addWithdrawNft = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/AddWithdrawNft',
		request,
		metadata || {},
		methodDescriptor_NftService_AddWithdrawNft,
		callback
	)
}

/**
 * @param {!proto.nft.WithdrawNftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.WithdrawNftResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.addWithdrawNft = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/AddWithdrawNft',
		request,
		metadata || {},
		methodDescriptor_NftService_AddWithdrawNft
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.nft.GetNftRankingResponse>}
 */
const methodDescriptor_NftService_GetNftRanking = new grpc.web.MethodDescriptor(
	'/nft.NftService/GetNftRanking',
	grpc.web.MethodType.UNARY,
	google_protobuf_empty_pb.Empty,
	proto.nft.GetNftRankingResponse,
	/**
	 * @param {!proto.google.protobuf.Empty} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.nft.GetNftRankingResponse.deserializeBinary
)

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.nft.GetNftRankingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nft.GetNftRankingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nft.NftServiceClient.prototype.getNftRanking = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/nft.NftService/GetNftRanking',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftRanking,
		callback
	)
}

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nft.GetNftRankingResponse>}
 *     Promise that resolves to the response
 */
proto.nft.NftServicePromiseClient.prototype.getNftRanking = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/nft.NftService/GetNftRanking',
		request,
		metadata || {},
		methodDescriptor_NftService_GetNftRanking
	)
}

module.exports = proto.nft
